import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Paper,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  Dialog,
  CircularProgress,
  Stack,
  Divider,
  Box,
  Popover,
} from '@mui/material'
import { dashboardEntity, dashboardUI } from '../../reducers'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/Mail'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom'
import {
  CurrentLoginUserProfile,
  DashboardIcon,
  HomeIcon,
  OpenTabLogo,
} from '../../utils/StaticData'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { Primary } from '../../utils'
import { connect } from 'react-redux'
import { getDrsData } from '../../actions'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { useEffect } from 'react'
import { Background } from '../../utils/index'
import { ArrowBack } from '@mui/icons-material'

function DRs({ fetchDrsData, DrsData, loaderState }: any) {

  useEffect(() => {
    fetchDrsData()
  }, [])

  const navigate = useNavigate()
  // const handleLeaveDetailsOpen = (userID: number) => {
  // navigate(`/home/dashboard/leave-details?id=${userID}`)

  const managerViewScreen = (userId: number) => {
    if (userId) {
      // navigate(`leave-details?id=${userId}`)
       navigate(`/home/dashboard/drs/manager-view?id=${userId}`)
    }
  }
  
  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '70%',
    height: 'fit-content',
    padding: '40px 70px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    left: '37.5%',
    background: '#FFFFFF',
    opacity: '1',
    marginTop: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0px',
    // border: '1px solid #DDDDDD',
  }))

  const useStyles = makeStyles({
    image: {
      width: '80px',
      borderRadius: '50%',
      height: '80px',
    },
    container: {
      width: '100%',
      color: 'azure',
    },
    subContainer: {
      padding: '12px',
    },
    paperCard: {
      width: '100%',
      height: '100%',
      padding: '10px 0px 0px 0px',
      // display: 'flex',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      opacity: '1',
      marginTop: '10px',
      overflow: 'hidden',
    },
    drsHeader: {
      marginLeft: '90px !important',
      marginTop: '10px !important',
      marginBottom: '30px !important',
    },
    stack: {
      justifyContent: 'center',
      height: '40px',
    },
    divider: {
      position: 'relative',
      margin: '0% 5%',
    },
    cardContainer: {},
    subCardContainer: {},
    CommonCardStyled: {
      cursor: 'pointer',
      display: 'flex',
    },
    backArrow: {
      float: 'right',
      // margin: '1% 4%',
      // marginRight:'20px',
      cursor: 'pointer',
    },
    cardEmpIdMidText: {
      fontSize: '15px',
      textAlign: 'left',
      marginTop: '8px',
      fontFamily: 'Montserrat-Medium',
      fontWeight: '100',
      letterSpacing: '0px',
      color: '#193C6D',
    },
    empIdHeading: {
      fontStyle: 'italic !important',
      color: '#193C6D !important',
      margin: '0px !important',
      fontSize: '13px !important',
    },
    empDesignationHeading: {
      fontStyle: 'italic !important',
      color: '#193C6D !important',
      bottom: '5px',
      fontSize: '13px !important',
    },
    boxContainer: {
      textAlign: 'left',
    },
    boxSubContainer: {
      float: 'right',
      marginRight: '30px',
      marginTop: '20px',
      cursor: 'pointer',
    },
    popoverNameText: {
      fontSize: 14,
      position: 'relative',
      // bottom: '40px',
      fontFamily: 'Montserrat-SemiBold',
      color: '#000',
    },
    popoverEmailPhoneHeading: {
      fontStyle: 'italic !important',
      color: '#000 !important',
      margin: '0px !important',
      fontSize: '13px !important',
    },
    popoverWorksationHeading: {
      fontStyle: 'italic !important',
      color: '#000 !important',
      bottom: '5px',
      fontSize: '13px !important',
    },
    root: {
      // display: 'flex',
      alignItems: 'center',
    },
    image1: {
      width: '70px',
      height: '70px',
      marginRight: '10px', 
    },
    subroot: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '15px',
    },
    icon: {
      marginRight:'10px',
      color: Primary,
    },
    info: {
      color: 'gray',
      fontSize: 14,
      position: 'relative',
      // bottom: '40px',
      fontFamily: 'Montserrat-SemiBold',
      // color: '#193C6D',
      // fontStyle:'italic'
    },
  
  })
  const classes = useStyles()

  const employeeNameText = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    opacity: '1',
  }

  const welcomeMessage = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.5',
    fontSize: '10px',
  }
  const DashboardText = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
    fontSize: '14px',
  }

  const IconStyles: React.CSSProperties = {
    // margin: '0px 10px 0 15px',
    width: '19px',
    position: 'relative',
    top: '3px',
  }

  const cardStyles = {
    // backgroundColor: '#F5F5F5',
    marginLeft: '10px',
    // marginBottom: '18px',
    // marginTop: '8px',
    width: '25%',
    position: 'relative',
    left: '37.5%',
    paddingBottom: '0px',
  }

  const cardStyled = {
    justifyContent: 'normal',
    position: 'relative',
    // background: 'red',
    border: 'none',
    // left:'5%',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: '10px',
    textAlign: 'justify',
  }

  const customCardStyle = {
    bottom: '6px',
    position: 'relative',
  }

  const CommonCardStyle: React.CSSProperties = {
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    margin: '0% 15%',
    maxWidth: '70%',
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
  }
  const CardHeadText = {
    fontSize: 14,
    fontFamily: 'Montserrat-Bold',
  }

  const CardHeadingText: React.CSSProperties = {
    fontSize: 14,
    position: 'relative',
    // bottom: '40px',
    fontFamily: 'Montserrat-SemiBold',
    color: '#193C6D',
  }

  const CardMidText = {
    fontStyle: 'italic',
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }

  const CardIconStyle = {
    fontSize: 32,
    color: Primary,
    marginRight: '4px',
    position: 'relative',
    right: '4px',
  }

  const CardButtonStyle = {
    fontSize: 10,
    backgroundColor: 'Primary',
    fontFamily: 'Montserrat-Bold',
  }
  const ExploreIcon: React.CSSProperties = {
    paddingLeft: '80px',
    position: 'relative',
    top: '3px',
    cursor: 'pointer',
  }

  const ExploreIcon1: React.CSSProperties = {
    paddingLeft: '53px',
    position: 'relative',
    top: '3px',
    cursor: 'pointer',
  }
  const ExploreIcon2: React.CSSProperties = {
    paddingLeft: '28px',
    position: 'relative',
    top: '3px',
    left: '82px',
    cursor: 'pointer',
  }
  const ExploreIcon3: React.CSSProperties = {
    paddingLeft: '66px',
    position: 'relative',
    top: '3px',
    left: '25px',
    cursor: 'pointer',
  }

  const ExploreIcon4: React.CSSProperties = {
    paddingLeft: '41px',
    position: 'relative',
    top: '3px',
    cursor: 'pointer',
  }

  const ExploreIcon5: React.CSSProperties = {
    paddingLeft: '140px',
    position: 'relative',
    top: '3px',
    cursor: 'pointer',
  }

  const CardStyle = {
    padding: '16px 13px 16px 16px',
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [workStation, setWorkStation] = useState('')
  const [image, setImage] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [employeeId, setEmplyeeId] = useState('')
  const [designation, setDesignation] = useState('')

  const handlePopoverOpen = (
    event: any,
    workStation: any,
    email: any,
    phone: any,
    name: any,
    image: any,
    employeeId: any,
    emplyeeName: any,
    designation: any
  ) => {
    setAnchorEl(event.currentTarget)
    setName(name)
    setEmail(email)
    setPhone(phone)
    setWorkStation(workStation)
    setImage(image)
    setEmplyeeId(employeeId)
    setEmployeeName(emplyeeName)
    setDesignation(designation)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const ManagerCard = (
    <React.Fragment>
      <div>
        <CardContent style={{ padding: '10px' }} sx={CardStyle}>
          <Typography gutterBottom>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classes.image}
                src={
                  DrsData?.managerDetails?.imagePath
                    ? DrsData?.managerDetails?.imagePath
                    : CurrentLoginUserProfile
                }
                alt=''
              />
              <div style={{ marginLeft: '10px' }}>
                <span style={CardHeadingText}>{DrsData?.managerDetails?.employeeName}</span>
                <Typography className={classes.empIdHeading} color='text.secondary' gutterBottom>
                  Emp Id: <span>{DrsData?.managerDetails?.employeeId}</span>
                </Typography>
                <Typography
                  className={classes.empDesignationHeading}
                  color='text.secondary'
                  gutterBottom
                >
                  {DrsData?.managerDetails?.designation}
                </Typography>
              </div>
            </div>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            {/* Email Id: */}
            <span> {DrsData?.managerDetails?.email} | </span>
            <span> {DrsData?.managerDetails?.phone}</span>
          </Typography>

          {/* <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Phone Number:
            <span> {DrsData?.managerDetails?.phone} </span>
          </Typography> */}
        </CardContent>
      </div>
    </React.Fragment>
  )

  const UserCard = (
    <React.Fragment>
      <div>
        <CardContent style={{ padding: '10px' }} sx={CardStyle}>
          <Typography gutterBottom>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classes.image}
                src={
                  DrsData?.userDetails?.imagePath
                    ? DrsData?.userDetails?.imagePath
                    : CurrentLoginUserProfile
                }
                alt=''
              />
              <div style={{ marginLeft: '10px' }}>
                <span style={CardHeadingText}>{DrsData?.userDetails?.employeeName}</span>
                <Typography className={classes.empIdHeading} color='text.secondary' gutterBottom>
                  Emp Id: <span>{DrsData?.userDetails?.employeeId}</span>
                </Typography>
                <Typography
                  className={classes.empDesignationHeading}
                  color='text.secondary'
                  textAlign={'left'}
                  gutterBottom
                >
                  {DrsData?.userDetails?.designation}
                </Typography>
              </div>
            </div>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            {/* Email Id: */}
            <span> {DrsData?.userDetails?.email} | </span>
            <span> {DrsData?.userDetails?.phone} </span>
          </Typography>
          {/* <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Phone Number:
            <span> {DrsData?.userDetails?.phone} </span>
          </Typography> */}
        </CardContent>
        {/* <div style={{ flexGrow: 1 }} /> */}
      </div>
    </React.Fragment>
  )

  const Drs = (
    <React.Fragment>
      {DrsData?.DRs?.length ? (
        DrsData?.DRs?.map((item: any, index: any) => (
          <div>
            <div
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              // onMouseEnter={(e) =>
              //   handlePopoverOpen(
              //     e,
              //     item.workStation,
              //     item.email,
              //     item.phone,
              //     item.employeeName,
              //     item.imagePath,
              //     item.employeeId,
              //     item.employeeName,
              //     item.designation,
              //   )
              // }
              onMouseLeave={handlePopoverClose}
              style={CommonCardStyle}
              className={classes.CommonCardStyled}
              onClick={(e) => managerViewScreen(item.userId)}
            >
              <CardContent style={{ padding: '10px' }} sx={CardStyle}>
                <Typography gutterBottom>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={classes.image}
                      src={item?.imagePath ? item?.imagePath : CurrentLoginUserProfile}
                      alt=''
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <span style={CardHeadingText}>{item?.employeeName}</span>
                      {/* <Typography
                        className={classes.empIdHeading}
                        color='text.secondary'
                        gutterBottom
                      >
                        Emp Id: <span>{item?.employeeId}</span>
                      </Typography> */}
                      <Typography
                        className={classes.empDesignationHeading}
                        color='text.secondary'
                        gutterBottom
                      >
                        {item?.designation}
                      </Typography>
                    </div>
                  </div>
                </Typography>
                {/* <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                  <span> {item?.email} | </span>
                  <span> {item?.phone} </span>
                </Typography> */}
              </CardContent>

              <Popover
                id='mouse-over-popover'
                sx={{
                  pointerEvents: 'none',
                  // '.MuiPopover-paper': {
                  //   '&.hover': {
                  //     boxShadow: 'none',
                  //   },
                  // },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}  
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Card style={{width: '300px'}}>
                  <CardContent style={{pointerEvents: "none"}}>
                    <div className={classes.root}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={classes.image}
                      src={image ? image : CurrentLoginUserProfile}
                      alt=''
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <span style={CardHeadingText}>{employeeName}</span>
                      <Typography
                        className={classes.empIdHeading}
                        color='text.secondary'
                        gutterBottom
                      >
                        Emp Id: <span>{employeeId}</span>
                      </Typography>
                      <Typography
                        className={classes.empDesignationHeading}
                        color='text.secondary'
                        gutterBottom
                      >
                        {designation}
                      </Typography>
                    </div>
                  </div>
                      <div className={classes.subroot}>
                        <MailIcon className={classes.icon} />
                        <Typography>
                          <span className={classes.info}>{email}</span>
                        </Typography>
                      </div>
                      <div className={classes.subroot}>
                        <PhoneIcon className={classes.icon} />
                        <Typography>
                          <span className={classes.info}>{phone}</span>
                        </Typography>
                      </div>
                      <div className={classes.subroot}>
                        <LocationOnIcon className={classes.icon} />
                        <Typography>
                        <span className={classes.info}>{workStation}</span>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Popover>
            </div>
          </div>
        ))
      ) : (
        <p style={{ fontSize: '15px', marginTop: '1px' }}></p>
      )}
    </React.Fragment>
  )

  const HeadingBar = {
    padding: '10px 0px',
    // display: 'flex',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 3px #00000029',
    opacity: 1,
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#4E2973',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div className={classes.container}>
          <div className={classes.subContainer}>
            <Paper
              className={classes.paperCard}
              elevation={3}
              // style={{
              //   width: '100%',
              //   height: '100%',
              //   padding: '10px 0px 0px 0px',
              //   // display: 'flex',
              //   background: '#FFFFFF 0% 0% no-repeat padding-box',
              //   boxShadow: '0px 3px 6px #00000029',
              //   opacity: '1',
              //   marginTop: '10px',
              //   overflow: 'auto',
              // }}
            >
              <Box className={classes.boxContainer}>
                <Box className={classes.boxSubContainer} onClick={() => navigate(-1)}>
                  <ArrowBack />
                </Box>
              </Box>
              <div style={{}}>
                <HeaderHeading
                  className={classes.drsHeader}
                  sx={{ marginTop: '10px', marginBottom: '20px' }}
                >
                  Direct Reportees
                </HeaderHeading>
                <Card sx={cardStyles} variant='outlined'>
                  {ManagerCard}
                </Card>
                <Stack className={classes.stack}>
                  <Divider orientation='vertical' />
                </Stack>
                <Card sx={cardStyles} variant='outlined'>
                  {UserCard}
                </Card>
                <Stack className={classes.stack}>
                  <Divider orientation='vertical' />
                </Stack>
              </div>
              <Divider
                sx={{ marginRight: '1%', marginLeft: '1%' }}
                className={classes.divider}
                orientation='horizontal'
                flexItem
              ></Divider>
              <div className={classes.cardContainer}>
                {/* <div className={classes.subCardContainer}> */}
                <Card sx={cardStyled} variant='outlined' className={classes.cardContainer}>
                  {Drs}
                </Card>
                {/* </div> */}
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    DrsData: dashboardEntity.getDashboard(state).getDrsData,
    loaderState: dashboardUI.getDashboard(state).isDrsData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDrsData: () => dispatch(getDrsData.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRs)
