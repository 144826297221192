import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import Pagination from '@mui/material/Pagination'
import { Grid, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import {
  HeaderHeading,
  StyledTableCell,
  StyledTableRow,
  SearchIconStyle,
} from '../Common/CommonStyles'
import { Primary } from '../../utils'
import { connect, useDispatch } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { fetchUserData } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { CurrentLoginUserProfile } from '../../utils/StaticData'


const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
}))


const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const SearchBox: React.CSSProperties = {
  width: '250px',
  float: 'left',
}

const SearchBoxCustom = styled(TextField)(({ theme }) => ({
  float: 'left',
  '& .MuiOutlinedInput-root': {
    width: '250px',
    borderRadius: '30px',
    '& fieldset': {
      borderColor: Primary,
    },
  },
}))

const EmployeeListTableData = (props: any) => {
  const { fetchUserData, userData, isGetUserData } = props
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    const filtered = userData.data?.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
    setFilteredRows(filtered)
  }, [searchQuery])

  const useStyles = makeStyles({
    image: {
      width: '50px',
      borderRadius: '50%',
      height: '50px',
      marginLeft: '10px',
      marginTop: '10px'
    },
    customTableCell: {
      width:'1%'
    }
  })
  
  const classes = useStyles()

  const startIndex = (page - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage

  const displayedRows = searchQuery ? filteredRows : userData.data
  const rowsToDisplay = displayedRows?.slice(startIndex, endIndex)

  if (isGetUserData) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CircularProgress disableShrink />
      </div>
    )
  }

  const handleBasicInfo = (empId: number) => {
    navigate(`/home/basic-info-on-search?id=${empId}`)
  }

  return (
    <>
      <HeaderHeading>Employee List</HeaderHeading>
      <SearchBoxCustom
        id='outlined-basic'
        placeholder='Search'
        variant='outlined'
        size='small'
        value={searchQuery}
        onChange={(e: any) => setSearchQuery(e.target.value)}
        style={SearchBox}
        InputProps={{
          startAdornment: <SearchIconStyle />,
        }}
      />

      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label='customized table'
        >
          <TableHead>
            <StyledTableRow>
            <StyledTableCell className={classes.customTableCell}></StyledTableCell>
              <StyledTableCell className={classes.customTableCell} >EMPLOYEE ID</StyledTableCell>
              <StyledTableCell className={classes.customTableCell}>NAME</StyledTableCell>
              <StyledTableCell className={classes.customTableCell}>EMAIL</StyledTableCell>
              {/* <StyledTableCell>ROLE TYPE</StyledTableCell> */}
              <StyledTableCell className={classes.customTableCell}>MANAGER</StyledTableCell>
              <StyledTableCell className={classes.customTableCell}>DESIGNATION</StyledTableCell>
              <StyledTableCell className={classes.customTableCell}>MOBILE NO</StyledTableCell>
              {/* <StyledTableCell>STATUS</StyledTableCell> */}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {rowsToDisplay?.map((row: any) => {
              if (row.isActive === 1) {
                return (
                  <StyledTableRow
                    key={row.employeeId}
                    onClick={(e) => handleBasicInfo(row?.userId)}
                  >
                     <Grid sm={1.5}>
              <img className={classes.image}
                src={row.image ? row.image : CurrentLoginUserProfile}
                alt=''
              />
            </Grid>
                    <StyledTableCell>{row.employeeId}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    {/* <StyledTableCell>
                      {row.role.map((role: string) => (
                        <div key={role}>{role}</div>
                      ))}
                    </StyledTableCell> */}
                    <StyledTableCell>{row.manager}</StyledTableCell>
                    <StyledTableCell>{row.designation}</StyledTableCell>
                    <StyledTableCell>{row.mobileNumber}</StyledTableCell>
                    {/* <StyledTableCell>{row.isActive === 1 ? 'Active' : 'Inactive'}</StyledTableCell> */}
                  </StyledTableRow>
                )
              }
              return null
            })}
          </TableBody>
        </Table>
        <Pagination
          sx={{
            float: 'right',
            margin: '15px 0px 10px 0px',
          }}
          page={page}
          onChange={handleChangePage}
          count={
            searchQuery
              ? Math.ceil(
                  filteredRows?.filter((item: any) => item.isActive === 1)?.length / rowsPerPage,
                )
              : Math.ceil(
                  userData.data?.filter((item: any) => item.isActive === 1)?.length / rowsPerPage,
                )
          }
          color='primary'
        />
      </TableContainer>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: dashboardEntity.getDashboard(state).getUserData,
    isGetUserData: dashboardUI.getDashboard(state).isGetUserData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserData: () => dispatch(fetchUserData.request()),
  }
}

const EmployeeListTableDataMapped = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeListTableData)

const EmployeeList = () => {
  return (
    <div style={MainContainer}>
      <StyledPaper>
        <EmployeeListTableDataMapped />
      </StyledPaper>
    </div>
  )
}

export default EmployeeList
