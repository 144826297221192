import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  FETCH_ACCOUNTTYPE,
  FETCH_BANKINFO,
  FETCH_COUNTRY,
  FETCH_STATE,
  FETCH_COMPENSATION_DETAILS,
  GET_TIMESHEET,
  GET_PAY_SLIP,
} from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function

function* handleGetBankInfoData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getBankInfoEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_BANKINFO)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_BANKINFO)
  }
}

function* handleGetCountryData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getCountryEndPoint)
    yield sendPayload(apiResponse, FETCH_COUNTRY)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_COUNTRY)
  }
}

function* handleTimesheetData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTimesheetEndPoint,
    )
    yield sendPayload(apiResponse, GET_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, GET_TIMESHEET)
  }
}

function* handlePayslipData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPaySlipEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_PAY_SLIP)
  } catch (e) {
    yield sendPayloadFailure(e, GET_PAY_SLIP)
  }
}

function* handleGetStateData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getStateEndPoint)
    yield sendPayload(apiResponse, FETCH_STATE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_STATE)
  }
}

function* getCompensationDetails(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCompensationDetailsEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_COMPENSATION_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_COMPENSATION_DETAILS)
  }
}

function* handleGetAccountTypeData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAccountTypeEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_ACCOUNTTYPE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ACCOUNTTYPE)
  }
}

export const sagas = {
  //watcher come here
  watchGetEmployeeInfoData: takeLatest(FETCH_BANKINFO[REQUEST], handleGetBankInfoData),
  watchGetCountryData: takeLatest(FETCH_COUNTRY[REQUEST], handleGetCountryData),
  watchGetStateData: takeLatest(FETCH_STATE[REQUEST], handleGetStateData),
  watchGetAccountTypeData: takeLatest(FETCH_ACCOUNTTYPE[REQUEST], handleGetAccountTypeData),
  watchCompensationDetails: takeLatest(FETCH_COMPENSATION_DETAILS[REQUEST], getCompensationDetails),
  watchTimesheetData: takeLatest(GET_TIMESHEET[REQUEST], handleTimesheetData),
  watchPaySlipData: takeLatest(GET_PAY_SLIP[REQUEST], handlePayslipData),
}
