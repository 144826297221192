import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import PdfIcon from '../../assets/images/pdf_image.png'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from '@mui/material'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  addNewComment,
  createServiceRequest,
  fetchDepartmentList,
  fetchSR,
  getIssueTypeList,
  getLoanInstallment,
} from '../../actions'
import { LoanRequestEntity, SREntity, dashboardEntity, dashboardUI } from '../../reducers'
import { IRequestData } from '../Types'
import {
  loginUserEntity,
  userAuthenticationEntity,
  userAuthenticationUI,
  SRUI,
} from '../../reducers'
import { Box } from '@mui/system'
import { IServiceRequest } from '../../models/service-request.model'
import { ICreateServiceRequest } from '../../models/create-service-request.model'
import { ICreateComment } from '../../models/create-comment.model'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import moment from 'moment'
import { duration } from '../../utils/date-format'
import { toast } from 'react-toastify'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Primary } from '../../utils'
import { useLocation } from 'react-router-dom'
import html2pdf from 'html2pdf.js'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '30px 48px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },

  '& .MuiButton-root:hover': {},
  '&.MuiButtonBase-root-MuiButton-root:hover': {},
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

const MainContainer = {
  background: 'rgb(231, 235, 240)',
  width: '100%',
  paddingBottom: '20px',
}

const MultiAccordion: React.CSSProperties = {
  margin: '50px 0 0 60px',
}

const AccordionStyle = {
  width: '95%',
  marginBottom: '15px',
}

const AccordingHeading = {
  flexShrink: 0,
  fontFamily: 'Montserrat-Medium',
  letterSpacing: '0',
  fontSize: '20px',
}

const rows = [
  { id: 1, name: 'Product 1', price: '$10.00' },
  { id: 2, name: 'Product 2', price: '$20.00' },
  { id: 3, name: 'Product 3', price: '$30.00' },
]

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  // padding: '30px 25px',
  padding: '5px 1px',

  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: '40px',
  marginRight: 'auto',
  marginBottom: '20px',
}))

const PaperBlock = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: '15px 15px',
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  // textAlign: 'center',
  overflow: 'auto',
}))

const PaperMiniBlock = styled(Paper)(({ theme }) => ({
  width: '5%',
  padding: '6px',
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  textAlign: 'center',
}))

const PaperSepBlock = styled(Paper)(({ theme }) => ({
  width: '68%',
  padding: '6px',
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  textAlign: 'center',
}))

const SplitDiv: React.CSSProperties = {
  display: 'flex',
  width: '559px',

  // marginBottom: '8px',
}

//   const Heading: React.CSSProperties = {
//     margin: 0,
//     marginBottom: '12px',
//     fontSize: '25px',
//     textAlign: 'center',
//     fontFamily: 'Montserrat-Medium',
//   }

const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  color: 'black',
  padding: '20px',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    fontSize: '11px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    color: '#483f3f',
    height: 'auto',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {},
  // hide last border
  '&:last-child td, &:last-child th': {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    borderBottom: 'none',
  },
}))

const DateField = styled(DatePicker)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root, & .MuiInputLabel-root': {
    lineHeight: '1em',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const SelectField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A5C332',
  boxShadow: 24,
  p: 4,
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const serviceData: any = {
  service_request: {
    id_department: 1,
    title: 'Example Service Request 7',
    description: 'This is a with leave service request 6.',
    priority: 1,
    // id_issue_type: 6,
    status: 1,
    project_group: 'mailto:12@tudip.com, mailto:45@tudip.com',
  },
  future_leave: {
    leave_start_date: '2015-07-05T00:00:00.000Z',
    leave_end_date: '2015-08-30T00:00:00.000Z',
  },
}

interface NewLoanInstallmentHistoryDialogProps {
  open: boolean
  onClose: () => void
  rowData: any
}

const NewLoanInstallmentHistoryDialog = ({
  rowData,
  open,
  SRData,
  onClose,
  loaderState,
  fetchSRData,
  fetchLoanInstallmentHistoryData,
  LoanInstallmentHistoryData,
}: any) => {


  const useStyles = makeStyles({
    tableRow: {
    fontWeight: 'bold', 
    }   
  })
  const classes = useStyles()
  const loanId = rowData?.id

  useEffect(() => {
    if (loanId) {
      fetchLoanInstallmentHistoryData(loanId)
    }
  }, [loanId])

  const handleClose = () => {
    onClose()
  }

  const handleExportPDF = () => {
    const element = document.getElementById('pdf-export')
    if (element) {
      const options = {
        margin: 10,
        filename: 'Compensation.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      }
      const imgElement = element.querySelector('img')

      if (imgElement) {
        // Show the image only in the exported PDF
        imgElement.style.display = 'block'

        html2pdf().set(options).from(element).save()

        // Reset the image display after exporting
        imgElement.style.display = 'none'
      }
    }
  }

  return (
    <div style={{ width: '100%' }} id='pdf-export'>
      <>
        {loaderState && (
          <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        )}
      </>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Heading>Installment History</Heading>
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 12, md: 6 }}>
            {/* <Grid item xs={12} sm={12}>
          <Button
            variant='outlined'
            sx={{
              fontSize: '14px',
              height: '42px',
              float: 'right',
              letterSpacing: '0px',
              borderRadius: '20px',
              padding: '5px 20px',
            }}
            startIcon={
              <Avatar sx={{ width: 24, height: 24 }}>
                <img src={PdfIcon} alt='PDF' />
              </Avatar>
            }
            onClick={handleExportPDF}
          >
            EXPORT AS PDF
          </Button>
        </Grid> */}

            <StyledPaper
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '20px',
              }}
            >
              {/* <p style={Heading}>Leave Accrual Report </p> */}

              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Id:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.employeeId}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Name:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.employeeName}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Request Description:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.request_description}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Approval Description:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.approval_description}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Total Loan Amount (In Rs.):
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.loan_amount}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Balance:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.remainingBalance}
                </PaperBlock>
              </div>
            </StyledPaper>

            {/* <HeaderHeading
              sx={{
                margin: '1px -25px 3px 17px',
                fontSize:"13px"
              }}
            >
              Installment History
            </HeaderHeading> */}

            <TableContainer
              sx={{
                margin: '9px -25px 3px 17px',
              }}
              component={Paper}
            >
              <Table
                sx={{
                  minWidth: 530,
                  // maxWidth:"400px"
                }}
                aria-label='customized table'
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>LOAN / INSTALLMENT DATE</StyledTableCell>
                    {/* <StyledTableCell>LOAN AMOUNT</StyledTableCell> */}
                    <StyledTableCell>INSTALLMENT AMOUNT</StyledTableCell>
                    <StyledTableCell>BALANCE</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {LoanInstallmentHistoryData &&
                Object.keys(LoanInstallmentHistoryData).length &&
                LoanInstallmentHistoryData.userLoanRepayment.length > 0 ? (
                  LoanInstallmentHistoryData.userLoanRepayment.map((item: any) => (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component='th' scope='row'>
                          {moment(item.payment_date).format('DD-MM-YYYY')}
                        </StyledTableCell>
                        {/* <StyledTableCell align='left'>
                          {LoanInstallmentHistoryData.loan_amount}
                        </StyledTableCell> */}
                        <StyledTableCell align='left'>{item.instalment_amount}</StyledTableCell>
                        <StyledTableCell align='left'>{item.balance}</StyledTableCell>
                      </StyledTableRow>
                      {/* ))} */}
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    {LoanInstallmentHistoryData.loan_request === 0 && (
                      <StyledTableRow>
                        <StyledTableCell align='center' colSpan={10}>
                          <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                            No matching records found.
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                )}
                {LoanInstallmentHistoryData.loan_request === 1 && (
                  <>
                    <StyledTableRow>
                      <StyledTableCell component='th' scope='row' className={classes.tableRow}>
                        Total:
                      </StyledTableCell>
                      {/* <StyledTableCell className={classes.tableRow} align='left'>
                        {LoanInstallmentHistoryData?.totalLoanAmount}
                      </StyledTableCell> */}
                      <StyledTableCell className={classes.tableRow} align='left'>
                        {LoanInstallmentHistoryData?.totalPayedAmount}
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row' align='left'>
                        {LoanInstallmentHistoryData?.remainingBalance}
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </Table>
            </TableContainer>

            {/* <StyledTableCell component='th' scope='row'>{LoanInstallmentHistoryData?.loan_date}</StyledTableCell>
            <StyledTableCell align='left'>{LoanInstallmentHistoryData?.totalLoanAmount}</StyledTableCell>
            <StyledTableCell align='left'>{LoanInstallmentHistoryData?.remainingBalance}</StyledTableCell>
            <StyledTableCell align='left'>{LoanInstallmentHistoryData?.remainingBalance}</StyledTableCell> */}

            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

function setOpen(arg: boolean) {
  throw new Error('Function not implemented.')
}

const mapStateToProps = (state: RootState) => {
  return {
    LoanInstallmentHistoryData: LoanRequestEntity.sendLoanRequestData(state).getLoanInstallment,
    loaderState: dashboardUI.getDashboard(state).isCommentData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchLoanInstallmentHistoryData: (data: any) => {
      dispatch(getLoanInstallment.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLoanInstallmentHistoryDialog)
