import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { FETCH_RCA, CREATE_RCA, FETCH_IDSR, CREATE_IDSR } from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function

function* fetchRCAs(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getRCAsList,
      data.userId,
    )
    yield sendPayload(apiResponse, FETCH_RCA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_RCA)
  }
}

function* createRCA(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createRCA,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_RCA)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_RCA)
  }
}

function* fetchIDSRs(data: any) {  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getIDSRsList,
      data,      
    )
    yield sendPayload(apiResponse, FETCH_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_IDSR)
  }
}

function* createIDSR(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createIDSR,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_IDSR)
  }
}

export const sagas = {
  watchFetchRCAs: takeLatest(actionTypes.FETCH_RCA[REQUEST], fetchRCAs),
  watchCreateRCA: takeLatest(actionTypes.CREATE_RCA[REQUEST], createRCA),
  watchFetchIDSRs: takeLatest(actionTypes.FETCH_IDSR[REQUEST], fetchIDSRs),
  watchcreateIDSR: takeLatest(actionTypes.CREATE_IDSR[REQUEST], createIDSR),
}
