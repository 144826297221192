import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_TIMESHEET,
  CREATE_RCA,
  RESET,
  CREATE_IDSR,
  FETCH_IDSR,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE } = actionTypes

const ui = () => {
  const isGetEmployeeAttendanceList = (state = false, action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE[SUCCESS]:
        return false
      case FETCH_ATTENDANCE[FAILURE]:
        return false
      case FETCH_ATTENDANCE[REQUEST]:
        return true
      default:
        return state
    }
  }

  const getAttendanceTimesheet = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE_TIMESHEET[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE_TIMESHEET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const isCreatingRCA = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_RCA[SUCCESS]:
      case CREATE_RCA[FAILURE]:
        return false
      case CREATE_RCA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isRCACreated = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_RCA[SUCCESS]:
        return true
      case CREATE_RCA[FAILURE]:
      case CREATE_RCA[REQUEST]:
      case CREATE_RCA[RESET]:
        return false
      default:
        return state
    }
  }
  const isCreatingIDSR = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_IDSR[SUCCESS]:
      case CREATE_IDSR[FAILURE]:
        return false
      case CREATE_IDSR[REQUEST]:
        return true
      default:
        return state
    }
  }
  const isFetchingIDSRs = (state = false, action: actions) => {
    switch (action.type) {
      case FETCH_IDSR[SUCCESS]:
        return false
      case FETCH_IDSR[FAILURE]:
      case FETCH_IDSR[REQUEST]:
        return true
      default:
        return state
    }
  }  

  const isIDSRCreated = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_IDSR[SUCCESS]:
        return true
      case CREATE_IDSR[FAILURE]:
      case CREATE_IDSR[REQUEST]:
      case CREATE_IDSR[RESET]:
        return false
      default:
        return state
    }
  }

  const resetRCA = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_RCA[RESET]:
        return true
      case CREATE_RCA[SUCCESS]:
      case CREATE_RCA[FAILURE]:
      case CREATE_RCA[REQUEST]:
        return false
      default:
        return state
    }
  }
  const resetIDSR = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_IDSR[RESET]:
        return true
      case CREATE_IDSR[SUCCESS]:
      case CREATE_IDSR[FAILURE]:
      case CREATE_IDSR[REQUEST]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isGetEmployeeAttendanceList,
    getAttendanceTimesheet,
    isCreatingRCA,
    isRCACreated,
    resetRCA,
    isCreatingIDSR,
    isIDSRCreated,
    isFetchingIDSRs,
    resetIDSR,
  })
}

export default ui

export const getEmpAttendanceList = (state: RootState) => state.ui.employeeListAttendance
