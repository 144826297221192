import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import { Typography, Modal } from '@mui/material'
import { HeaderHeading, StyledTableCell, StyledTableRow, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { createNewRCA, getRCAs } from '../../actions'
import { attendanceUI, dashboardEntity } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { fetchRCAs } from '../../reducers/entities'
import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import './Rca.scss'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  float: 'right',
  marginTop: '15px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: 'Montserrat-Medium',
  top: '30px',
  right: '100px',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', 
    fontSize: '13px', 
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const RCATable = (props: any) => {
  const navigate = useNavigate()

  return (
    <>
      <StyledPaper>
        <Box sx={{ textAlign: 'left' }}>
          <Box
            onClick={() => navigate(-1)}
            sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
          >
            <ArrowBack sx={{ position: 'relative', left: '120px' }} />
          </Box>
        </Box>
        <HeaderHeading sx={{ marginLeft: '100px' }}>My RCAs</HeaderHeading>
        <TableContainer sx={{ margin: '20px 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE</StyledTableCell>
                <StyledTableCell>SUBJECT</StyledTableCell>
                <StyledTableCell>MANAGER</StyledTableCell>
                <StyledTableCell>PROJECT</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {new Date(data.created_at).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                    <StyledTableCell align='left'>{data.manager_name}</StyledTableCell>
                    <StyledTableCell align='left'>{data.project_name}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={10}>
                    <Typography align='center' variant='subtitle1' sx={{ color: '#483f3f' }}>
                      No RCA found
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </StyledPaper>
    </>
  )
}

const CreateRcaDialog = ({
  open,
  onClose,
  createRCA,
}: {
  open: boolean
  onClose: () => void
  createRCA: (data: any) => void
}) => {
  const [to, setTo] = useState('')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState('')
  const [subject, setSubject] = useState('')
  const [projectName, setProjectName] = useState('')
  const [body, setBody] = useState('')

  const handleCreateRca = () => {
    handleClose()
    let data = {
      to_email: to,
      cc_email: cc,
      subject: subject,
      body: body,
      project_name: projectName,
    }
    createRCA(data)
  }

  const resetForm = () => {
    setTo('')
    setToError(false)
    setCcError(false)
    setCc('')
    setSubject('')
    setProjectName('')
    setBody('')
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  return (
    <Dialog open={open}  maxWidth='lg'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          Create RCA
        </Typography>
      </BootstrapDialogTitle>
      <Box sx={{ padding: '0 16px', height: '450px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='Cc'
              size='small'
              fullWidth
              value={cc}
              onChange={(e) => handleCc(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={ccError}
              helperText={ccError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='Subject'
              size='small'
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              margin='normal'
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='Project Name'
              size='small'
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              margin='normal'
            />
          </Grid>
          <Grid item xs={12} sm={12} className='custom_ck'>
            <CKEditor
              editor={ClassicEditor}
              data={body}
              onReady={() => {}}
              onChange={(event, editor) => {
                const data = editor.getData()
                setBody(data)
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogActions
        sx={{ margin: '24px 10px 10px 0', marginTop: '-30px' }}
        style={ccError || toError ? { paddingTop: '30px' } : {}}
      >
        <CancelButton onClick={handleClose}>CANCEL</CancelButton>
        <Button
          sx={{
            borderRadius: '20px',
            fontSize: '13px',
            height: '42px',
            fontFamily: 'Montserrat-SemiBold',
            width: '100px',
            '&.Mui-disabled': {
              opacity: 0.5,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreateRca}
          disabled={
            !to.length || !cc.length || !body.length || !projectName.length || !subject.length
          }
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RCAList = (props: any) => {
  const {
    getAllRCAs,
    getAllRCAsList,
    getUserDetails,
    createRCA,
    isCreatingRCA,
    isRCACreated,
    resetRCA,
  } = props
  const [selectedRCA, setSelectedRCA] = useState<any | null>(null)
  const [isNewRCADialogOpen, setIsNewRCADialogOpen] = useState(false)

  const handleRowClick = (data: any) => {
    setSelectedRCA(data)
  }

  const handleCloseModal = () => {
    setSelectedRCA(null)
  }

  const handleOpenNewRCADialog = () => {
    setIsNewRCADialogOpen(true)
  }

  const handleCloseNewRCADialog = () => {
    setIsNewRCADialogOpen(false)
  }

  useEffect(() => {
    if (getUserDetails.id) {
      getAllRCAs(getUserDetails.id)
    }
  }, [getUserDetails.id])

  useEffect(() => {
    if (isRCACreated) {
      toast.success('RCA sent successfully')
      resetRCA()
      getAllRCAs(getUserDetails.id)
    }
  }, [isRCACreated])

  return (
    <>
      <Dialog open={isCreatingRCA} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      {/* {getAllRCAsList && getAllRCAsList?.length ? ( */}
      <div style={MainContainer}>
        <ActionButton
          variant='outlined'
          startIcon={<AddTwoToneIcon sx={{ width: 24, height: 24 }} />}
          onClick={handleOpenNewRCADialog}
        >
          NEW RCA
        </ActionButton>
        <CreateRcaDialog
          open={isNewRCADialogOpen}
          createRCA={createRCA}
          onClose={handleCloseNewRCADialog}
        />
        <>
          <RCATable data={getAllRCAsList} onRowClick={handleRowClick} />
          <Modal open={!!selectedRCA} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
              >
                {selectedRCA?.subject}
              </Heading>
              <Typography variant='body1' style={{ fontSize: '12px' }}>
                <div dangerouslySetInnerHTML={{ __html: selectedRCA?.body }}></div>
              </Typography>
            </Box>
          </Modal>
        </>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getAllRCAsList: fetchRCAs.getAllRCAsList(state).fetchRCAs,
    getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingRCA: attendanceUI.getEmpAttendanceList(state).isCreatingRCA,
    isRCACreated: attendanceUI.getEmpAttendanceList(state).isRCACreated,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllRCAs: (userId: any) => dispatch(getRCAs.request({ userId })),
    createRCA: (data: any) => dispatch(createNewRCA.request({ data })),
    resetRCA: () => dispatch(createNewRCA.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RCAList)
