import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import SideDrawer from '../Common/SideDrawer'
import Header from '../Common/Header'
import { Outlet } from 'react-router-dom'
// import { Dashboard } from '@mui/icons-material'
import { connect } from 'react-redux'
import Footer from '../Common/Footer'
// import { getHomePageDetails } from '../../actions'
// import { dashboardEntity } from '../../reducers'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function PersistentDrawerLeft() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <SideDrawer />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '94%',
          height: `calc(100% - 60px)`,
          flexDirection: 'column',
          marginLeft: '77px',
          background: 'rgb(231, 235, 240)',
        }}
      >
        <Header />

        <Outlet />
        <Footer />
      </Box>
    </>
  )
}

// const mapStateToProps = (state: any) => {
//   return {
//     homePageDetails: dashboardEntity.getDashboard(state).getHomePageDetails,
//   }
// }

// const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
//   return {
//     getHomeDetails: () => {
//       dispatch(getHomePageDetails.request())
//     },
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(PersistentDrawerLeft)
