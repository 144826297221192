import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_DESIGNATION,
  FETCH_DESIGNATIONBAND,
  FETCH_EMPINFO,
  FETCH_EMPLOYMENTTYPE,
  FETCH_MARITIAL_STATUS,
  FETCH_QUALIFICATION,
  FETCH_QUALIFICATION_SKILL,
  FETCH_SAPERIOD,
  FETCH_TIMING,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getEmpInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_EMPINFO[SUCCESS]:
        return action.payload
      case FETCH_EMPINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDesignationData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DESIGNATION[SUCCESS]:
        return action.payload
      case FETCH_DESIGNATION[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDesignationBandData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DESIGNATIONBAND[SUCCESS]:
        return action.payload
      case FETCH_DESIGNATIONBAND[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getEmploymentTypeData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_EMPLOYMENTTYPE[SUCCESS]:
        return action.payload
      case FETCH_EMPLOYMENTTYPE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getSAPeriodData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_SAPERIOD[SUCCESS]:
        return action.payload
      case FETCH_SAPERIOD[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTimingData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_TIMING[SUCCESS]:
        return action.payload
      case FETCH_TIMING[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getQualification = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_QUALIFICATION[SUCCESS]:
        return action.payload
      case FETCH_QUALIFICATION[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getQualificationSkill = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_QUALIFICATION_SKILL[SUCCESS]:
        return action.payload
      case FETCH_QUALIFICATION_SKILL[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getEmpInfoData,
    getDesignationData,
    getDesignationBandData,
    getEmploymentTypeData,
    getSAPeriodData,
    getTimingData,
    getQualification,
    getQualificationSkill,
  })
}

export default entity

export const getEmployeePortal = (state: RootState) => state.entities.employeePortal
