import { combineReducers } from 'redux'
import * as dashboardState from './dashboard'
import * as userAuthenticationState from './userAuthentication'
import * as loginUserState from './loginUser'
import * as employeePortalState from './employeePortal'
import * as financeState from './finance'
import * as ServiceRequest from './ServiceRequest'
import * as loanRequest from './LoanRequest'
import * as employeeDataRequest from './employeeList'
import * as statuSummary from './statusSummary'
import * as attendanceState from "./attendance"
import * as fetchRCAs from './fetchRCAs'
import * as fetchIDSRs from './fetchRCAs'
import * as getAssignedRequest from './ServiceRequest'
import * as createUpdatedServiceRequestData from './ServiceRequest'
import * as getDownloadableURL from './ServiceRequest'
import * as getManagerViewData from './dashboard'

export {
  dashboardState,
  userAuthenticationState,
  loginUserState,
  employeePortalState,
  financeState,
  ServiceRequest,
  loanRequest,
  employeeDataRequest,
  statuSummary,
  attendanceState,
  fetchRCAs,
  fetchIDSRs,
  getAssignedRequest,
  createUpdatedServiceRequestData,
  getDownloadableURL,
  getManagerViewData
}

export default combineReducers({
  dashboard: dashboardState.default(),
  userAuthentication: userAuthenticationState.default(),
  ServiceRequest: ServiceRequest.default(),
  loginUser: loginUserState.default(),
  employeePortal: employeePortalState.default(),
  finance: financeState.default(),
  LoanRequest: loanRequest.default(),
  EmployeeDataRequest: employeeDataRequest.default(),
  statuSummary: statuSummary.default(),
  EmployeeAttendanceRequest: attendanceState.default(),
  fetchRCAs: fetchRCAs.default(),
  fetchIDSRs: fetchIDSRs.default(),
  getAssignedRequest: getAssignedRequest.default(),
  createUpdatedServiceRequestData:createUpdatedServiceRequestData.default(),
  getDownloadableURL: getDownloadableURL.default(),
  getManagerViewData: getManagerViewData.default()

})
