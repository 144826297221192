import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_ACCOUNTTYPE,
  FETCH_BANKINFO,
  FETCH_COUNTRY,
  FETCH_STATE,
  FETCH_COMPENSATION_DETAILS,
  GET_TIMESHEET,
  GET_PAY_SLIP,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getBankInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_BANKINFO[SUCCESS]:
        return action.payload
      case FETCH_BANKINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getCountryData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_COUNTRY[SUCCESS]:
        return action.payload
      case FETCH_COUNTRY[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getStateData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_STATE[SUCCESS]:
        return action.payload
      case FETCH_STATE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getCompensationDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_COMPENSATION_DETAILS[SUCCESS]:
        return action.payload
      case FETCH_COMPENSATION_DETAILS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAccountTypeData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ACCOUNTTYPE[SUCCESS]:
        return action.payload
      case FETCH_ACCOUNTTYPE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTimesheetData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_TIMESHEET[SUCCESS]:
        return action.payload
      case GET_TIMESHEET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getPaySlipData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_PAY_SLIP[SUCCESS]:
        return action.payload
      case GET_PAY_SLIP[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getBankInfoData,
    getStateData,
    getCountryData,
    getAccountTypeData,
    getCompensationDetails,
    getTimesheetData,
    getPaySlipData,
  })
}

export default entity

export const getFinance = (state: RootState) => state.entities.finance
