import styled from '@emotion/styled'
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Background, Primary } from '../../utils'
import { FullWidthStack } from '../Common/CommonStyles'

const StyledDrawer = styled(Drawer)<{ open: boolean }>(({ open }) => ({
  width: open ? '210px' : '80px',
  flexShrink: 0,
  transition: 'width 0.3s ease',
  '& .MuiDrawer-paper': {
    width: open ? '210px' : '80px',
    transition: 'width 0.3s ease',
    boxSizing: 'border-box',
    overflowY: 'visible',
    '& ul': {
      overflow: 'hidden',
    },
    background: Primary,
  },
  '& .MuiListItem-root': {
    // padding: '16px 10px 0px 21px !important',
  },
  '& .MuiListItemButton-root': {
    paddingRight: '5px',
    '& svg': {
      height: '18px',
      position: 'relative',
      right: '5px',
    },
  },
  '& .MuiListItemButton-root:hover': {
    background: '#FFFFFF',
    color: '#ffffff',
    '& svg': {
      fill: Primary,
    },
  },
  '& .MuiListItemButton-root.Mui-selected': {
    background: '#FFFFFF',
    color: '#000',
    '& svg': {
      // fill: Primary,
      stroke: Primary,
    },
    '&:hover': {
      background: '#FFFFFF',
      color: '#000',
      '& svg': {
        // fill: Primary,
        // stroke: Primary,
      },
    },
  },

  '& .MuiListItemButton-root:not(.Mui-selected)': {
    background: '#2b4e81 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    backdropflter: 'blur(30px)',
    webkitBackdropFilter: ' blur(30px)',
    color: '#000',
    '& svg': {
      fill: '#ffffff',
      stroke: '#ffffff',
    },
    '& span': { color: '#ffffff' },
  },
  overflow: 'hidden',
}))

const StyledListItemButton = styled(ListItemButton)({
  height: '40px',
  fontSize: '10px',
  borderRadius: '10px',
  marginTop: '5px',
  fontFamily: 'Montserrat-SemiBold',
})

const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '13px',
    fontWeight: '600',
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
  },
})

const SubMenuContainer = styled(Paper)({
  border: `1px solid ${Primary}`,
  marginLeft: '1px',
})

const StyledMenuItem = styled(MenuItem)({
  paddingRight: '50px',
  fontSize: '13px',
  fontWeight: 'bold',
  fontFamily: 'Montserrat-SemiBold',
})

const CustomTabs = styled(Tabs)({
  width: '100%',
  '& .MuiTabs-indicator': {
    backgroundColor: Primary,
  },
})

const CustomTab = styled(Tab)<{ width?: string }>(({ width = 'auto' }) => ({
  width: width,
  textTransform: 'none',
  fontSize: '13.5px',
  fontFamily: 'Montserrat-SemiBold',
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: 'black',
  },
  // borderBottom: '2px solid rgba(128, 128, 128, 0.3)',
}))

const MasterContainer = styled(Stack)({
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
  width: '97%',
  height: '70%',
  minHeight: '65%',
  borderRadius: '10px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundColor: '#FFF',
  marginTop: '10px',
  boxSizing: 'border-box',
  maxWidth: '97%',
  overflow: 'hidden',
})

const ButtonContainer = styled(Stack)({
  width: '100%',
  justifyContent: 'flex-end',
  gap: '2%',
  margin: '15px 18px 0px 0px',
  flexWrap: 'wrap',
  rowGap: '10px',
})

const ButtonContainers = styled(ButtonContainer)({
  margin: '15px 0px 0px 0px',
})

const TabContainer = styled(Stack)({
  justifyContent: 'flex-start',
  width: '95%',
  margin: '0px 30px',
})

const DrawerHeaderStack = styled(FullWidthStack)({
  height: '64px',
  position: 'relative',
  overflow: 'visible',
  justifyContent: 'flex-start',
  paddingLeft: '26px',
})

const ChildList = styled(List)({
  borderLeft: '1px solid gray',
  marginLeft: '40px',
  marginTop: '10px',
  padding: '0px 5px',
})

const IconBox = styled(Box)({
  position: 'absolute',
  height: '60px',
  width: '499px',
  borderRadius: '50%',
  backgroundColor: Background,
  right: '18px',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
})

const GuideModalContainer = styled(Stack)({
  position: 'absolute',
  bottom: -20,
  right: '200px',
  top: '2%',
  transform: 'translateX(-0%)',
  flexDirection: 'column',
})

const CardNo = styled(Typography)({
  ' -webkit-text-stroke': `1px ${Primary}`,
  '-webkit-text-fill-color': '#fff',
  fontWeight: 'bold',
  fontSize: '55px',
})

const CardStepContainer = styled(Stack)({
  minWidth: '220px',
  width: '250px',
  height: '350px',
  minHeight: '300px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '2px 5px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '10px 5px 5px 5px',
  gap: '8px',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  position: 'relative',
  overflow: 'hidden',
})

const CardButton = styled(Box)({
  height: '30px',
  width: '30px',
  borderRadius: '50%',
  border: `1px solid ${Primary}`,
  boxSizing: 'border-box',
  padding: '1px',
  marginRight: '5%',
  '& svg': {
    height: '25px',
    fill: `${Primary}`,
    marginLeft: '3px',
  },
})

const CardStepper = styled(Stepper)({
  '& span': { padding: '0' },
  '& .MuiStepConnector-root': { height: '14px', margin: '-5px 0px 0px 4px', overflow: 'hidden' },
  '& .MuiStepLabel-labelContainer': { marginLeft: '5px' },
  '& .MuiStepIcon-text': {
    display: 'none',
  },
  '& .MuiStepIcon-root': {
    width: '8px',
    height: '8px',
  },
  '& .MuiStepLabel-root': {
    marginTop: '-3px',
  },
  '& .MuiStepLabel-label': {
    fontSize: '12px',
  },
})

const HeirarchyContainer = styled(Stack)({
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '97%',
  width: '98%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'gray lightgray',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'lightgray',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'gray',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-corner': {
    background: 'lightgray',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: Primary,
  },
})

export {
  StyledDrawer,
  StyledListItemButton,
  StyledListItemText,
  SubMenuContainer,
  StyledMenuItem,
  CustomTabs,
  CustomTab,
  MasterContainer,
  ButtonContainer,
  TabContainer,
  DrawerHeaderStack,
  IconBox,
  ChildList,
  ButtonContainers,
  GuideModalContainer,
  CardNo,
  CardStepContainer,
  CardButton,
  CardStepper,
  HeirarchyContainer,
}
