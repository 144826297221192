import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { FormLabel, TextField } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Primary } from '../../utils'
import Divider from '@mui/material/Divider'
import { HeaderHeading } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { fetchBackInfo, fetchQualification, fetchQualificationSkill } from '../../actions'
import { dashboardEntity, employeePortalEntity, dashboardUI } from '../../reducers'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '92%',
  padding: '30px 25px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  margin: 'auto',
  border: '1px solid #DDDDDD',
  marginTop: '20px',
}))

const HeadingPaper = styled(Paper)(({ theme }) => ({
  width: '92%',
  padding: '15px 25px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: Primary,
  color: '#FFFFFF',
  opacity: '1',
  margin: '0px auto 15px auto',
}))

const SelectField = styled(Select)(({ theme }) => ({
  width: '171px',
  height: '38px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    cursor: 'not-allowed',
  },
}))

const SelectFieldBool = styled(Select)(({ theme }) => ({
  width: '124px',
  height: '38px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    cursor: 'not-allowed',
  },
}))

const InputField = styled(TextField)({
  margin: '0',
  height: 'auto',
  borderRadius: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
    cursor: 'not-allowed',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
})

const Label = styled(FormLabel)({
  fontFamily: 'Montserrat-SemiBold',
  fontSize: '14px',
  letterSpacing: '0px',
  opacity: '1',
})

const HeadingLabel = styled(FormLabel)({
  fontFamily: 'Montserrat-SemiBold',
  fontSize: '13px',
  letterSpacing: '0px',
  color: '#000000',
  opacity: '1',
})

const Heading = () => {
  return (
    <HeadingPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Qualification Or Skillset</HeadingLabel>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <HeadingLabel>Graduated</HeadingLabel>
        </Grid>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Passing Year</HeadingLabel>
        </Grid>
        <Grid item xs={3} sm={3}>
          <HeadingLabel>College/University</HeadingLabel>
        </Grid>
        <Grid item xs={2} sm={2}>
          <HeadingLabel>Branch/Subject</HeadingLabel>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <HeadingLabel>Percentage/CGPA</HeadingLabel>
        </Grid>
      </Grid>
    </HeadingPaper>
  )
}

const Graduate = (props: any) => {
  const { isGetBackInfoData } = props
  const [year, setYear] = React.useState(1)
  const handleChange = (event: any) => {
    setYear(event.target.value)
  }

  const [qualification, setQualification] = useState('')
  const [graduated, setGraduated] = useState('')
  const [passingYear, setPassingYear] = useState('')
  const [college, setCollege] = useState('')
  const [branch, setBranch] = useState('')
  const [percentage, setPercentage] = useState('')
  const [skilsData, setSkillData] = useState([])
  const [skillName, setSkillName] = useState('')

  const handleQualification = (event: any) => {
    setQualification(event.target.value)
  }
  const handleGraduated = (event: any) => {
    setGraduated(event.target.value)
  }
  const handlePassingYear = (event: any) => {
    setPassingYear(event.target.value)
  }
  const handleCollege = (event: any) => {
    setCollege(event.target.value)
  }
  const handleBranch = (event: any) => {
    setBranch(event.target.value)
  }
  const handlePercentage = (event: any) => {
    setPercentage(event.target.value)
  }
  const handleSkillName = (event: any) => {
    setSkillName(event.target.value)
  }
  let name: any = []
  let data =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 1)
  data &&
    data.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name.push(qualification_skill_name)
      return null
    })

  let name1: any = []
  let data1 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 2)
  data1 &&
    data1.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name1.push(qualification_skill_name)
      return null
    })

  let name2: any = []
  let data2 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 3)
  data2 &&
    data2.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name2.push(qualification_skill_name)
      return null
    })

  let name3: any = []
  let data3 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 4)
  data3 &&
    data3.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''
      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name3.push(qualification_skill_name)
      return null
    })

  let name4: any = []
  let data4 =
    Object.keys(props.userData).length &&
    props.userData.educationalInfo.filter((item: any) => item.id_qualification_set === 5)
  data4 &&
    data4.map((item: any) => {
      let skill_name = Object.keys(item).length > 0 ? item.skill_name : ''

      let qualification_data =
        props.qualificationSkill?.length &&
        props.qualificationSkill.find((e: any) => e.id === skill_name)
      let qualification_skill_name =
        Object.keys(qualification_data).length > 0
          ? qualification_data.qualification_skill_name
          : ''
      name4.push(qualification_skill_name)
      return null
    })

  return (
    <>
      <StyledPaper elevation={3}>
        <HeaderHeading sx={{ marginBottom: '30px' }}>Background Information</HeaderHeading>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Qualification Or Skillset</HeadingLabel>
          </Grid>
          <Grid item xs={1.5} sm={1.5}>
            <HeadingLabel>Graduated</HeadingLabel>
          </Grid>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Passing Year</HeadingLabel>
          </Grid>
          <Grid item xs={3} sm={3}>
            <HeadingLabel>College/University</HeadingLabel>
          </Grid>
          <Grid item xs={2} sm={2}>
            <HeadingLabel>Branch/Subject</HeadingLabel>
          </Grid>
          <Grid item xs={1.5} sm={1.5}>
            <HeadingLabel>Percentage/CGPA</HeadingLabel>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Graduate school level</Label>
          </Grid>
          {Object.keys(props.userData).length &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 1)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name[index]}
                      size='small'
                      onChange={handleSkillName}
                      inputProps={{ readOnly: true }}
                    >
                      {props.qualificationSkill
                        .filter(
                          (value: any) => value.id_qualification_set === item.id_qualification_set,
                        )
                        .map((filteredValue: any) => (
                          <MenuItem
                            key={filteredValue.id}
                            selected={item.skill_name === filteredValue.id}
                            value={filteredValue.qualification_skill_name}
                          >
                            {item.skill_name === filteredValue.id
                              ? filteredValue.qualification_skill_name
                              : null}
                          </MenuItem>
                        ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}

          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Undergraduate school level</Label>
          </Grid>
          {Object.keys(props.userData).length &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 2)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name1.length && name1[index]}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.skill_name}>
                        <em>{item.skill_name}</em>
                      </MenuItem>
                      <MenuItem value={name1[index]}>{name1[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>High school level</Label>
          </Grid>
          {Object.keys(props.userData).length &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 3)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name2[index]}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name2[index]}>{name2[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>Other</Label>
          </Grid>
          {Object.keys(props.userData).length &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 4)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name3.length && name3[index]}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name3[index]}>{name3[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ marginTop: '8px' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Label>License/ Professional certificate</Label>
          </Grid>
          {Object.keys(props.userData).length &&
            props.userData.educationalInfo
              .filter((item: any) => item.id_qualification_set === 5)
              .map((item: any, index: any) => (
                <>
                  <Grid item xs={2} sm={2}>
                    <SelectField
                      id='demo-simple-select-readonly'
                      value={name4.length && name4[index]}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={name4[index]}>{name4[index]}</MenuItem>
                      <MenuItem value={2}>Value 2</MenuItem>
                      <MenuItem value={3}>Value 3</MenuItem>
                    </SelectField>
                  </Grid>

                  <Grid item xs={1.5} sm={1.5}>
                    <SelectFieldBool
                      id='demo-simple-select-readonly'
                      value={item.graduated}
                      size='small'
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={item.graduated}>
                        <em>{item.graduated}</em>
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </SelectFieldBool>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.year}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <InputField
                      defaultValue={item.degree}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <InputField
                      defaultValue={item.course_of_study}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5} sm={1.5}>
                    <InputField
                      defaultValue={item.percentage}
                      size='small'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              ))}
        </Grid>
      </StyledPaper>
    </>
  )
}

const Undergraduate = () => {
  const [year, setYear] = React.useState(2)
  const handleChange = (event: any) => {
    setYear(event.target.value)
  }

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>UNDERGRADUATE SCHOOL LEVEL</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <SelectField
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Value 1</MenuItem>
            <MenuItem value={2}>Value 2</MenuItem>
            <MenuItem value={3}>Value 3</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <SelectFieldBool
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </SelectFieldBool>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='2018'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField
            defaultValue='College Name'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='Science'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField
            defaultValue='80'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const Highschool = () => {
  const [year, setYear] = React.useState(1)
  const handleChange = (event: any) => {
    setYear(event.target.value)
  }

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>HIGH SCHOOL LEVEL</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <SelectField
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Value 1</MenuItem>
            <MenuItem value={2}>Value 2</MenuItem>
            <MenuItem value={3}>Value 3</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <SelectFieldBool
            id='demo-simple-select-readonly'
            value={year}
            size='small'
            onChange={handleChange}
            inputProps={{ readOnly: true }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </SelectFieldBool>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='2016'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField
            defaultValue='College Name'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField
            defaultValue='Math'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField
            defaultValue='85'
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const Other = () => {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>OTHER</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '162px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '116px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField size='small' />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const ProfessionalCert = () => {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Label>LICENSE/ PROFESSIONAL CERT</Label>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '162px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            size='small'
            style={{ width: '116px' }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>value</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={2} sm={2}>
          <InputField size='small' />
        </Grid>
        <Grid item xs={1.5} sm={1.5}>
          <InputField size='small' />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const PrevPosition = (props: any) => {
  return (
    <>
      {props.userData.previousPositionsHistory?.map((item: any, index: any) => (
        <StyledPaper elevation={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Label>PREVIOUS POSITIONS HELD</Label>
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.position ?? 'NA'}
                label='Position'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.company ?? 'NA'}
                label='Company'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={moment(item?.from_year).format('DD-MM-YYYY') ?? 'NA'}
                label='From'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={moment(item?.to_year).format('DD-MM-YYYY') ?? 'NA'}
                label='To'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.supervisor ?? 'NA'}
                label='Salary per month'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputField
                size='small'
                inputProps={{ readOnly: true }}
                defaultValue={item?.telephone ?? 'NA'}
                label='Annual Salary'
              />
            </Grid>
          </Grid>
        </StyledPaper>
      ))}
    </>
  )
}

const BackgroundInfo = (props: any) => {
  const {
    fetchQualification,
    fetchQualificationSkill,
    fetchUserData,
    isGetBackInfoData,
    qualificationData,
    qualificationSkill,
    userData,
  } = props

  useEffect(() => {
    fetchUserData()
    fetchQualification()
    fetchQualificationSkill()
  }, [])

  if (isGetBackInfoData) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CircularProgress disableShrink />
      </div>
    )
  }
  return (
    <div
      style={{
        backgroundColor: 'rgb(231, 235, 240)',
        width: '100%',
        paddingBottom: '20px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Graduate
            userData={userData}
            qualificationData={qualificationData}
            qualificationSkill={qualificationSkill}
          />
          <PrevPosition userData={userData} />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: dashboardEntity.getDashboard(state).getBackInfoData,
    qualificationData: employeePortalEntity.getEmployeePortal(state).getQualification,
    qualificationSkill: employeePortalEntity.getEmployeePortal(state).getQualificationSkill,
    isGetBackInfoData: dashboardUI.getDashboard(state).isGetBackInfoData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserData: () => dispatch(fetchBackInfo.request()),
    fetchQualification: () => dispatch(fetchQualification.request()),
    fetchQualificationSkill: () => dispatch(fetchQualificationSkill.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundInfo)
