import * as React from 'react'
import { Box, Card, DialogContent, FormControl, InputLabel, MenuItem, Modal, Typography, styled } from '@mui/material'
import { attendanceEntity, dashboardEntity } from '../../reducers'
import { connect } from 'react-redux'
import { getAttendanceTimesheet, getStatusSummary } from '../../actions'
import { RootState } from '../../configureStore'
import { statuSummary } from '../../reducers/entities'
import './StatusSummary.css'
import { HeaderHeading } from '../Common/CommonStyles'
import { Heading } from '../Pages/Styles'
import { useEffect, useState } from 'react'
import { SelectField } from '../Common/ComponentCommonStyles'
import { Primary } from '../../utils'
import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'



function StatusSummary({ getUserDetails, getStatusSummary, getStatusSummaryData, fetchAttendanceTimesheet, AttendanceTimesheet }: any) {

    const [openModal, setOpenModal] = React.useState(false);
    const [selectedSummary, setSelectedSummary] = React.useState<any>();

    const handleOpenModal = (summary: any) => {
        setSelectedSummary(summary);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedSummary(null);
        setOpenModal(false);
    };

    const [selectedMonth, setSelectedMonth] = useState('')


    useEffect(() => {
        if (getUserDetails.id) {
            fetchAttendanceTimesheet(getUserDetails.id)
        }
    }, [getUserDetails.id])

    useEffect(() => {
        if (AttendanceTimesheet.length) {
            setSelectedMonth(AttendanceTimesheet[0].id)
            getStatusSummary({ tID: AttendanceTimesheet[0].id, userId: getUserDetails.id })
        }
    }, [AttendanceTimesheet])


    const handleSelectMonth = React.useCallback(
        (event: any) => {
            const selectedMonthId = event.target.value
            setSelectedMonth(selectedMonthId)
            getStatusSummary({ tID: selectedMonthId, userId: getUserDetails.id })
        },
        [getUserDetails.id, getStatusSummary],
    )

    const parseStatusText = (text: string) => {
        const boldText = text.replace(/\*\*(.*?)\*\*/g, '<strong style="margin: -33px;">$1</strong>');
        const lineBreaks = boldText.replace(/\\n/g, '<br>');
        const listItems = lineBreaks.replace(/- (.*?)(?=(<br>|$))/g, '<li style="margin: -6px;">$1</li>');
        const unorderedList = `<ul>${listItems}</ul>`;
        return <div className='modify-summary' dangerouslySetInnerHTML={{ __html: unorderedList }} />;
    };

    const StyledFormControl = styled(FormControl)(({ theme }) => ({
        marginLeft: '-38px',
    }))

    const StyledMenuItem = styled(MenuItem)({
        ' & svg': {
            height: '22px',
            fill: Primary,
        },
        '& .MuiButtonBase-root-MuiMenuItem-root': {
            display: 'flex',
        },
    })

    const StatusSummaryCard1 = (
        <React.Fragment>
            <div className='heading'>
                <HeaderHeading sx={{ marginTop: '20px', width: '88%', position: 'relative', left: '3%' }}>Summary</HeaderHeading>
                <Box sx={{ position: 'relative', top: '10px' }}>
                    <Box sx={{ width: '160px' }}>
                        <StyledFormControl>
                            <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                            <SelectField
                                sx={{ borderRadius: '22px' }}
                                variant='outlined'
                                label='Select Month:'
                                value={selectedMonth}
                                onChange={handleSelectMonth}
                            >
                                {AttendanceTimesheet.map((option: any, index: number) => (
                                    <StyledMenuItem key={index} value={option?.id}>
                                        {moment(option?.start_date).format('MMMM YYYY')}
                                    </StyledMenuItem>
                                ))}
                            </SelectField>
                        </StyledFormControl>
                    </Box>
                </Box>
            </div>
            <div className='status_summary_container'>
                {Object.keys(getStatusSummaryData).length && getStatusSummaryData?.length ? (
                    getStatusSummaryData?.map((summaryItem: any) => (
                        <Card
                            key={summaryItem.employee_id}
                            variant='outlined'
                            style={{ margin: '10px 0', padding: '10px', cursor: 'pointer' }}
                            className='card_wrapper'
                            onClick={() => handleOpenModal(summaryItem)}
                        >
                            <div className='card_header'>
                                {new Date(summaryItem.IDSR_date).toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                })}
                            </div>
                            {parseStatusText(summaryItem.summary)}
                        </Card>
                    ))
                ) : (
                    <Typography variant='h4' style={{ marginBottom: "150px", width: '100%', textAlign: 'center', paddingTop: '5%' }}>No records found</Typography>
                )}
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50vw',
                        maxWidth: '500px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '70vh',
                        overflow: 'auto',
                        borderRadius: '10px',
                    }}
                >
                    <IconButton
                        aria-label='close'
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[600],
                            marginTop: '15px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Heading
                        style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0px',
                            position: 'relative',
                        }}
                    >
                        {selectedSummary?.subject}
                    </Heading>
                    <Typography variant="body1" style={{ fontSize: '12px' }}>
                        <div dangerouslySetInnerHTML={{ __html: selectedSummary?.body }}></div>
                    </Typography>
                </Box>
            </Modal>
        </React.Fragment>
    );

    return (
        <>

            <div style={{ width: '100%' }}>
                <div style={{ padding: '12px' }}>
                    <div style={{ flexWrap: 'wrap' }}>
                        <Card variant='outlined'>
                            {StatusSummaryCard1}
                        </Card>
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        getStatusSummaryData: statuSummary.getAllStatusSummaries(state).getStatusSummaryDetails,
        getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
        AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
    return {
        fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
        getStatusSummary: (data: any) => {
            dispatch(getStatusSummary.request(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusSummary)
