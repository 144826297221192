import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Box, CircularProgress, Dialog, ThemeProvider, Toolbar, styled } from '@mui/material'
import {
  AppBarTheme,
  HeaderLogo,
  HeaderProfileName,
  HeaderRightContent,
  HeaderToolbar,
  loaderProps,
} from './CommonStyles'
import { NavLink, useNavigate } from 'react-router-dom'

import SearchBox from './SearchBox'
import { CurrentLoginUserProfile, KuberLogo } from '../../utils/StaticData'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { connect } from 'react-redux'
import { getUserDetails, getUserLoginUser } from '../../actions'
import { dashboardEntity } from '../../reducers'
import { googleLogout } from '@react-oauth/google'

// const settings = ['Password', 'Manage Notification', 'Sign Out']
const settings = ['Sign Out']

function Header({ userDetails, getUser, getUserLogOut }: any) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [processing, setProcessing] = React.useState<boolean>(false)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const navigate = useNavigate()

  const handleCloseUserMenu = () => {
    setProcessing(true)
    getUserLogOut()
    googleLogout()
    localStorage.clear()
    setTimeout(() => {
      setProcessing(false)
      navigate('/')
    }, 3000)
    setAnchorElUser(null)
  }

  React.useEffect(() => {
    getUser()
  }, [])

  return (
    <>
      {processing && (
        <Dialog open={processing} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <ThemeProvider theme={AppBarTheme}>
        <AppBar position='static'>
          <Container maxWidth='xl'>
            <HeaderToolbar>
              <Box>
                <a style={{margin: -10}} href='/home/dashboard'>
                  <HeaderLogo src={KuberLogo} />
                </a>
              </Box>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                <SearchBox />
              </Box>

              <HeaderRightContent>
                <span style={HeaderProfileName}> {userDetails.name} </span>
                <Tooltip title=''>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt='Remy Sharp'
                      src={
                        userDetails?.image_path ? userDetails?.image_path : CurrentLoginUserProfile
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={(e) => setAnchorElUser(null)}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign='center'>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </HeaderRightContent>
            </HeaderToolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </>
  )
}
// export default Header
const mapStateToProps = (state: any) => {
  return {
    // loginStatus: loginUserUI.getLoginUserData(state).isUserLoginDone,
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getUser: () => {
      dispatch(getUserDetails.request())
    },
    getUserLogOut: () => {
      dispatch(getUserLoginUser.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
