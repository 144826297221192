import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import { Box, CircularProgress, Dialog } from '@mui/material'
import { Typography } from '@mui/material'
import { HeaderHeading, StyledTableCell, StyledTableRow, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { fetchAsset } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}
const toCamelCase = (str: any) => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter: any, index: any) => {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    })
    .replace(/\s+/g, '')
}

const AssetTable = (props: any) => {
  const navigate = useNavigate()

  return (
    <>
      <StyledPaper>
        <Box sx={{ textAlign: 'left' }}>
          <Box
            onClick={() => navigate(-1)}
            sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
          >
            <ArrowBack />
          </Box>
        </Box>

        <HeaderHeading>Asset Details</HeaderHeading>

        <TableContainer
          sx={{
            margin: '20px 0',
          }}
          component={Paper}
        >
          <Table
            sx={{
              minWidth: 700,
            }}
            aria-label='customized table'
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>LAPTOP ID</StyledTableCell>
                <StyledTableCell>RAM</StyledTableCell>
                <StyledTableCell>HARD DISK</StyledTableCell>
                <StyledTableCell>MAKE</StyledTableCell>
                <StyledTableCell>ASSIGNED ON</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data && props.data?.length > 0 ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component='th' scope='row'>
                      {data.laptop_no}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.ram_size}</StyledTableCell>
                    <StyledTableCell align='left'>{data.hard_disk_size}</StyledTableCell>
                    <StyledTableCell align='left'>{data.laptopName}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(data.assigned_on).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </StyledPaper>
    </>
  )
}

const AssignedAsset = (props: any) => {
  const { fetchAssetData, AssetData, loaderState } = props

  useEffect(() => {
    fetchAssetData()
  }, [])
  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {!loaderState && AssetData && AssetData?.length && (
        <div style={MainContainer}>
          <AssetTable data={AssetData} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    AssetData: dashboardEntity.getDashboard(state).getAssetData,
    loaderState: dashboardUI.getDashboard(state).isAssetsData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAssetData: () => dispatch(fetchAsset.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedAsset)
