import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { RESET } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, LOGIN_USER } = actionTypes

const ui = () => {
  const isUserLoginDone = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN_USER[SUCCESS]:
        return true
      case LOGIN_USER[FAILURE]:
      case LOGIN_USER[REQUEST]:
        return false
      case LOGIN_USER[RESET]:
        return false
      default:
        return state
    }
  }

  const isUserLoginFailed = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN_USER[SUCCESS]:
      case LOGIN_USER[REQUEST]:
        return false
      case LOGIN_USER[FAILURE]:
        return true
      case LOGIN_USER[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isUserLoginDone,
    isUserLoginFailed,
  })
}

export default ui

export const getLoginUserData = (state: RootState) => state.ui.loginUser
