import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { FETCH_ATTENDANCE, FETCH_ATTENDANCE_TIMESHEET } from '../actions/actiontypes'
const { REQUEST } = actionTypes

function* getEmployeeAttendance(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendance,
      data,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE)
  }
}

function* getAttendanceTimesheet(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendanceTimesheet,
      data.userId,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE_TIMESHEET)
  }
}

export const sagas = {
  watchEmployeeAttendance: takeLatest(actionTypes.FETCH_ATTENDANCE[REQUEST], getEmployeeAttendance),
  watchAttendanceTimesheet: takeLatest(
    actionTypes.FETCH_ATTENDANCE_TIMESHEET[REQUEST],
    getAttendanceTimesheet,
  ),
}
