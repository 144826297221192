import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import './IDSR.scss'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
} from '@mui/material'
import { Typography, Modal } from '@mui/material'
import {
  HeaderHeading,
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { createNewIDSR, getAttendanceTimesheet, getIDSRs } from '../../actions'
import { attendanceEntity, attendanceUI, dashboardEntity } from '../../reducers'
import { fetchIDSRs } from '../../reducers/entities'
import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

interface IFormValues {
  projectName: string
  timeSpend: string
  taskDescription: string
}

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  float: 'right',
  marginTop: '15px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: 'Montserrat-Medium',
  top: '38px',
  right: '50px',
}))

const StyledFormControl = styled(FormControl)(() => ({
  marginLeft: '-38px',
}))

const InputField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))
const InputField1 = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'white',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface ICancelButton {
  fontSize?: string
  height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: `${fontSize}`,
  height: `${height}`,
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}
const SelectField = styled(Select)(() => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
  },
}))

const IDSRTable = (props: any) => {
  const {isFetchingIDSRs} = props;
  
  return (
    <>
      <Dialog open={isFetchingIDSRs} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <StyledPaper>
        <div>
          <HeaderHeading sx={{ marginTop: '-5px', marginLeft: '190px' }}>IDSRs</HeaderHeading>
        </div>
        <TableContainer sx={{ margin: '36px 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE (DAY)</StyledTableCell>
                <StyledTableCell>SUBJECT</StyledTableCell>
                <StyledTableCell>PROJECT NAME</StyledTableCell>
                <StyledTableCell>TIME SPENT (MINS)</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-GB')}`} (
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}`}
                      )
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                    <StyledTableCell align='left'>{data.project_name}</StyledTableCell>
                    <StyledTableCell align='left'>{data.time_spent}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </StyledPaper>
    </>
  )
}

const CreateIDSRDialog = ({
  open,
  onClose,
  createIDSR,
}: {
  open: boolean
  onClose: () => void
  createIDSR: (data: any) => void
}) => {
  const [to, setTo] = useState('')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState('')
  const [subject, setSubject] = useState('')
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      timeSpend: '',
      taskDescription: '',
    },
  ])

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        projectName: '',
        timeSpend: '',
        taskDescription: '',
      },
    ])
  }

  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        timeSpend: value,
      }
      return newData
    })
  }
  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return value.projectName === '' || value.timeSpend === '' || value.taskDescription === ''
      }) ||
      toError ||
      !subject ||
      !to
    return hasError
  }

  const handleCreateIDSR = () => {
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      const data = {
        to: to,
        subject: subject,
        cc: cc,
        idsrData: formValues,
      }
      createIDSR(data)
      resetForm()
    }
  }

  const resetForm = () => {
    setTo('')
    setToError(false)
    setCcError(false)
    setCc('')
    setSubject('')
    setFormValues([
      {
        projectName: '',
        timeSpend: '',
        taskDescription: '',
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  return (
    <Dialog open={open} maxWidth='lg' className='create_IDSR_wrapper'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          Create IDSR
        </Typography>
      </BootstrapDialogTitle>
      <Box sx={{ padding: '0 16px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField1
              id='outlined-required'
              label='Cc'
              size='small'
              fullWidth
              value={cc}
              onChange={(e) => handleCc(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={ccError}
              helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='Subject'
              size='small'
              placeholder='IDSR (MM/DD/YYYY)'
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              margin='normal'
            />
          </Grid>

          {formValues.map((element, index) => (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                className='project_wrapper'
                sx={{
                  paddingBottom: '20px',
                  height: '550px',
                }}
              >
                <Grid item xs={6} sm={6}>
                  <InputField
                    id='outlined-required'
                    label='Project Name'
                    size='small'
                    fullWidth
                    value={formValues[index]?.projectName || ''}
                    onChange={(e) => handleProjectNameChange(index, e.target.value)}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    id='outlined-required'
                    label='Time Spent (Minutes)'
                    size='small'
                    fullWidth
                    value={formValues[index]?.timeSpend || ''}
                    onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12} sm={12} className='custom_ck'>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formValues[index]?.taskDescription || ''}
                    onReady={() => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      handleTaskDescriptionChange(index, data)
                    }}
                  />
                </Grid>
                <Box className='delete_wrapper'>
                  {index !== 0 && (
                    <CancelButton
                      fontSize='12px'
                      height='35px'
                      onClick={() => deleteFormFields(index)}
                    >
                      Delete Task
                    </CancelButton>
                  )}

                  {(index === formValues.length - 1 ||
                    (index === 0 && formValues.length === 1)) && (
                    <Button
                      className='delete_button'
                      sx={{
                        borderRadius: '20px',
                        fontSize: '12px',
                        height: '35px',
                        fontFamily: 'Montserrat-SemiBold',
                        width: '100px',
                        '&.Mui-disabled': {
                          opacity: 0.5,
                          color: '#ffffff',
                          cursor: 'not-allowed',
                        },
                      }}
                      onClick={addFormFields}
                    >
                      + Add Task
                    </Button>
                  )}
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>

      <DialogActions className='button_wrapper'>
        <CancelButton onClick={handleClose}>CANCEL</CancelButton>
        <Button
          sx={{
            borderRadius: '20px',
            fontSize: '13px',
            height: '42px',
            fontFamily: 'Montserrat-SemiBold',
            width: '100px',

            '&.Mui-disabled': {
              opacity: 0.5,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreateIDSR}
          disabled={handleCheckError()}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const IDSR = (props: any) => {
  const {
    createIDSR,
    isCreatingIDSR,
    isIDSRCreated,
    getAllIDSRsList,
    resetIDSR,
    fetchAttendanceTimesheet,
    getUserById,
    AttendanceTimesheet,
    fetchAttendanceData,
    isFetchingIDSRs,
  } = props

  const [selectedIDSR, setSelectedIDSR] = useState<any | null>(null)
  const [isNewIDSRDialogOpen, setisNewIDSRDialogOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState('')

  const handleRowClick = (data: any) => {
    setSelectedIDSR(data)
  }

  const handleCloseModal = () => {
    setSelectedIDSR(null)
  }

  const handleOpenNewIDSRDialog = () => {
    setisNewIDSRDialogOpen(true)
  }

  const handleCloseNewIDSRialog = () => {
    setisNewIDSRDialogOpen(false)
  }

  useEffect(() => {
    if (getUserById?.id) {
      fetchAttendanceTimesheet(getUserById.id)
    }
  }, [getUserById?.id])

  useEffect(() => {
    if (isIDSRCreated) {
      toast.success('IDSR sent successfully')
      fetchAttendanceTimesheet(getUserById.id)
      resetIDSR()
    }
  }, [isIDSRCreated])

  useEffect(() => {
    if (AttendanceTimesheet?.length) {
      setSelectedMonth(AttendanceTimesheet[0].id)
      fetchAttendanceData({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
    }
  }, [AttendanceTimesheet])

  const handleSelectMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedMonth(selectedMonthId)
      fetchAttendanceData({
        userId: getUserById?.id,
        tId: selectedMonthId,
      })
    },
    [getUserById?.id, fetchAttendanceData],
  )

  const fetchAttendanceData1 = (data: any) => {
    fetchAttendanceData({ userId: getUserById.id, tId: data.tId })
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  return (
    <>
      <Dialog open={isCreatingIDSR} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <div style={MainContainer}>
        <Box sx={{ display: 'flex', justifyContent: 'end', float: 'right' }}>
          <Box sx={{ width: '200px', position: 'relative', right: '10%', top: '50px' }}>
            <StyledFormControl>
              <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
              <SelectField
                sx={{ borderRadius: '100px', marginTop: '5px' }}
                variant='outlined'
                type='small'
                label='Select Month:'
                value={selectedMonth}
                onChange={handleSelectMonth}
              >
                {AttendanceTimesheet?.map((option: any, index: number) => (
                  <StyledMenuItem key={index} value={option?.id}>
                    {moment(option?.start_date).format('MMMM YYYY')}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </StyledFormControl>
          </Box>
          <ActionButton
            variant='outlined'
            startIcon={<AddTwoToneIcon sx={{ width: 20, height: 24 }} />}
            onClick={handleOpenNewIDSRDialog}
          >
            NEW IDSR
          </ActionButton>
        </Box>

        <CreateIDSRDialog
          open={isNewIDSRDialogOpen}
          createIDSR={createIDSR}
          onClose={handleCloseNewIDSRialog}
        />

        <IDSRTable
          data={getAllIDSRsList}
          onRowClick={handleRowClick}
          fetchAttendanceData1={fetchAttendanceData1}
          isFetchingIDSRs={isFetchingIDSRs}
        />
        <Modal open={!!selectedIDSR} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70vw',
              maxWidth: '75%',
              bgcolor: 'background.paper',
              p: 4,
              maxHeight: '80vh',
              overflowY: 'auto',
              borderRadius: '10px',
            }}
          >
            <IconButton
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[600],
                marginTop: '15px',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Heading
              style={{
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'sans-serif',
                letterSpacing: '0px',
                position: 'relative',
              }}
              dangerouslySetInnerHTML={{
                __html: selectedIDSR?.subject || '',
              }}
            />
            <Typography variant='body1' style={{ fontSize: '12px' }}>
              <div>{convertTextToHTML(selectedIDSR?.body || '')}</div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingIDSR: attendanceUI.getEmpAttendanceList(state).isCreatingIDSR,
    isFetchingIDSRs: attendanceUI.getEmpAttendanceList(state).isFetchingIDSRs,
    isIDSRCreated: attendanceUI.getEmpAttendanceList(state).isIDSRCreated,
    getAllIDSRsList: fetchIDSRs.getAllIDSRList(state).fetchIDSRs,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createIDSR: (data: any) => dispatch(createNewIDSR.request({ data })),
    resetIDSR: () => dispatch(createNewIDSR.reset()),
    getAllIDSRs: (userId: any) => dispatch(getIDSRs.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getIDSRs.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IDSR)
