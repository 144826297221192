import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
  Avatar,
  Typography,
  CircularProgress,
  Dialog,
} from '@mui/material'
import { financeEntity, employeePortalEntity, financeUI } from '../../reducers'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PdfIcon from '../../assets/images/pdf_image.png'
import { useEffect, useState } from 'react'
import { CurrentLoginUserProfile, GetCompensationTudipLogo } from '../../utils/StaticData'
import React from 'react'
import { withStyles } from '@material-ui/core'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Primary } from '../../utils'
import { VerticalAlignCenter } from '@mui/icons-material'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import html2pdf from 'html2pdf.js'
import { getCompensationDetails } from '../../actions'
import moment from 'moment'
import { loaderProps } from '../Common/CommonStyles'
import { numberFormat } from '../../utils/currency-format'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '80%',
  padding: '30px 45px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  margin: '0px 5px',
  letterSpacing: '0px',
}))

const SelectField = styled(Select)(({ theme }) => ({
  width: '240px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
}))

const Heading: React.CSSProperties = {
  margin: '0px 0px 10px 0px',
  fontSize: '22px',
  textAlign: 'center',
  fontFamily: 'Montserrat-Medium',
}

const SubHeadingTop: React.CSSProperties = {
  margin: '40px 5px 8px 0px',
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-Medium',
  color: '#555555',
  display: 'flex',
  alignItems: 'flex-start',
  letterSpacing: '0px',
}

const SubHeading: React.CSSProperties = {
  margin: '0px 5px 8px 0px',
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-Medium',
  color: '#555555',
  display: 'flex',
  alignItems: 'flex-start',
  letterSpacing: '0px',
}

const SideHeadings = styled(Typography)(({ theme }) => ({
  margin: '35px 0px',
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
}))

const StyledImage = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: '3px 10px',
    backgroundColor: Primary,
    fontSize: '15px',
    border: '0.1px solid grey',
    color: theme.palette.common.white,
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '3px 10px',
    fontSize: '15px',
    border: '0.1px solid grey',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
  },
}))

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: '2px 10px',
    backgroundColor: Primary,
    color: theme.palette.common.white,
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    borderBottom: 'none',
    fontFamily: 'Montserrat-SemiBold',
  },
}))

const StyleTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: '2px 10px', // Adjust the padding here
    border: '0.1px solid grey',
    color: '#555555',
    fontSize: '15px',
    fontFamily: 'Montserrat-SemiBold',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '2px 10px', // Adjust the padding here
    border: '0.1px solid grey',
    color: '#5A606B',
    fontSize: '15px',
    fontFamily: 'Montserrat-Medium',
  },
}))

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}

const handleExportPDF = () => {
  const element = document.getElementById('pdf-export')

  if (element) {
    const options = {
      margin: 10,
      filename: 'Compensation.pdf',
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }
    const imgElement = element.querySelector('img')

    if (imgElement) {
      // Show the image only in the exported PDF
      imgElement.style.display = 'block'

      html2pdf().set(options).from(element).save()

      // Reset the image display after exporting
      imgElement.style.display = 'none'
    }
  }
}

const CompensationDetails = (props: any) => {
  const { compensationData, fetchCompensationTypeData } = props
  const [compensationPeriod, setCompensationPeriod] = useState(
    `${compensationData?.compensationId + ',' + compensationData?.apoId}`,
  )

  const handleCompensationPeriod = (event: any) => {
    setCompensationPeriod(event.target.value)
    if (event.target.value.trim()) {
      fetchCompensationTypeData(event.target.value)
    }
  }

  return (
    <StyledPaper elevation={0}>
        {Object.keys(compensationData).length ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div>
                <StyledFormControl>
                  <InputLabel id='demo-simple-select-readonly-label'>
                    {' '}
                    Compensation period:
                  </InputLabel>
                  <SelectField
                    value={compensationPeriod}
                    label='Compensation Period:'
                    onChange={handleCompensationPeriod}
                  >
                    {compensationData?.employeeCompensations?.map(
                      (empCompensation: any, index: number) => (
                        <StyledMenuItem
                          key={index}
                          value={`${
                            empCompensation?.id_compensation +
                            ',' +
                            empCompensation?.id_appraisal_period_opened
                          }`}
                        >
                          Compensation ({moment(empCompensation.created_at).format('DD/MM/YYYY')})
                        </StyledMenuItem>
                      ),
                    )}
                  </SelectField>
                </StyledFormControl>
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                variant='outlined'
                sx={{
                  fontSize: '14px',
                  height: '42px',
                  float: 'right',
                  letterSpacing: '0px',
                  borderRadius: '20px',
                  padding: '5px 20px',
                }}
                startIcon={
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <img src={PdfIcon} alt='PDF' />
                  </Avatar>
                }
                onClick={handleExportPDF}
              >
                EXPORT AS PDF
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ width: '100%' }} id='pdf-export'>
                <Grid item xs={12} sm={12}>
                  <img src={GetCompensationTudipLogo} alt='Tudip Logo' style={{ display: 'none' }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <p style={Heading}>
                    {compensationData?.companyName
                      ? compensationData?.companyName?.toUpperCase()
                      : ''}
                  </p>
                  <p style={Heading}>{compensationData?.compensationName}</p>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {/* <p style={SubHeadingTop}>Employee Id: &nbsp; {compensationData?.employeeId}</p>
                  <p style={SubHeading}>Employee Name: &nbsp; {compensationData?.employeeName}</p> */}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <SideHeadings>ALLOWANCES</SideHeadings>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TableContainer
                    component={Paper}
                    style={{ overflowX: 'auto', display: 'flex', border: '0.1px solid grey' }}
                  >
                    <Table sx={{ width: '100%' }} aria-label='customized table'>
                      <TableHead>
                        <StyledTableCell rowSpan={2} align='center'>
                          Component
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          <div style={divStyle}>
                            Monthly (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          <div style={divStyle}>
                            Annual (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                          </div>
                        </StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {compensationData?.Allowances?.CompensationDetails?.map(
                          (compensation: any, index: number) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell component='th' scope='row' align='left'>
                                {compensation.component}
                              </StyledTableCell>
                              <StyledTableCell align='right'>
                                {compensation?.monthlyPayPeriodAmount
                                  ? numberFormat(compensation?.monthlyPayPeriodAmount)
                                  : 0}
                              </StyledTableCell>
                              <StyledTableCell align='right'>
                                {compensation?.yearlyPayPeriodAmount
                                  ? numberFormat(compensation?.yearlyPayPeriodAmount)
                                  : 0}
                              </StyledTableCell>
                            </StyledTableRow>
                          ),
                        )}
                        {
                          <StyledTableRow>
                            <StyledTableCell component='th' scope='row' align='left'>
                              {'Total Gross Salary'}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {compensationData?.Allowances?.totalMonthlyGrossSalary
                                ? numberFormat(compensationData?.Allowances?.totalMonthlyGrossSalary)
                                : 0}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {compensationData?.Allowances?.totalYearlyGrossSalary
                                ? numberFormat(compensationData?.Allowances?.totalYearlyGrossSalary)
                                : 0}
                            </StyledTableCell>
                          </StyledTableRow>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <SideHeadings>COMPANY CONTRIBUTIONS</SideHeadings>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TableContainer
                    component={Paper}
                    style={{ overflowX: 'auto', display: 'flex', border: '0.1px solid grey' }}
                  >
                    <Table sx={{ width: '100%' }} aria-label='customized table'>
                      <TableHead>
                        <StyledTableCell rowSpan={2} align='center'>
                          Retiral and Other Benefits
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          <div style={divStyle}>
                            Yearly (<CurrencyRupeeIcon sx={{ fontSize: '15px' }} />)
                          </div>
                        </StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {compensationData?.CompanyContributions?.contributionDetails?.map(
                          (compensation: any, index: number) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell component='th' scope='row' align='left'>
                                {compensation?.contributionName}
                              </StyledTableCell>
                              <StyledTableCell align='right'>
                                {compensation?.yearlyPayPeriodAmount
                                  ? numberFormat(compensation?.yearlyPayPeriodAmount)
                                  : 0}
                              </StyledTableCell>
                            </StyledTableRow>
                          ),
                        )}
                        <StyledTableRow>
                          <StyledTableCell component='th' scope='row' align='left'>
                            {'Total Salary'}
                          </StyledTableCell>
                          <StyledTableCell align='right'>
                            {compensationData?.totalSalary
                              ? numberFormat(compensationData?.totalSalary)
                              : 0}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Typography variant='h4'>No record found</Typography>
        )}
    </StyledPaper>
  )
}

const Compensation = (props: any) => {
  const { compensationData, fetchCompensationTypeData, loaderState } = props

  useEffect(() => {
    fetchCompensationTypeData('')
  }, [])

  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}

      {!loaderState && !Object.keys(compensationData).length ? (
        <div
          style={{
            backgroundColor: '#ffffff',
            width: '100%',
            height: '18em',
            overflow: 'auto',
            paddingTop: '10em',
          }}
        >
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            No compensation found
          </Typography>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            Please contact HR Team for more information
          </Typography>
        </div>
      ) : null}
      {/* To check data is present before rendering the compensation */}
      {Object.keys(compensationData).length ? (
        <div
          style={{
            backgroundColor: 'rgb(231, 235, 240)',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CompensationDetails
                compensationData={compensationData}
                fetchCompensationTypeData={fetchCompensationTypeData}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    compensationData: financeEntity.getFinance(state).getCompensationDetails,
    loaderState: financeUI.getFinance(state).isCompensationData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCompensationTypeData: (data: any) => dispatch(getCompensationDetails.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Compensation)
