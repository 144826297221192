import { GetAccessToken, LoginAuthData } from '../components/Types'
import * as RestAPI from './rest'

export const loginUserEndpoint = (data: LoginAuthData) =>
  RestAPI.POST(`/google-authentication`, data)

export const getUserDataEndPoint = () => RestAPI.GET(`/users`)

export const getBackInfoDataEndPoint = () => RestAPI.GET(`/users/background-info`)

export const getHolidaysEndPoint = () => RestAPI.GET(`/holidays`)

export const getHighlightsEndPoint = () => RestAPI.GET(`/highlights`)

export const getAssetsEndPoint = () => RestAPI.GET(`/assets`)

export const getEmpInfoDataEndPoint = () => RestAPI.GET(`/users/emp-info`)

export const getDesignationEndPoint = () => RestAPI.GET(`/users/designation`)

export const getDesignationBandEndPoint = () => RestAPI.GET(`/users/designation-band`)

export const getEmployementTypeEndPoint = () => RestAPI.GET(`/users/employeement-type`)

export const getSAPeriodEndPoint = () => RestAPI.GET(`/users/service-agreement`)

export const getTimingEndPoint = () => RestAPI.GET(`/users/office-time`)

export const getBasicInfoPoint = (data: any) => RestAPI.GET(`/users/basic-info?userId=${data.userId}&isGlobal=${data.isGlobal}`)

export const getSREndPoint = (data: any) =>
  RestAPI.GET(`/service-requests?page=${data.page}&pageSize=${data.pageSize}`)

export const getAssignedSREndPoint = (data: any) =>
  RestAPI.GET(`/assigned-requests?page=${data.page}&pageSize=${data.pageSize}`)

export const getBankInfoEndPoint = () => RestAPI.GET(`/bank-info`)

export const getDrsEndPoint = () => RestAPI.GET(`users/drs-list`)

export const getCountryEndPoint = () => RestAPI.GET(`/users/countries`)

export const getTimesheetEndPoint = () => RestAPI.GET(`/timesheets`)

export const getLeaveTimesheetEndPoint = () => RestAPI.GET(`leaves/leaveTimesheets`)

export const getPaySlipEndPoint = (data: any) => RestAPI.GET(`/payslips?tID=${data}`)

export const getLeaveDataEndPoint = (data: any) =>
  RestAPI.GET(`leaves/underlings?status=${data.status}&tID=${data.timeSheetId}`)

export const getAcceptLeaveDataEndPoint = (data: any) =>
  RestAPI.POST(`leaves/statusUpdate`, { ...data })

export const getRejectLeaveDataEndPoint = (data: any) =>
  RestAPI.GET(`leaves/underlings?tID=${data}`)

export const getProjectDetailsEndPoint = () => RestAPI.GET(`users/projects`)

export const getStateEndPoint = () => RestAPI.GET(`/users/states`)

export const getAccountTypeEndPoint = () => RestAPI.GET(`/bank-info/account-type`)

export const getMaritalStatusEndPoint = () => RestAPI.GET(`/users/marital-status`)

export const getLeaveDetailsEndPoint = (data: any) => RestAPI.GET(`/leaves`, data)

export const getDepartmentListEndPoint = () => RestAPI.GET(`/department`)

export const getUserDetails = () => RestAPI.GET(`users/user-info`)

export const getUserByIdEndPoint = (data: any) => RestAPI.GET(`users/user-by-id/${data.id}`)

export const getReferredByIdEndPoint = (data: any) => RestAPI.GET(`users/user-by-id/${data.id}`)

export const getHomePageDetails = () => RestAPI.GET(`users/home-page`)

// export const getIssueTypeListEndPoint = (deptId: any) => RestAPI.GET('/type', { dept_id: deptId })

export const getIssueTypeListEndPoint = (data: any) => RestAPI.GET('type', data)

export const getQualificationEndPoint = () => RestAPI.GET(`/users/qualification-set`)

export const getQualificationSkillEndPoint = () => RestAPI.GET(`/users/qualification-skill-set`)

export const getAccessTokenEndPoint = (data: GetAccessToken) =>
  RestAPI.OAuthPOST(`oauth/token`, data)

export const logoutUserEndpoint = () => RestAPI.OAuthLogout(`oauth/logout`)

export const getLoginUser = () => RestAPI.GET('login')

export const createNewRequest = (data: any) => RestAPI.POST1('service-requests', data)

export const getCompensationDetailsEndPoint = (data: any) =>
  RestAPI.GET(`compensations?compensationString=${data}`)

export const sendLoanRequest = (data: any) => RestAPI.POST('loans', data)

export const getLoanDetailsEndPoint = () => RestAPI.GET('loans')

export const getLoanInstallmentHistoryEndPoint = (data: any) =>
  RestAPI.GET(`loans/loanDetailsById?loanId=${data}`)

export const getAllEmployees = () => RestAPI.GET('/users/getAllEmployees')

export const addNewComment = (data: any) => RestAPI.POST('/comments', data)

export const assignedRequest = (data: any) =>
  RestAPI.GET(`/assigned-requests?page=${data.page}&pageSize=${data.pageSize}`)

export const updatedRequest = (data: any) =>
  RestAPI.PUT(`/service-requests/update?srId=${data.srId}`, data)

export const getStatusSummary = (data: any) =>
  RestAPI.GET(`/idsr-summary?userId=${data.userId}&tID=${data.tID}`)

export const getAttendance = (data: any) =>
  RestAPI.GET(`/attendance?userId=${data.userId}&tID=${data.tId}`)

export const getAttendanceTimesheet = (userId: string) =>
  RestAPI.GET(`/attendance-timesheets?userId=${userId}`)

export const getRCAsList = (userId: string) => RestAPI.GET(`/rca?userId=${userId}`)

export const createRCA = (data: any) => RestAPI.POST(`/rca`, data)

export const getIDSRsList = (data: any) => RestAPI.GET(`/idsr?userId=${data.userId}&tId=${data.tId}`)

export const createIDSR = (data: any) => RestAPI.POST(`/idsr`,data)

export const downloadableURL =(data: any) => RestAPI.GET(`/Downloadable-file?srId=${data.srId}`)

export const getManagerViewData = (data: any) => RestAPI.GET(`/api1/Managerview?userId=${data.userId}&tID=${data.tID}`)

export const getDocURL = (data: any) => RestAPI.PATCH(`/api1/url?userId=${data.userId}&url=${data.url}`,data)
