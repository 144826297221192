import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { StyledTableCell, StyledTableRow } from '../Common/CommonStyles'
import { Box, CircularProgress, Dialog } from '@mui/material'

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Divider,
} from '@mui/material'

import { useState } from 'react'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { fetchBasicInfo, fetchCountry, fetchMaritialStatus, fetchState } from '../../actions'
import { dashboardEntity, dashboardUI, financeEntity } from '../../reducers'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import { useLocation } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import moment from 'moment'

const Maincontainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}
const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  color: 'black',
  padding: '20px',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: ' 85%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    cursor: 'not-allowed',
    padding: '11.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '14px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
  },
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    cursor: 'not-allowed',
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  fontSize: '13px',
  marginLeft: '10px',
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: '14px',
  },
}))

const Label = styled(FormLabel)({
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  opacity: '1',
  color: 'black',
})

const TextLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: 'Montserrat-Medium',
}))

const EmployeeProfile = (props: any) => {
  const data = props.data
  const UserFullName = data.first_name + ' ' + data.last_name
  const id = 'Emp ID: ' + data.employee_id
  // const emp = 'Employees under' + ' ' + UserFullName

  const UserFirstName = data.first_name
  // const id = 'Emp ID: ' + data.employee_id
  const emp = UserFirstName + "'s DRs"

  const navigate = useNavigate()
  const handleEmployOpen = (userID: number) => {
    navigate(`/home/basic-info-on-search?id=${userID}`)
  }

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{
            float: 'right',
            cursor: 'pointer',
            position: 'relative',
            right: '110px',
            top: '30px',
          }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <StyledPaper elevation={0}>
        <Grid
          container
          sm={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            padding: '20px',
            paddingTop: '0',
          }}
        >
          <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid sm={1.5}>
              <img
                src={data.image_path ? data.image_path : CurrentLoginUserProfile}
                style={{
                  width: '100px',
                  borderRadius: '50%',
                  height: '100px',
                  objectFit: 'cover',
                }}
                alt=''
              />
            </Grid>
            <Grid
              sm={10.5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  fontSize: '20px',
                  textAlign: 'left',
                  marginTop: '-14px',
                  fontFamily: 'Montserrat-SemiBold',
                  letterSpacing: '0px',
                  color: '#193C6D',
                }}
              >
                {UserFullName}
              </Typography>
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  fontSize: '17px',
                  textAlign: 'left',
                  marginTop: '8px',
                  fontFamily: 'Montserrat-Medium',
                  fontWeight: '100',
                  letterSpacing: '0px',
                  color: '#193C6D',
                }}
              >
                {' '}
                Emp ID: {data.employee_id ?? 'NA'}
              </Typography>
            </Grid>
          </Grid>
          <Divider orientation='horizontal' sx={{ marginTop: '10px' }} flexItem></Divider>
          <Grid sm={12} sx={{ padding: '20px', paddingTop: '30px' }}>
            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Email
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.username ?? 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Mobile Number
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.mobile_no ?? 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  marginTop: '18px',
                  maxHeight: '210px',
                  margin: '10px 0px 0px 0px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Manager
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    position: 'relative',
                    right: '54px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    overflow: 'auto',
                    width: '300px',
                    color: '#262e3c',
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#193C6D',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555',
                    },
                  }}
                >
                  <>
                    <Grid
                      sx={{ display: 'flex', alignItems: 'left' }}
                      onClick={(e) => handleEmployOpen(data.managerId)}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Grid>
                          <img
                            src={
                                  data?.managerImageURL
                                ? data?.managerImageURL
                                : CurrentLoginUserProfile
                            }
                            style={{
                              width: '35px',
                              borderRadius: '50%',
                              height: '35px',
                              position: 'relative',
                              bottom: '-5px',
                            }}
                            alt=''
                          />
                        </Grid>
                        <Grid style={{ marginLeft: '20px', marginTop: '-7px' }}>
                          <p style={{ color: '#193C6D', height: '15px', marginBottom: '5px' }}>
                            {data?.managerName}
                          </p>
                          {/* <p style={{ fontSize: '9px', marginTop: '6px' }}>
                                {data?.managerName}
                              </p> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Typography>
              </Grid>
            </Grid>

            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Office Timing
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data?.officeTime ? moment(data?.officeTime, ['HH.mm']).format('hh:mm A') : 'NA'}
                </Typography>
              </Grid>
              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Office Location
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.companyLocationName ?? 'NA'}
                </Typography>
              </Grid>

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Workstation
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {data.workStation ?? 'NA'}
                </Typography>
              </Grid>
            </Grid>

            <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              {data?.DRSEmployees?.DRs?.length > 0 ? (
                <Grid
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'center',
                    marginTop: '18px',
                    maxHeight: '210px',
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h6'
                    sx={{
                      fontSize: '12px',
                      textAlign: 'left',
                      marginLeft: '10px',
                      fontFamily: 'Montserrat-Medium',
                      letterSpacing: '0px',
                      color: '#5e646f',
                    }}
                  >
                    {emp}
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h6'
                    sx={{
                      fontSize: '12px',
                      textAlign: 'left',
                      marginLeft: '10px',
                      fontFamily: 'Montserrat-SemiBold',
                      letterSpacing: '0px',
                      overflow: 'auto',
                      width: '300px',
                      color: '#262e3c',
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#193C6D',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {data.DRSEmployees.DRs.length ? (
                      data.DRSEmployees.DRs.map((item: any, index: any) => (
                        <>
                          <Grid
                            sx={{ display: 'flex', alignItems: 'left' }}
                            onClick={(e) => handleEmployOpen(item.userId)}
                          >
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <Grid>
                                <img
                                  src={item.imagePath ? item.imagePath : CurrentLoginUserProfile}
                                  style={{
                                    width: '35px',
                                    borderRadius: '50%',
                                    height: '35px',
                                  }}
                                  alt=''
                                />
                              </Grid>
                              <Grid style={{ marginLeft: '20px', marginTop: '-7px' }}>
                                <p style={{ height: '15px', marginBottom: '7px' }}>
                                  {item.employeeName}
                                </p>
                                <p style={{ fontSize: '9px', marginTop: '6px' }}>
                                  {item.designation}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p style={{ fontSize: '15px', marginTop: '1px' }}>NA</p>
                    )}
                  </Typography>
                </Grid>
              ) : null}

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Official Birth Date
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {new Date(data.official_birth_date).toLocaleDateString('en-GB') ?? 'NA'}
                </Typography>
              </Grid>

              <Grid
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'center',
                  height: '60px',
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-Medium',
                    letterSpacing: '0px',
                    color: '#5e646f',
                  }}
                >
                  Actual Birth Date
                </Typography>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontFamily: 'Montserrat-SemiBold',
                    letterSpacing: '0px',
                    color: '#262e3c',
                  }}
                >
                  {new Date(data.birth_date).toLocaleDateString('en-GB') ?? 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  )
}

const PersonalInfo = (props: any) => {
  const data = props.data
  const UserFullName = data.first_name + ' ' + data.last_name
  const navigate = useNavigate()
  const [selectedValue, setSelectedValue] = useState('Male')
  const [isDisabled, setIsDisabled] = useState(true)
  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value)
  }

  return (
    <>
      <StyledPaper elevation={0}>
        <>
          <Box sx={{ textAlign: 'left' }}>
            <Box
              onClick={() => navigate(-1)}
              sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
            >
              <ArrowBack />
            </Box>
          </Box>
        </>
        <HeaderHeading sx={{ marginBottom: '20px' }}>Basic Information</HeaderHeading>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }} spacing={3}>
          <Grid item xs={4} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='First Name'
              value={data.first_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              id='outlined-required'
              label='Middle Name'
              placeholder='Middle Name'
              value={data.middle_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Last Name'
              value={data.last_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label="Father's Name"
              value={data.father_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label="Mother's Name"
              value={data.mother_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Manager Name'
              value={data.managerName ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Mobile No'
              defaultValue={data?.mobile_no}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Telephone No'
              defaultValue={data?.telephone_no}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              autoFocus
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <img
              src={data.image_path ? data.image_path : CurrentLoginUserProfile}
              style={{
                width: '154px',
                position: 'absolute',
                right: '260px',
                top: '350px',
                borderRadius: '50%',
                height: '140px',
                objectFit: 'cover',
              }}
              alt=''
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Employee Id'
              value={data.employee_id ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item sm={2}>
            <StyledFormControl>
              <StyledFormLabel id='demo-row-radio-buttons-group-label'>
                Contract Employee
              </StyledFormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <StyledFormControlLabel
                  value='2'
                  control={<Radio />}
                  label='Yes'
                  checked={data.is_contract_employee === 1}
                  disabled
                />
                <StyledFormControlLabel
                  value='1'
                  control={<Radio />}
                  label='No'
                  checked={data.is_contract_employee === 0}
                  disabled
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledFormControl>
              <StyledFormLabel id='demo-row-radio-buttons-group-label'>Gender</StyledFormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <StyledFormControlLabel
                  value='Male'
                  control={<Radio />}
                  label='Male'
                  checked={data.gender === '1'}
                  onChange={handleRadioChange}
                  disabled={isDisabled}
                />
                <StyledFormControlLabel
                  value='Female'
                  control={<Radio />}
                  label='Female'
                  checked={data.gender === '2'}
                  onChange={handleRadioChange}
                  disabled={isDisabled}
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  )
}

const AtClientLocation = (props: any) => {
  const data = props.data
  return (
    <StyledPaper elevation={0}>
      <LeavesDetailsHeading>CLIENT LOCATIONS</LeavesDetailsHeading>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>LOCATION</StyledTableCell>
              <StyledTableCell>START DATE</StyledTableCell>
              <StyledTableCell>END DATE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.clientLocationHistory.map((location: any) => (
              <StyledTableRow key={location.id}>
                <StyledTableCell component='th' scope='row'>
                  {location.locationName}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {moment(location.start_date).format('DD-MM-YYYY')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {location.end_date ? moment(location.end_date).format('DD-MM-YYYY') : 'N/A'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const ConditionLocation = (props: any) => {
  const data = props.data
  if (!data.client_location && data.companyLocationHistory.length === 0) {
    return null
  }
  return (
    <>
      {data.clientLocationHistory.length > 0 && data.companyLocationHistory.length > 0 ? (
        <>
          <AtClientLocation data={data} />
          <OfficeLocationLogs data={data} />
        </>
      ) : (
        data.companyLocationHistory.length > 0 && <OfficeLocationLogs data={data} />
      )}
    </>
  )
}

const BasicInfo = (props: any) => {
  const {
    BasicInfoData,
    fetchBasicInfoData,
    fetchCountryData,
    fetchMaritalStatusData,
    fetchStateData,
    loaderState,
  } = props

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')

  useEffect(() => {
    if (userId) {
      fetchBasicInfoData({userId, isGlobal: true})
    }
    fetchCountryData()
    fetchMaritalStatusData()
    fetchStateData()
  }, [userId])
  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {Object.keys(BasicInfoData)?.length ? (
        <div style={Maincontainer}>
          <Grid>
            <Grid item xs={12}>
              <EmployeeProfile data={BasicInfoData} />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  )
}

const OfficeLocationLogs = (props: any) => {
  const data = props.data

  return (
    <StyledPaper elevation={0}>
      <LeavesDetailsHeading>COMPANY LOCATIONS</LeavesDetailsHeading>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>LOCATION</StyledTableCell>
              <StyledTableCell>START DATE</StyledTableCell>
              <StyledTableCell>END DATE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.companyLocationHistory.map((location: any) => (
              <StyledTableRow key={location.id}>
                <StyledTableCell component='th' scope='row'>
                  {location.locationName}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {moment(location.start_date).format('DD-MM-YYYY')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {location.end_date ? moment(location.end_date).format('DD-MM-YYYY') : 'N/A'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    StateData: financeEntity.getFinance(state).getStateData,
    CountryData: financeEntity.getFinance(state).getCountryData,
    MaritalStatusData: dashboardEntity.getDashboard(state).getMaritalStatusData,
    loaderState: dashboardUI.getDashboard(state).isBasicInfoData,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBasicInfoData: (data: any) => dispatch(fetchBasicInfo.request(data)),
    fetchStateData: () => dispatch(fetchState.request()),
    fetchCountryData: () => dispatch(fetchCountry.request()),
    fetchMaritalStatusData: () => dispatch(fetchMaritialStatus.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
