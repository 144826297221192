import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
  Typography,
  styled,
  tableCellClasses,
  TableFooter,
  Grid,
  CircularProgress,
  Dialog,
} from '@mui/material'
import { Primary } from '../../utils'
import { useState } from 'react'
import React from 'react'
import html2pdf from 'html2pdf.js'
import PdfIcon from '../../assets/images/pdf_image.png'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { getPaySlip, getTimesheet } from '../../actions'
import { financeEntity, financeUI } from '../../reducers'
import moment from 'moment'
import { loaderProps } from '../Common/CommonStyles'
const CardStyleKey = {
  width: '230px',
}

const CardStyleValue = {
  width: '200px',
}

const CardStyleKeyNew = {
  width: '200px',
}

const cardStyles = {
  border: 'none',
  // overflow:'none'
}
const NotEligibleEmployee = styled(Paper)(({ theme }) => ({
  width: '70%',
  padding: '30px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const EligibleEmployee = styled(Paper)(({ theme }) => ({
  width: '80%',
  padding: '30px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Montserrat-Medium',
  textAlign: 'center',
  letterSpacing: '0px',
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: 'Montserrat-Medium',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#6c6b6b',
}))

const CommonStyle = { display: 'flex', justifyContent: 'center', margin: '10px 0' }
const ExportButton = { display: 'flex', justifyContent: 'flex-end' }

const StyledSelect = styled(Select)(({ theme }) => ({
  // width: '23%',
  borderRadius: '20px',
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  height: '38px',
  fontFamily: 'Montserrat-SemiBold',
  width: '120px',
  textAlign: 'center',
  borderRadius: '20px',
}))

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
  textAlign: 'center',
  letterSpacing: '0px',
  lineHeight: 1.8,
  marginTop: '15px',
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const MainContainer = {
  background: 'rgb(231, 235, 240)',
  width: '100%',
  // height: '100%',
  // paddingBottom: '20px',
}

const TextStyle = {
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  marginBottom: '5px',
}
const SelectField = styled(Select)(({ theme }) => ({
  width: '240px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
}))

const TextStylenew = {
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  height:0,
}
const TextTotal = {
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  marginTop: '15px',
}

const MailDrop = {
  color: '#C11828',
}

const handleExportPDF = (emname: string, month: string, year: number) => {
  const element = document.getElementById('pdf-export')
  const PaySlipname = `${emname}_${month}_${year}`

  if (element) {
    const options = {
      margin: 10,
      filename: PaySlipname,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    }

    html2pdf().set(options).from(element).save()
  }
}

const PaySlips = (props: any) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const [payslipMonth, setPayslipMonth] = useState('')
  const [paySlipYear, setPaySlipYear] = useState(0)

  // const IndexMonth = [0:"JAN", 0:"]
  const { fetchTimesheet, timesheetData, isPayslipRequest, fetchPaySlips, payslipData, loaderStateoftimeSheet } =
    props

  const [selectedPaySlipDateOption, setPaySlipDateOption] = useState('')

  const handlePaySlipDateChange = (event: any, timesheets: any[]) => {
    const selectedId = event.target.value
    const selectedTimesheet = timesheets.find((timesheet) => timesheet.id === selectedId)
    if (selectedTimesheet) {
      let dateIndex: number = new Date(selectedTimesheet.start_date).getMonth()
      setPayslipMonth(month[dateIndex])
      setPaySlipYear(new Date(selectedTimesheet.start_date).getFullYear())
      setPaySlipDateOption(selectedTimesheet.id)
      fetchPaySlips(selectedTimesheet.id)
    }
  }

  const [age, setAge] = React.useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value)
  }

  React.useEffect(() => {
    fetchTimesheet()
  }, [])

  React.useEffect(() => {
    if (loaderStateoftimeSheet && timesheetData.length) {
      setPaySlipDateOption(timesheetData[0].id)
      let dateIndex: number = new Date(timesheetData[0].start_date).getMonth()
      setPayslipMonth(month[dateIndex])
      setPaySlipYear(new Date(timesheetData[0].start_date).getFullYear())
      fetchPaySlips(timesheetData[0].id)
    }
  }, [loaderStateoftimeSheet])

  const Payslipdata: any = payslipData.employeeDetails
  return (
    <div  style={{
      ...MainContainer,
      paddingBottom: loaderStateoftimeSheet && !timesheetData.length ? '250px' : '20px',
    }}>
      <Dialog open={isPayslipRequest} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      {loaderStateoftimeSheet && !timesheetData.length ? 
      <NotEligibleEmployee elevation={3}>
        <Heading>Tudip Technologies Pvt. Ltd. Salary Slip Portal</Heading>

        <Paragraph>
          You are not able to access the salary slips because you are under the probation period.
          For more information please drop mail to hr@tudip.com.
        </Paragraph>
      </NotEligibleEmployee>
      : null}
      {loaderStateoftimeSheet && timesheetData.length ?
      <EligibleEmployee>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <StyledFormControl>
              <InputLabel id='demo-simple-select-readonly-label'>Select Payslip</InputLabel>
              <SelectField
                variant='outlined'
                value={selectedPaySlipDateOption}
                label='Select Payslip:'
                onChange={(event) => handlePaySlipDateChange(event, timesheetData)}
              >
                {timesheetData?.map((option: any, index: number) => (
                  <StyledMenuItem key={index} value={option?.id}>
                    ({moment(option?.start_date).format('DD/MM/YYYY')}) to (
                    {moment(option?.end_date).format('DD/MM/YYYY')})
                  </StyledMenuItem>
                ))}
              </SelectField>
            </StyledFormControl>
          </div>

          <div style={ExportButton}>
            <Button
              variant='outlined'
              sx={{
                fontSize: '14px',
                height: '42px',
                float: 'right',
                borderRadius: '20px',
              }}
              startIcon={
                <Avatar sx={{ width: 24, height: 24 }}>
                  <img src={PdfIcon} alt='PDF' />
                </Avatar>
              }
              onClick={(e) =>
                handleExportPDF(payslipData?.employeeDetails?.full_name, payslipMonth, paySlipYear)
              }
            >
              EXPORT AS PDF
            </Button>
          </div>
        </Grid>

        <div id='pdf-export'>
          <Heading>Tudip Technologies Pvt. Ltd., Pune</Heading>
          <SubHeading>
            Salary Slip for the Month of {payslipMonth} {paySlipYear}
          </SubHeading>

          {/* <div style={CommonStyle}>
          <ActionButton>PROCESS</ActionButton>
        </div> */}

          {/* <div style={ExportButton}>
          <Button
            variant='outlined'
            sx={{
              fontSize: '14px',
              height: '42px',
              float: 'right',
              borderRadius: '20px',
            }}
            startIcon={
              <Avatar sx={{ width: 24, height: 24 }}>
                <img src={PdfIcon} alt='PDF' />
              </Avatar>
            }
            onClick={handleExportPDF}
          >
            EXPORT AS PDF
          </Button>
        </div> */}

          {/* {payslipData?.employeeDetails?.full_name} */}

          <TableContainer
            component={Paper}
            sx={{ border: '1px solid #DDDDDD', width: '99.8%', mt: '40px' }}
          >
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography sx = {{paddingTop:'5px'}} style={TextStyle}>
                      Name: {payslipData?.employeeDetails?.full_name}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TableRow>
                  <Card sx={cardStyles} variant='outlined'>
                    <CardContent sx={CardStyleKeyNew}>
                      <Typography style={TextStyle}>Employee No:</Typography>

                      <Typography style={TextStyle}>Designation:</Typography>

                      <Typography style={TextStyle}>PF No:</Typography>

                      <Typography style={TextStyle}>UAN No:</Typography>
                    </CardContent>
                  </Card>
                </TableRow>
                {/* i want verttcal devider */}

                <TableRow>
                  <Card
                    sx={cardStyles}
                    variant='outlined'
                    style={{ textAlign: 'end', position: 'relative', left: '15px' }}
                  >
                    <CardContent sx={CardStyleValue}>
                      <Typography style={TextStyle}>
                        <span>
                          {payslipData?.employeeDetails?.employee_id
                            ? payslipData?.employeeDetails?.employee_id
                            : 'NA'}
                        </span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span>
                          {' '}
                          {payslipData?.employeeDetails?.job_level
                            ? payslipData?.employeeDetails?.job_level
                            : 'NA'}
                        </span>
                      </Typography>

                      <Typography sx={{ fontSize: '13px', marginTop: '4px' }}>
                        <span>
                          {' '}
                          {payslipData?.employeeDetails?.pf_no
                            ? payslipData?.employeeDetails?.pf_no
                            : 'NA'}
                        </span>
                      </Typography>

                      <Typography sx={{ fontSize: '13px', marginTop: '6px' }}>
                        <span>
                          {payslipData?.employeeDetails?.uan_no
                            ? payslipData?.employeeDetails?.uan_no
                            : 'NA'}
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>
                <Divider orientation='vertical' flexItem />
                <TableRow>
                  <Card
                    sx={cardStyles}
                    style={{ position: 'relative', right: '20px' }}
                    variant='outlined'
                  >
                    <CardContent sx={CardStyleKeyNew}>
                      <Typography style={TextStyle}>Bank Name:</Typography>

                      <Typography style={TextStyle}>Bank Account No:</Typography>

                      <Typography style={TextStyle}>Bank Branch:</Typography>

                      <Typography style={TextStyle}>PAN Card No:</Typography>

                      <Typography style={TextStyle}>Aadhaar No:</Typography>
                    </CardContent>
                  </Card>
                </TableRow>

                <TableRow>
                  <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'end' }}>
                    <CardContent sx={CardStyleValue}>
                      <Typography style={TextStyle}>
                        <span>{payslipData?.employeeDetails?.bank_name || 'NA'}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span> {payslipData?.employeeDetails?.bank_account_no || 'NA'}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span> {payslipData?.employeeDetails?.bank_branch || 'NA'}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span>{payslipData?.employeeDetails?.pan_card_no || 'NA'}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span>{payslipData?.employeeDetails?.aadhaarCard || 'NA'}</span>
                      </Typography>
                    </CardContent>
                  </Card>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{ border: '1px solid #DDDDDD', marginTop: '20px', width: '99.8%' }}
          >
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
              <TableHead>
                {/* <TableRow>
                  <TableCell colSpan={4}><Typography style={TextStyle}>Earnings and Reimbursements</Typography></TableCell>
                </TableRow>
                <Divider orientation="vertical" flexItem /> 

                <TableRow>
                  <TableCell colSpan={4}><Typography style={TextStyle}>Earnings and Reimbursements</Typography></TableCell>
                </TableRow> */}

                <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TableRow>
                    <Card sx={cardStyles} variant='outlined'>
                      <CardContent sx={CardStyleKeyNew}>
                        <Typography
                          style={TextStylenew}
                          sx={{ width: '220px', paddingTop: '0px', paddingBottom: '0px' }}
                        >
                          Earnings and Reimbursements
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>

                  <TableRow>
                    <Card
                      sx={cardStyles}
                      variant='outlined'
                      style={{ textAlign: 'right', position: 'relative', left: '15px' }}
                    >
                      <CardContent sx={CardStyleValue}>
                        <Typography
                          style={TextStylenew}
                          sx={{ paddingTop: '0px', paddingBottom: '0px', marginLeft: '77px' }}
                        >
                          <span>In Rs</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>

                  <Divider orientation='vertical' flexItem />
                  <TableRow>
                    <Card
                      sx={cardStyles}
                      style={{ position: 'relative', right: '15px' }}
                      variant='outlined'
                    >
                      <CardContent sx={CardStyleKeyNew}>
                        <Typography
                          style={TextStylenew}
                          sx={{ paddingTop: 'px', paddingBottom: '0px', marginLeft: '-7px' }}
                        >
                          Deductions and Recoveries
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>

                  <TableRow>
                    <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'end' }}>
                      <CardContent sx={CardStyleValue}>
                        <Typography
                          style={TextStylenew}
                          sx={{
                            paddingTop: '0px',
                            paddingBottom: '7px',
                            marginLeft: '77px',
                            marginRight: '0px',
                            paddingRight: '0px',
                          }}
                        >
                          <span>In Rs</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </TableRow>
                </TableBody>
              </TableHead>
              <Divider orientation='horizontal' />
              <TableBody sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <TableRow>
                  <Card sx={cardStyles} variant='outlined'>
                    <CardContent sx={CardStyleKey}>
                      {payslipData?.allowances?.enrichedAllowances?.map((item: any, index: any) => (
                        <>
                          <Typography key={index} style={TextStyle}>
                            {item?.name}
                          </Typography>
                        </>
                      ))}

                      {/* <Typography style={TextStyle}>
                        House Rent Allowance:
                      </Typography>

                      <Typography style={TextStyle}>
                        Conveyance Allowance:
                      </Typography>

                      <Typography style={TextStyle}>
                        Medical Allowance:
                      </Typography>

                      <Typography style={TextStyle}>
                        Internet & Phone Allowance:
                      </Typography>

                      <Typography style={TextStyle}>
                        Customer Commitment Bonus:
                      </Typography>

                      <Typography style={TextStyle}>
                        Leave Travel Allowance:
                      </Typography>

                      <Typography style={TextStyle}>
                        Special Allowance:
                      </Typography> */}

                      {/* <Divider orientation="horizontal" flexItem />  */}
                    </CardContent>
                  </Card>
                </TableRow>

                <TableRow>
                  <Card sx={cardStyles} variant='outlined'>
                    <CardContent sx={CardStyleValue} style={{ textAlign: 'right' }}>
                      {payslipData?.allowances?.enrichedAllowances?.map((item: any, index: any) => (
                        <>
                          <Typography style={TextStyle}>
                            <span style={{ marginLeft: '61px' }}>{item?.pay_period_amount}</span>
                          </Typography>
                        </>
                      ))}

                      {/* <Typography style={TextStyle} >
                        <span style={{marginLeft:"61px"}}>{payslipData?.allowances?.allowances[1].pay_period_amount}</span>
                      </Typography> */}

                      {/* <Typography style={TextStyle}>
                        <span style={{marginLeft:"61px"}}> {payslipData?.allowances?.allowances[2].pay_period_amount}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"61px"}}>{payslipData?.allowances?.allowances[3].pay_period_amount} </span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"61px"}}>{payslipData?.allowances?.allowances[4].pay_period_amount} </span>
                      </Typography>

                      <Typography style={TextStyle} >
                        <span style={{marginLeft:"61px"}}> {payslipData?.allowances?.allowances[5].pay_period_amount}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"61px"}}> {payslipData?.allowances?.allowances[6].pay_period_amount} </span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"61px"}}>{payslipData?.allowances?.allowances[7].pay_period_amount}</span>
                      </Typography> */}
                      {/* <Divider orientation="horizontal" flexItem />  */}
                      {/* <Typography style={TextTotal}>
                        <span> 100000 </span>
                      </Typography> */}
                    </CardContent>
                  </Card>
                </TableRow>

                <Divider orientation='vertical' sx={{ marginRight: '31px' }} flexItem />
                <TableRow>
                  <Card
                    sx={{ border: 'none', position: 'relative', right: '20px' }}
                    variant='outlined'
                  >
                    <CardContent sx={CardStyleKeyNew}>
                      {/* 
                      <Typography sx={{marginLeft:"-18px"}} style={TextStyle}>
                        Provident Fund:
                      </Typography>

                      <Typography sx={{marginLeft:"-18px", marginRight:"10px", zIndex:"100" }}  style={TextStyle}>
                        Professional Tax:
                      </Typography>
                      <Typography sx={{marginLeft:"-18px"}} style={TextStyle}>
                        LWF:
                      </Typography> */}

                      {payslipData?.deductions?.enrichedDeductions?.map((item: any, index: any) => (
                        <>
                          <Typography key={index} sx={{ marginLeft: '-16px' }} style={TextStyle}>
                            <span>{item?.name}</span>
                          </Typography>
                        </>
                      ))}
                    </CardContent>
                  </Card>
                </TableRow>

                <TableRow>
                  <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'right' }}>
                    <CardContent sx={CardStyleValue}>
                      {/* <Typography style={TextStyle}>
                        <span style={{marginLeft:"80px"}}>{payslipData?.deductions?.enrichedDeductions[0].pay_period_amount}</span>
                      </Typography>

                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"80px"}}>{payslipData?.deductions?.enrichedDeductions[1].pay_period_amount}</span>
                      </Typography>
                      
                      <Typography style={TextStyle}>
                        <span style={{marginLeft:"80px"}}>{payslipData?.deductions?.enrichedDeductions[2].pay_period_amount}</span>
                      </Typography> */}

                      {payslipData?.deductions?.enrichedDeductions?.map((item: any, index: any) => (
                        <>
                          <Typography key={index} style={TextStyle}>
                            <span style={{ marginLeft: '80px' }}>{item?.pay_period_amount}</span>
                          </Typography>
                        </>
                      ))}
                    </CardContent>
                  </Card>
                </TableRow>
              </TableBody>
            </Table>
            <Divider orientation='horizontal' flexItem />
            <TableBody sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TableRow>
                <Card sx={cardStyles} variant='outlined'>
                  <CardContent sx={CardStyleKeyNew}>
                    <Typography style={TextStyle} sx={{ color: '#000' }}>
                      Total Earnings:
                    </Typography>
                  </CardContent>
                </Card>
              </TableRow>

              <TableRow>
                <Card
                  sx={cardStyles}
                  variant='outlined'
                  style={{ position: 'relative', left: '20px', textAlign: 'end' }}
                >
                  <CardContent sx={CardStyleValue}>
                    <Typography style={TextStyle}>
                      <span style={{ marginLeft: '73px' }}>
                        {payslipData?.allowances?.totalAllowances}
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </TableRow>
              <Divider orientation='vertical' flexItem />
              <TableRow>
                <Card
                  sx={cardStyles}
                  style={{ position: 'relative', right: '17px' }}
                  variant='outlined'
                >
                  <CardContent sx={CardStyleKeyNew}>
                    <Typography sx={{ marginLeft: '-7px', color: '#000' }} style={TextStyle}>
                      Total Deduction:
                    </Typography>
                    {/* <Divider orientation="horizontal" flexItem />  */}

                    <Typography sx={{ marginLeft: '-7px', color: '#000' }} style={TextStyle}>
                      Net Pay:
                    </Typography>
                  </CardContent>
                </Card>
              </TableRow>

              <TableRow>
                <Card sx={cardStyles} variant='outlined' style={{ textAlign: 'right' }}>
                  <CardContent sx={CardStyleValue}>
                    <Typography style={TextStyle}>
                      <span style={{ marginLeft: '77px' }}>
                        {payslipData?.deductions?.totalDeduction}
                      </span>
                    </Typography>
                    {/* <Divider orientation="horizontal" flexItem />  */}

                    <Typography style={TextStyle}>
                      <span style={{ marginLeft: '77px' }}>
                        {payslipData?.net_pay ? payslipData?.net_pay : 'NA'}
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </TableRow>
            </TableBody>
            <Divider orientation='horizontal' flexItem />
          </TableContainer>
        </div>
      </EligibleEmployee>
      : null
      }
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    timesheetData: financeEntity.getFinance(state).getTimesheetData,
    payslipData: financeEntity.getFinance(state).getPaySlipData,
    loaderStateoftimeSheet: financeUI.getFinance(state).isTimesheetData,
    isPayslipRequest: financeUI.getFinance(state).isPayslipData
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTimesheet: () => dispatch(getTimesheet.request()),
    fetchPaySlips: (data: any) => dispatch(getPaySlip.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaySlips)
