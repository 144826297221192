import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'

// Styled Components
// const MainContainer = styled(Stack)({
//   boxSizing: 'border-box',
//   height: '100%',
//   width: '100%',
// })
// const LeftContainer = styled('img')({
//   width: '55%',
//   height: '100%',
// })
// const RightContainer = styled(Stack)({
//   padding: '0 10%',
//   boxSizing: 'border-box',
//   flexDirection: 'column',
//   width: '45%',
//   height: '100%',
//   position: 'relative',
//   justifyContent: 'center',
// })
// const Logo = styled('img')({
//   width: '210px',
//   height: '75px',
//   marginBottom: '55px',
// })

// const EsgLogo = styled('img')({
//   width: '55px',
//   marginRight: '10px',
// })

// const Heading = styled(Typography)({
//   marginBottom: '11px',
//   fontWeight: 'bold',
//   fontSize: '33px',
// })

// const RememberMeOuterDiv = styled(Stack)({
//   width: '100%',
//   justifyContent: 'space-between',
//   marginBottom: '25px',
// })
// const LangDrop = styled(Stack)({
//   position: 'absolute',
//   top: '25px',
//   right: '30px',
//   width: '15%',
//   height: 'auto',
// })
// Styled Components

const MainContainer = styled(Stack)({
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
})
const LeftContainer = styled(Stack)({
  width: '55%',
  height: '100%',
  // padding: '5% 0 0 0'
  // backgroundImage: 'linear-gradient(120deg, #d1359a 0%, #83414a)',
})

const CenterContainer = styled('img')({
  width: '85%',
  height: '85%',
  // backgroundImage: 'linear-gradient(120deg, #d1359a 0%, #83414a)',
  borderRadius: ' 20px',
  transform: 'translate( 10%)',
})

const RightContainer = styled(Stack)({
  padding: '0 10%',
  boxSizing: 'border-box',
  flexDirection: 'column',
  width: '45%',
  height: '100%',
  position: 'relative',
  justifyContent: 'space-between',
})
const Logo = styled('img')({
  width: '210px',
  height: '75px',
  marginBottom: '55px',
})

const EsgLogo = styled('img')({
  width: '55px',
  marginRight: '10px',
})
const Heading = styled(Typography)({
  marginBottom: '11px',
  fontWeight: 'bold',
  fontSize: '33px',
  fontFamily: 'Montserrat-Medium',
})

const RememberMeOuterDiv = styled(Stack)({
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '25px',
})
const LangDrop = styled(Stack)({
  position: 'absolute',
  top: '25px',
  right: '30px',
  width: '15%',
  height: 'auto',
})

const ForgotPasswordContainer = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '40px',
})

const ForgotPasswordContent = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Montserrat-Medium',
})

const FPLogo = styled('img')({
  width: '210px',
  height: '75px',
  marginBottom: '15px',
})
export {
  MainContainer,
  RightContainer,
  LangDrop,
  Logo,
  LeftContainer,
  Heading,
  RememberMeOuterDiv,
  EsgLogo,
  ForgotPasswordContent,
  ForgotPasswordContainer,
  CenterContainer,
  FPLogo,
}
