import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { ADD_NEW_COMMENT, FAILURE, FETCH_DOWNLOADABLE_URL, FETCH_UPDATED_SERVICE_REQUEST } from '../../actions/actiontypes'

const {
  SUCCESS,
  REQUEST,
  FETCH_ISSUE_TYPE_LIST,
  CREATE_SERVICE_REQUEST,
  FETCH_ASSIGNED_REQUEST,
  LOGIN_USER,
} = actionTypes

const entity = () => {
  const createServiceRequest = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_SERVICE_REQUEST[SUCCESS]:
        return action.payload
      case CREATE_SERVICE_REQUEST[REQUEST]:
        return []
      default:
        return state
    }
  }
  const getIssueTypeList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ISSUE_TYPE_LIST[SUCCESS]:
        return action.payload
      case FETCH_ISSUE_TYPE_LIST[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getComments = (state = {}, action: actions) => {
    switch (action.type) {
      case ADD_NEW_COMMENT[SUCCESS]:
        return action.payload
      case ADD_NEW_COMMENT[REQUEST]:
        return {}
      case ADD_NEW_COMMENT[FAILURE]:
        return action.payload
      default:
        return state
    }
  }
  const getAssignedRequest = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ASSIGNED_REQUEST[SUCCESS]:
        return {
          ...state,
          data: action.payload,
        }
        case FETCH_ISSUE_TYPE_LIST[REQUEST]:
        return []
      default:
        return state
    }
  }
  const getUpdatedServiceRequest = (state = [], action: actions) =>{
    switch (action.type) {
      case FETCH_UPDATED_SERVICE_REQUEST[SUCCESS]:
        return {
          ...state,
          data: action.payload,
        }
        case FETCH_UPDATED_SERVICE_REQUEST[REQUEST]:
          return []
        default:
          return state
    }
  }

  const getDownloadableURL = (state = [], action: actions) =>{
    switch (action.type) {
      case FETCH_DOWNLOADABLE_URL[SUCCESS]:        
        return {
          ...state,
          data: action.payload,
        }
        case FETCH_DOWNLOADABLE_URL[REQUEST]:
          return []
        default:
          return state
    }
  }

  return combineReducers({
    createServiceRequest,
    getIssueTypeList,
    getComments,
    getAssignedRequest,
    getUpdatedServiceRequest,
    getDownloadableURL,
  })
}

export default entity

export const createServiceRequestData = (state: RootState) => state.entities.ServiceRequest
export const getAssignedRequestData = (state: RootState) => state.entities.ServiceRequest
export const createUpdatedServiceRequestData = (state: RootState) => state.entities.ServiceRequest
export const getDownloadableURL = (state: RootState) => state.entities.ServiceRequest

