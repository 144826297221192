import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { GET_USER_DATA } from '../../actions/actiontypes'

const { REQUEST } = actionTypes

const ui = () => {
  const isLoaderTrue = (state = false, action: actions) => {
    switch (action.type) {
      case GET_USER_DATA[REQUEST]:
        return true
      default:
        return false
    }
  }

  return combineReducers({
    isLoaderTrue,
  })
}

export default ui

export const getLoaderState = (state: RootState) => state.ui.loaderState
