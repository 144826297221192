import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { REQUEST, SUCCESS } from '../../actions/actiontypes'

const { FETCH_ATTENDANCE, FETCH_ATTENDANCE_TIMESHEET } = actionTypes

const entity = () => {
  const getEmployeeAttendance = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getAttendanceTimesheet = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE_TIMESHEET[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE_TIMESHEET[REQUEST]:
        return []

      default:
        return state
    }
  }

  return combineReducers({
    getEmployeeAttendance,
    getAttendanceTimesheet,
  })
}

export default entity

export const getAllAttendance = (state: RootState) => state.entities.EmployeeAttendanceRequest
