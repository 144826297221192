import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import React, { useState } from 'react'
import { Primary } from '../../utils'
import { NumericFormat } from 'react-number-format'

type CommonFileUploadProps = {
  id: string
  onChange: (id: string, file: File | null) => void
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Montserrat-Medium',
  textAlign: 'center',
  letterSpacing: '0px',
  lineHeight: '1.5',
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: 'Montserrat-Medium',
  textAlign: 'center',
  letterSpacing: '0px',
  lineHeight: '3.0',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
  },
}))

const ParticularTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    fontSize: '13px',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    color: '#483f3f',
    letterSpacing: '0px',
  },
}))

const RemarksTableCell = styled(TableCell)(({ theme }) => ({
  width: '25%',
  background: '#f2f2f2f2',

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2f2',
    color: '#787070',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    color: '#483f3f',
    textAlign: 'center',
    letterSpacing: '0px',
  },
}))

const AmountTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0',
  paddingLeft: '10px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2f2',
    color: '#787070',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    color: '#483f3f',
    textAlign: 'center',
    letterSpacing: '0px',
  },
}))

const UploadTableCell = styled(TableCell)(({ theme }) => ({
  width: '20%',
  padding: ' 0',
  paddingTop: '12px',

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2f2',
    color: '#787070',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    color: '#483f3f',
    textAlign: 'center',
    letterSpacing: '0px',
  },
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

export interface IUserInputProps {
  initial: number
  value: number
  onChange: any
  name: string
}

const UserInput = ({ initial, value, onChange, name }: IUserInputProps) => {
  return (
    <td>
      <NumericFormat
        customInput={InputField}
        allowNegative={false}
        defaultValue={0}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onChange={(e) => onChange(e, name)}
      />
      {/* <InputField  /> */}
    </td>
  )
}

const CommonFileUpload: React.FC<CommonFileUploadProps> = ({ id, onChange }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    onChange(id, file) // Pass the id and file to the onChange callback
  }

  return (
    <>
      <input type='file' id={id} style={{ display: 'none' }} onChange={handleFileChange} />
      <label htmlFor={id}>
        <Button
          sx={{
            fontSize: '15px',
            padding: '6px 22px',
            borderRadius: '24px',
            fontFamily: 'Montserrat-Medium',
          }}
          variant='contained'
          component='span'
        >
          Upload File
        </Button>
      </label>
      <Typography
        sx={{ fontSize: '13px', fontFamily: 'Montserrat-Medium' }}
        variant='body1'
        color='textSecondary'
      >
        {selectedFile ? selectedFile.name : 'No file selected'}
      </Typography>
    </>
  )
}

const FirstFormSection = () => {
  const [AprilfileUpload, setAprilFile] = useState(null)
  const [MayfileUpload, setMayFile] = useState(null)
  const [JunefileUpload, setJuneFile] = useState(null)
  const [JulyfileUpload, setJulyFile] = useState(null)
  const [AugustfileUpload, setAugustFile] = useState(null)
  const [SeptemberfileUpload, setSeptemberFile] = useState(null)
  const [OctoberfileUpload, setOctoberFile] = useState(null)
  const [NovemberfileUpload, setNovemberFile] = useState(null)
  const [DecemberfileUpload, setDecemberFile] = useState(null)
  const [JanuaryfileUpload, setJanuaryFile] = useState(null)
  const [FebruaryfileUpload, setFebruaryFile] = useState(null)
  const [MarchfileUpload, setMarchFile] = useState(null)

  const handleFileChange = (id: any, file: any) => {
    if (id === 'AprilfileUpload') {
      setAprilFile(file)
    } else if (id === 'MayfileUpload') {
      setMayFile(file)
    } else if (id === 'JunefileUpload') {
      setJuneFile(file)
    } else if (id === 'JulyfileUpload') {
      setJulyFile(file)
    } else if (id === 'AugustfileUpload') {
      setAugustFile(file)
    } else if (id === 'SeptemberfileUpload') {
      setSeptemberFile(file)
    } else if (id === 'OctoberfileUpload') {
      setOctoberFile(file)
    } else if (id === 'NovemberfileUpload') {
      setNovemberFile(file)
    } else if (id === 'DecemberfileUpload') {
      setDecemberFile(file)
    } else if (id === 'JanuaryfileUpload') {
      setJanuaryFile(file)
    } else if (id === 'FebruaryfileUpload') {
      setFebruaryFile(file)
    } else if (id === 'MarchfileUpload') {
      setMarchFile(file)
    }
  }

  const [total1, setTotal1] = useState<number>(0)

  interface IMonths {
    april: number
    may: number
    june: number
    july: number
    august: number
    september: number
    october: number
    november: number
    december: number
    january: number
    february: number
    march: number
  }

  const [months, setMonths] = useState({
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
    january: 0,
    february: 0,
    march: 0,
  })

  const handleChange1 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updatedMonth = { ...months, [name]: Number(e.target.value) }
    setMonths(updatedMonth)
    const total = Object.values(updatedMonth).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal1(roundedTotal)
  }

  return (
    <>
      <TableRow>
        <StyledTableCell>1</StyledTableCell>
        <ParticularTableCell>Rent Paid / Payable for:</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>April</ParticularTableCell>
        <RemarksTableCell rowSpan={12}>
          Please enter your Monthly Rent details as per the Rent Receipt / Lease Deed.
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='april'
            value={months['april']}
            onChange={handleChange1}
            key='april'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='AprilfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>May</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='may'
            value={months['may']}
            onChange={handleChange1}
            key='may'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='MayfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>June</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='june'
            value={months['june']}
            onChange={handleChange1}
            key='june'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='JunefileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>July</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='july'
            value={months['july']}
            onChange={handleChange1}
            key='july'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='JulyfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>August</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='august'
            value={months['august']}
            onChange={handleChange1}
            key='august'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='AugustfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>September</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='september'
            value={months['september']}
            onChange={handleChange1}
            key='september'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='SeptemberfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>October</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='october'
            value={months['october']}
            onChange={handleChange1}
            key='october'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='OctoberfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>November</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='november'
            value={months['november']}
            onChange={handleChange1}
            key='november'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='NovemberfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>December</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='december'
            value={months['december']}
            onChange={handleChange1}
            key='december'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='DecemberfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>January</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='january'
            value={months['january']}
            onChange={handleChange1}
            key='january'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='JanuaryfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>February</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='february'
            value={months['february']}
            onChange={handleChange1}
            key='february'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='FebruaryfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>March</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='march'
            value={months['march']}
            onChange={handleChange1}
            key='march'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='MarchfileUpload' onChange={handleFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total1} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}
const SecondFormSection = () => {
  const SecondFormFileChange = (id: any, file: any) => {}
  const [total2, setTotal2] = useState<number>(0)
  const [loan, setLoan] = useState({
    input1: 0,
    input2: 0,
    input3: 0,
  })

  const handleChange2 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updatedLoan = { ...loan, [name]: Number(e.target.value) }
    setLoan(updatedLoan)
    const total = Object.values(updatedLoan).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal2(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>2 </StyledTableCell>
        <ParticularTableCell>Interest on Housing Loan</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          If the house is self-occupied and the loan was taken before April 1999. Up to Rs. 30000/-
          (If Self Occupied)
        </ParticularTableCell>
        <RemarksTableCell>
          Provisional Certificate issued by the Banker showing the Interest Part
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={loan['input1']}
            onChange={handleChange2}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='HouseLoanColumn1' onChange={SecondFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          If the house is self-occupied and the loan was taken on or after April 1999. Max Limit -
          Rs. 2,00,000/- (If Self Occupied)
        </ParticularTableCell>
        <RemarksTableCell>
          Provisional Certificate issued by the Banker showing the Interest Part
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input2'
            value={loan['input2']}
            onChange={handleChange2}
            key='input2'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='HouseLoanColumn2' onChange={SecondFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Let out/ Deemed to be let out. [if the property is LET OUT - Rental Income need to be
          specified] - No Limit - (If Let Out)
        </ParticularTableCell>
        <RemarksTableCell>
          Rental Income for the Period of current financial year / Municipal Taxes PAID by the owner
          (employee) during the financial year
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input3'
            value={loan['input3']}
            onChange={handleChange2}
            key='input3'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='HouseLoanColumn3' onChange={SecondFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total2} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}

const ThirdFormSection = () => {
  const ThirdFormFileChange = (id: any, file: any) => {}
  const [total3, setTotal3] = useState<number>(0)
  const [_80C, set80C] = useState({
    input1: 0,
    input2: 0,
    input3: 0,
    input4: 0,
    input5: 0,
    input6: 0,
    input7: 0,
    input8: 0,
    input9: 0,
    input10: 0,
    input11: 0,
    input12: 0,
    input13: 0,
    input14: 0,
  })

  const handleChange3 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80C = { ..._80C, [name]: Number(e.target.value) }
    set80C(updated80C)
    const total = Object.values(updated80C).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal3(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>3 </StyledTableCell>
        <ParticularTableCell>80C & 80CCC Categories</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Life Insurance Premium</ParticularTableCell>
        <StyledTableCell sx={{ width: '25%', background: '#f2f2f2f2' }}>
          Self, Spouse & Children only
        </StyledTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80C['input1']}
            onChange={handleChange3}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn1' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Public Provident Fund (PPF)</ParticularTableCell>
        <RemarksTableCell>Self, Spouse & Children only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input2'
            value={_80C['input2']}
            onChange={handleChange3}
            key='input2'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn2' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Voluntary Provident Fund (VPF)</ParticularTableCell>
        <RemarksTableCell>will be additionally deducted from salary</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input3'
            value={_80C['input3']}
            onChange={handleChange3}
            key='input3'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn3' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Pension Fund Contribution</ParticularTableCell>
        <RemarksTableCell>Self only </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input4'
            value={_80C['input4']}
            onChange={handleChange3}
            key='input4'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn4' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>National Savings Certificate (NSC)</ParticularTableCell>
        <RemarksTableCell>Self only </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input5'
            value={_80C['input5']}
            onChange={handleChange3}
            key='input5'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn5' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Interest Accrued on NSC (Reinvested)</ParticularTableCell>
        <RemarksTableCell>Self only </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input6'
            value={_80C['input6']}
            onChange={handleChange3}
            key='input6'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn6' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Unit Linked Insurance Policy (ULIP)</ParticularTableCell>
        <RemarksTableCell>Self, Spouse & Children only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input7'
            value={_80C['input7']}
            onChange={handleChange3}
            key='input7'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn7' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Equity Linked Savings Schemes (ELSS) - Mutual Funds
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input8'
            value={_80C['input8']}
            onChange={handleChange3}
            key='input8'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn8' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Payment of Tuition fees for Children (Max 2 Children)
        </ParticularTableCell>
        <RemarksTableCell>Two children only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input9'
            value={_80C['input9']}
            onChange={handleChange3}
            key='input9'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn9' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Principal repayment of Housing Loan</ParticularTableCell>
        <RemarksTableCell>Self & Joint a/c holder</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input10'
            value={_80C['input10']}
            onChange={handleChange3}
            key='input10'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn10' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Registration charges incurred for Buying House (I year Only)
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input11'
            value={_80C['input11']}
            onChange={handleChange3}
            key='input11'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn11' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Infrastructure Bonds</ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input12'
            value={_80C['input12']}
            onChange={handleChange3}
            key='input12'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn12' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Bank Fixed Deposit for 5 Years & above</ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input13'
            value={_80C['input13']}
            onChange={handleChange3}
            key='input13'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn13' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Post office Term Deposit for 5years & above</ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input14'
            value={_80C['input14']}
            onChange={handleChange3}
            key='input14'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='CategorieColumn14' onChange={ThirdFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total3} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}

const ForthFormSection = () => {
  const ForthFormFileChange = (id: any, file: any) => {}
  const [total4, setTotal4] = useState<number>(0)
  const [medical, setMedical] = useState({
    input1: 0,
  })

  const handleChange4 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updatedMedical = { ...medical, [name]: Number(e.target.value) }
    setMedical(updatedMedical)
    const total = Object.values(updatedMedical).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal4(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>4</StyledTableCell>
        <ParticularTableCell>Medical Allowance Rs.15,000/- per annum</ParticularTableCell>
        <RemarksTableCell>
          Medical expenses incurred for family: (Spouse, children & dependent parents, brothers &
          sisters )
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={medical['input1']}
            onChange={handleChange4}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='MedicalAllowanceColumn' onChange={ForthFormFileChange} />
        </UploadTableCell>
      </TableRow>
    </>
  )
}

const FifthFormSection = () => {
  const FifthFormFileChange = (id: any, file: any) => {}
  const [total5, setTotal5] = useState<number>(0)
  const [_80D, set80D] = useState({
    input1: 0,
    input2: 0,
  })

  const handleChange5 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80D = { ..._80D, [name]: Number(e.target.value) }
    set80D(updated80D)
    const total = Object.values(updated80D).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal5(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>5 </StyledTableCell>
        <ParticularTableCell>Sec 80D</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Sec 80D (Medi Claim Policy & Preventive health check-up) Rs. 25,000/- (or) Rs.30,000/-
        </ParticularTableCell>
        <RemarksTableCell>
          Medical Insurance Premium PAID by Employee: - on the health of an employee, spouse, and
          dependent children. [Rs.30,000/- In case of an employee is a Senior Citizen] (i.e., 60
          years or more). Payment made by an employee for preventive health check-up of self,
          spouse, dependent children during the financial year is eligible for the maximum deduction
          of Rs 5000. Note: The amount paid is within the overall limits ( Rs. 25000/Rs. 30000).
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80D['input1']}
            onChange={handleChange5}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='FifthColumn1' onChange={FifthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Sec 80D (Medi Claim Policy & Preventive health check-up) Rs. 25,000/- (or) Rs.30,000/-
        </ParticularTableCell>
        <RemarksTableCell>
          Additional Amount of Rs.35,000/- Medical Insurance Premium PAID by Employee on the health
          of parents (even not dependent on the employee). [Rs.30,000/- In the case parents are
          Senior Citizen]i.e., 60 years or more payment made by an employee for preventive health
          check-up of parents during the financial year is eligible for the maximum deduction of Rs
          5000. Note: The amount paid is within the overall limits ( Rs. 25000/Rs. 30000).
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input2'
            value={_80D['input2']}
            onChange={handleChange5}
            key='input2'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='FifthColumn2' onChange={FifthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total5} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}

const SixthFormSection = () => {
  const SixthFormFileChange = (id: any, file: any) => {}
  const [total6, setTotal6] = useState<number>(0)
  const [_80DD, set80DD] = useState({
    input1: 0,
  })

  const handleChange6 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80DD = { ..._80DD, [name]: Number(e.target.value) }
    set80DD(updated80DD)
    const total = Object.values(updated80DD).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal6(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>6</StyledTableCell>
        <ParticularTableCell>
          Sec 80DD (Maintenance including Medical treatment of dependent person with disability) Rs.
          50,000/- (Rs.1,00,000/- for Severe disability)
        </ParticularTableCell>
        <RemarksTableCell>
          Here Employee gets the fixed amount of deduction. If an employee has incurred any
          expenditure / deposited in specified policy. (Form 10-IA obtained from Medical Authority
          has to be submitted)
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80DD['input1']}
            onChange={handleChange6}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='SixthFormColumn' onChange={SixthFormFileChange} />
        </UploadTableCell>
      </TableRow>
    </>
  )
}

const SevnthFormSection = () => {
  const SevthFormFileChange = (id: any, file: any) => {}
  const [total7, setTotal7] = useState<number>(0)
  const [_80DDB, set80DDB] = useState({
    input1: 0,
  })

  const handleChange7 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80DDB = { ..._80DDB, [name]: Number(e.target.value) }
    set80DDB(updated80DDB)
    const total = Object.values(set80DDB).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal7(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>7</StyledTableCell>
        <ParticularTableCell>
          Sec 80DDB (Medical treatment for specified diseases or ailments) - Self / Dependents Rs.
          40,000/- (Rs. 60,000/- for Senior Citizen )
        </ParticularTableCell>
        <RemarksTableCell>
          Here Employee gets the deduction: Lower of the following: i). Amount incurred or ii).
          Rs.40,000/- (or Rs.60,000/- if expenses incurred for senior citizen i.e,60 years or more )
          (Form 10-I obtained from Medical Authority has to be submitted)
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80DDB['input1']}
            onChange={handleChange7}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='SixthFormColumn' onChange={SevthFormFileChange} />
        </UploadTableCell>
      </TableRow>
    </>
  )
}

const EighthFormSection = () => {
  const EighthFormFileChange = (id: any, file: any) => {}
  const [total8, setTotal8] = useState<number>(0)
  const [_80E, set80E] = useState({
    input1: 0,
  })

  const handleChange8 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80E = { ..._80E, [name]: Number(e.target.value) }
    set80E(updated80E)
    const total = Object.values(updated80E).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal8(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>8</StyledTableCell>
        <ParticularTableCell>
          Sec 80E (Education Loan) No limit (only Interest Portion)
        </ParticularTableCell>
        <RemarksTableCell>
          Interest on Loan taken for Higher Education: Amount paid by employee during the financial
          year on loan is taken for higher education of - Employee, spouse or children of employees
          or students for whom employee is a legal guardian Benefit Period: Current Period + Next 7
          years
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80E['input1']}
            onChange={handleChange8}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='EighthFormColumn' onChange={EighthFormFileChange} />
        </UploadTableCell>
      </TableRow>
    </>
  )
}

const NinthFormSection = () => {
  const NinthFormFileChange = (id: any, file: any) => {}
  const [total9, setTotal9] = useState<number>(0)
  const [_80U, set80U] = useState({
    input1: 0,
  })

  const handleChange9 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updated80U = { ..._80U, [name]: Number(e.target.value) }
    set80U(updated80U)
    const total = Object.values(updated80U).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal9(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>9</StyledTableCell>
        <ParticularTableCell>
          Sec 80U (For person with a disability) Rs.50,000/- (Rs.1,00,000/- for Severe disability)
        </ParticularTableCell>
        <RemarksTableCell>
          Here Employee gets the fixed amount of deduction. (Form 10-IA obtained from Medical
          Authority has to be submitted)
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={_80U['input1']}
            onChange={handleChange9}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='NinthFormColumn' onChange={NinthFormFileChange} />
        </UploadTableCell>
      </TableRow>
    </>
  )
}

const TenthFormSection = () => {
  const TenthFormFileChange = (id: any, file: any) => {}
  const [total10, setTotal10] = useState<number>(0)
  const [saving, setSaving] = useState({
    input1: 0,
    input2: 0,
    input3: 0,
    input4: 0,
  })

  const handleChange10 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updatedSaving = { ...saving, [name]: Number(e.target.value) }
    setSaving(updatedSaving)
    const total = Object.values(updatedSaving).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal10(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>10</StyledTableCell>
        <ParticularTableCell>Interest Income from Savings A.c</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Interest Income from Savings A.c (Banks & Post office only)
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={saving['input1']}
            onChange={handleChange10}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='TenthColumn1' onChange={TenthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Interest Income from Savings A.c (Banks & Post office only)
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input2'
            value={saving['input2']}
            onChange={handleChange10}
            key='input2'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='TenthColumn2' onChange={TenthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Interest Income from Savings A.c (Banks & Post office only)
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input3'
            value={saving['input3']}
            onChange={handleChange10}
            key='input3'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='TenthColumn3' onChange={TenthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>
          Interest Income from Savings A.c (Banks & Post office only)
        </ParticularTableCell>
        <RemarksTableCell>Self only</RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input4'
            value={saving['input4']}
            onChange={handleChange10}
            key='input4'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='TenthColumn4' onChange={TenthFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total10} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}

const EleventhFormSection = () => {
  const EleventhFormFileChange = (id: any, file: any) => {}
  const [total11, setTotal11] = useState<number>(0)
  const [previous, setPrevious] = useState({
    input1: 0,
    input2: 0,
    input3: 0,
    input4: 0,
  })

  const handleChange11 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    if (isNaN(Number(e.target.value))) return
    const updatedPrevious = { ...previous, [name]: Number(e.target.value) }
    setPrevious(updatedPrevious)
    const total = Object.values(updatedPrevious).reduce((a, b) => a + b, 0)
    const roundedTotal = parseFloat(total.toFixed(2)) // Round the total to 2 decimal places
    setTotal11(roundedTotal)
  }

  return (
    <>
      {' '}
      <TableRow>
        <StyledTableCell>11</StyledTableCell>
        <ParticularTableCell>
          Income From Previous Employer (Joined after 01/04/2017)
        </ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>AMOUNT Rs</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Income after exemptions</ParticularTableCell>
        <RemarksTableCell rowSpan={4}>
          Form 16 or Tax computation from previous employer should be furnished
        </RemarksTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input1'
            value={previous['input1']}
            onChange={handleChange11}
            key='input1'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='EleventhColumn1' onChange={EleventhFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Provident Fund (PF)</ParticularTableCell>
        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input2'
            value={previous['input2']}
            onChange={handleChange11}
            key='input2'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='EleventhColumn2' onChange={EleventhFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Professional Tax (PT)</ParticularTableCell>

        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input3'
            value={previous['input3']}
            onChange={handleChange11}
            key='input3'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='EleventhColumn3' onChange={EleventhFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <ParticularTableCell>Tax deducted at source (TDS)</ParticularTableCell>

        <AmountTableCell>
          {/* <InputField size='small' /> */}
          <UserInput
            name='input4'
            value={previous['input4']}
            onChange={handleChange11}
            key='input4'
            initial={5}
          />
        </AmountTableCell>
        <UploadTableCell>
          <CommonFileUpload id='EleventhColumn4' onChange={EleventhFormFileChange} />
        </UploadTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell> </StyledTableCell>
        <ParticularTableCell>TOTAL</ParticularTableCell>
        <StyledTableCell></StyledTableCell>
        <AmountTableCell>
          <InputField size='small' value={total11} />
        </AmountTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </>
  )
}

const InvestmentFormTable = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '5%' }}>S.NO.</StyledTableCell>
              <ParticularTableCell>PARTICULARS</ParticularTableCell>
              <StyledTableCell sx={{ width: '30%' }}>REMARKS</StyledTableCell>
              <StyledTableCell sx={{ width: '20%' }}>
                AMOUNT DECLARED BY YOU IN THE DECLARATION FORM
              </StyledTableCell>
              <StyledTableCell>UPLOAD FILES</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FirstFormSection />
            <SecondFormSection />
            <ThirdFormSection />
            <ForthFormSection />
            <FifthFormSection />
            <SixthFormSection />
            <SevnthFormSection />
            <EighthFormSection />
            <NinthFormSection />
            <TenthFormSection />
            <EleventhFormSection />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant='outlined'
          sx={{
            fontSize: '13px',
            height: '42px',
            marginTop: '15px',
            marginRight: '3px',
            borderRadius: '20px',
            padding: '5px 20px',
          }}
          onClick={() => {}}
        >
          Save
        </Button>
        <Button
          variant='outlined'
          sx={{
            fontSize: '13px',
            height: '42px',
            marginTop: '15px',
            marginLeft: '3px',
            borderRadius: '20px',
            padding: '5px 20px',
            backgroundColor: 'white',
            color: 'black',
          }}
          onClick={() => {}}
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}

const InvestmentForm = () => {
  return (
    <div style={MainContainer}>
      <StyledPaper>
        <Heading>TUDIP TECHNOLOGIES PRIVATE LIMITED</Heading>
        <SubHeading>INVESTMENT DECLARATION FORM FOR FY 2023-24, AY 2024-25</SubHeading>
        <InvestmentFormTable />
      </StyledPaper>
    </div>
  )
}

export default InvestmentForm
