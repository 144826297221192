import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import {
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Primary } from '../../utils'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import {
  fetchDesignation,
  fetchDesignationBand,
  fetchEmpInfo,
  fetchEmplymentType,
  fetchSAPeriod,
  fetchTiming,
  getManagerDataById,
  getRefDataById,
} from '../../actions'
import { dashboardEntity, dashboardUI, employeePortalEntity } from '../../reducers'
import getHardCodedData from './Info.json'
import { loaderProps } from '../Common/CommonStyles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '85%',
  padding: '30px 25px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    cursor: 'not-allowed',
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
    fontSize: '14px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const HeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
  margin: '15px 0',
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    cursor: 'not-allowed',
    padding: '13px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
    fontSize: '14px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  paddingLeft: '10px',
  fontSize: '13px',
  marginLeft: '10px',
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '13px',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: '13px',
  },
}))

const Label = styled(FormLabel)({
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  opacity: '1',
  color: 'black',
})

const BasicInfo = (props: any) => {
  const data = props.json
  const [adharNum, setAdharNum] = useState(props.json?.aadhaar_card_no || 'NA')
  const [panNum, setPanNum] = useState(props.json?.pan_card_no || 'NA')

  const [passportNum, setPassportNum] = useState(props.json.passport_no || 'NA')
  const [pfApplicable, setPfApplicable] = useState(props.json.pf_applicable === 1 ? 'Yes' : 'No')
  const [pfNum, setPfNum] = useState(props.json.pf_no || 'NA')
  const [uanNum, setUanNum] = useState(props.json.uan_no || 'NA')

  const [selectedValue, setSelectedValue] = useState('Yes')
  const [isDisabled, setIsDisabled] = useState(true)

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value)
  }

  return (
    <>
      <StyledPaper elevation={0}>
        <HeaderHeading sx={{ margin: '0px 0px 30px 0px' }}>Employee Information</HeaderHeading>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              InputProps={{
                readOnly: true,
              }}
              label='Aadhaar Number'
              value={data.aadhaar_card_no ?? 'NA'}
              size='small'
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='PAN Number'
              value={data.pan_card_no ? data.pan_card_no : 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              label='Passport Number'
              value={data.passport_no ? data.passport_no : 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <StyledFormControl>
              <StyledFormLabel>PF Applicable</StyledFormLabel>
              <RadioGroup row>
                <StyledFormControlLabel
                  value='Yes'
                  control={<Radio />}
                  label='Yes'
                  checked={data.pf_applicable === 1}
                  onChange={handleRadioChange}
                  disabled={isDisabled}
                />
                <StyledFormControlLabel
                  value='No'
                  control={<Radio />}
                  label='No'
                  checked={data.pf_applicable === 0}
                  onChange={handleRadioChange}
                  disabled={isDisabled}
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='PF Number'
              value={data.pf_no ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='UAN Number'
              value={data.uan_no ?? 'NA'}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </>
    // <StyledPaper elevation={0}>
    //   <HeaderHeading sx={{ margin: '0px 0px 30px 0px' }}>Employee Information

    //     {props.json?.aadhaar_card_no}</HeaderHeading>
    //   <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
    //     <Grid item xs={4} sm={4}>
    //       <InputField
    //         label='Aadhaar Number'
    //         defaultValue={props.json.aadhaar_card_no}
    //         size='small'
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //       />
    //     </Grid>

    //     <Grid item xs={4} sm={4}>
    //       <InputField
    //         label='PAN Number'
    //         defaultValue={panNum}
    //         size='small'
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //       />
    //     </Grid>

    //     <Grid item xs={4} sm={4}>
    //       <InputField
    //         label='Passport Number'
    //         defaultValue={passportNum}
    //         size='small'
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //       />
    //     </Grid>

    //     <Grid item xs={4} sm={4}>
    //       <StyledFormControl>
    //         <StyledFormLabel>PF Applicable</StyledFormLabel>
    //         <RadioGroup row>
    //           <StyledFormControlLabel
    //             value='Yes'
    //             control={<Radio />}
    //             label='Yes'
    //             checked={pfApplicable === 'Yes'}
    //             onChange={handleRadioChange}
    //             disabled={isDisabled}
    //           />
    //           <StyledFormControlLabel
    //             value='No'
    //             control={<Radio />}
    //             label='No'
    //             checked={pfApplicable === 'No'}
    //             onChange={handleRadioChange}
    //             disabled={isDisabled}
    //           />
    //         </RadioGroup>
    //       </StyledFormControl>
    //     </Grid>
    //     <Grid item xs={4} sm={4}>
    //       <InputField
    //         label='PF Number'
    //         defaultValue={pfNum}
    //         size='small'
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //       />
    //     </Grid>
    //     <Grid item xs={4} sm={4}>
    //       <InputField
    //         label='UAN Number'
    //         defaultValue={uanNum}
    //         size='small'
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //       />
    //     </Grid>
    //   </Grid>
    // </StyledPaper>
  )
}

const ProfessionalInfo = (props: any) => {
  useEffect(() => {
    // Set the value of temp when the component mounts
    setFirstWeekend(props.json.first_weekend)
    setSecondWeekend(props.json.second_weekend)
    setSAmount(props.json.service_agreement_amount)
    setWorkingExperience(props.json.working_experience)
  }, [
    props.json.first_weekend,
    props.json.second_weekend,
    props.json.service_agreement_amount,
    props.json.working_experience,
  ])
  const { getAllWorkingExperience } = getHardCodedData
  const { getAllWeek } = getHardCodedData
  const { getAllServiceAgreementAmmount } = getHardCodedData
  const data = props.json

  const designation = props.designation
    .filter((user: any) => user.id === data.id_grade)
    .map((user: any) => `${user.grade_name}-${user.desc}`)
    .join('-')

  const designationband = props.designationband
    .filter((user: any) => user.id === data.designation_band)
    .map((user: any) => user.band_title)

  const employment_type = props.employment_type
    .filter((user: any) => user.id === data.employment_type)
    .map((user: any) => user.type_name)

  const service_agreement_period = props.service_agreement_period
    .filter((user: any) => user.id === data.id_service_agreement)
    .map((user: any) => user.month)

  // const designation = props.designation.filter((user: any) => { user.id === data.job_id })
  const myWeekend = 'Sunday'
  const getManagerName = props.getUserById
  const getReferredname = props.getReferredById

  const managerName = getManagerName.name
  const referredName = getReferredname.name

  const [isDisabled, setIsDisabled] = useState(true)
  // const [designation, setDesignation] = useState(props.json.designation || 'NA')
  const [designationBand, setdesignationBand] = useState(props.json.designation_band || 'NA')
  const [manager, setManager] = useState(props.getUserById?.id || 'NA')
  const [refer, setRefer] = useState(props.getReferredById?.id || 'NA')
  // const [employementType, setemployementType] = useState(props.json.employment_type || 'NA')
  const [firstWeekend, setFirstWeekend] = useState('')
  const [secondWeekend, setSecondWeekend] = useState('')
  const [tshirtSize, setTshirtSize] = useState('')
  const [SAPeriod, setSAPeriod] = useState(props.json.id_service_agreement || 'NA')
  const [SAAmount, setSAmount] = useState('')
  const [workingexperience, setWorkingExperience] = useState(props.json.working_experience || 'NA')
  const [notice, setNotice] = useState(props.json.under_notice === 1 ? 'Yes' : 'No')
  const [noticePeriod, setNoticePeriod] = useState(props.json.notice_period || 'NA')
  const [relieving, setRelieving] = useState(props.json.type_of_relieving || 'NA')

  const inputDateStr = data.hire_date
  const dateObject = new Date(inputDateStr)
  const formattedDate = dateObject.toLocaleDateString('en-US')

  useEffect(() => {
    setManager(props?.getUserById?.id || 'NA')
    setRefer(props.getReferredById?.id || 'NA')
  }, [props.getUserById?.id, props.getReferredById?.id])

  const handleDesignationBand = (event: any) => {
    setdesignationBand(event.target.value)
  }
  const handleManager = (event: any) => {
    setManager(event.target.value)
  }
  const handleRefer = (event: any) => {
    setRefer(event.target.value)
  }

  const handleNotice = (event: any) => {
    setNotice(event.target.value)
  }
  const handleNoticePeriod = (event: any) => {
    setNoticePeriod(event.target.value)
  }
  const handleRelieving = (event: any) => {
    setRelieving(event.target.value)
  }

  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '-1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Designation</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={designation}
              label='Designation'
              // onChange={handleDesignation}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={designation}>{designation}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Designation Band</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={designationband}
              label='Designation Band'
              onChange={handleDesignationBand}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={designationband}>{designationband}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Manager</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={manager}
              label='Manager'
              // onChange={handleManager}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value={'NA'}>{'NA'}</MenuItem>
              <MenuItem value={props.getUserById?.id}>{props.getUserById?.name}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        {/* <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: "-1px" }}>
            <InputLabel id='demo-simple-select-readonly-label'>Referred By</InputLabel>
            <SelectField
        
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={refer}
              label='Referred By'
              onChange={handleRefer}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value={'NA'}>{'NA'}</MenuItem>
              <MenuItem value={props.getReferredById?.id}>{props.getReferredById?.name}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>  */}

        {/* <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-readonly-label'>Employment Type</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={employment_type}
              label='Employment Type'
              // onChange={handleEmployementType}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={employment_type}>{employment_type}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid> */}
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>First Weekend</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={firstWeekend}
              label='First Weekend'
              inputProps={{ readOnly: true }}
            >
              {getAllWeek.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Second Weekend</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              label='Second Weekend'
              value={secondWeekend}
              inputProps={{ readOnly: true }}
            >
              {getAllWeek.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>

            {/* <SelectField
                required
                id='outlined-required'
                label='Priority'
                size='small'
                select
                value={selectedPriorityOption}
                onChange={handlePriorityChange}
                variant='outlined'
              >
                {AllPriority.map((option) => (
                  <StyledMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </SelectField>
 */}
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            label='Hire Date'
            value={formattedDate || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={4} sm={4} >
          <InputField
            required
            label='Effective Date'
            value={formattedDate || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid> */}

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Experience While Joining</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={workingexperience}
              label='Experience While Joining'
              // onChange={handleExperience}
              inputProps={{ readOnly: true }}
            >
              {getAllWorkingExperience.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Service Agreement Period</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={service_agreement_period}
              label='Service Agreement Period'
              // onChange={handleSAPeriod}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={service_agreement_period}>{service_agreement_period + " month"}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            label='Service Agreement Amount'
            value={SAAmount || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-readonly-label'>Service Agreement Amount</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={SAAmount}
              label='Service Agreement Amount'
              // onChange={handleSAAmount}
              inputProps={{ readOnly: true }}
            >
              {getAllServiceAgreementAmmount.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid> */}

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Notice</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.under_maternity === 1}
                onChange={handleNotice}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.under_maternity === 0}
                onChange={handleNotice}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Notice Period</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={props.json.noticePeriod}
              label='Notice Period'
              onChange={handleNoticePeriod}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>1 months</MenuItem>
              <MenuItem value={2}>2 months</MenuItem>
              <MenuItem value={3}>3 months</MenuItem>
              <MenuItem value={4}>4 months</MenuItem>
              <MenuItem value={5}>5 months</MenuItem>
              <MenuItem value={6}>6 months</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Type of Relieving</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={props.json.termination_type}
              label='Type of Relieving'
              onChange={handleRelieving}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Select</MenuItem>
              <MenuItem value={1}>Terminated</MenuItem>
              <MenuItem value={2}>Absconded</MenuItem>
              <MenuItem value={3}>Relieved</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const OtherInfo = (props: any) => {
  const { getAllTshirtSize } = getHardCodedData
  const { getAllTimingData } = getHardCodedData

  const [manager, setManager] = useState(props.getUserById?.id || 'NA')
  const [tshirtSize, setTshirtSize] = useState('')
  const [timing, setTiming] = useState('')
  const data = props.json
  useEffect(() => {
    setTshirtSize(props.json.tshirt_size)
  }, [props.json.tshirt_size])

  useEffect(() => {
    setTiming(props.json.timing_id)
  }, [props.json.timing_id])

  const timing_id = props.timing_id

  const [isDisabled, setIsDisabled] = useState(true)
  const [performanceReview, setPerformanceReview] = useState(
    props.json.first_performance_review === 1 ? 'Yes' : 'No',
  )
  const [refer, setRefer] = useState(props.getReferredById?.id || 'NA')
  const [idCard, setIdCard] = useState(props.json.id_card_issued === 1 ? 'Yes' : 'No')
  const [mediClaim, setMediClaim] = useState(props.json.mediclaim_issued === 1 ? 'Yes' : 'No')
  const [tshirt, setTshirt] = useState(props.json.tshirt_size || 'NA')
  const [maternity, setMaternity] = useState(props.json.under_maternity === 1 ? 'Yes' : 'No')
  const [poorPerformanceReview, setPoorPerformanceReview] = useState(
    props.json.poor_performance_review === 1 ? 'Yes' : 'No',
  )

  const handlePerformanceReview = (event: any) => {
    setPerformanceReview(event.target.value)
  }
  const handleIdCard = (event: any) => {
    setIdCard(event.target.value)
  }
  const handleMediClaim = (event: any) => {
    setMediClaim(event.target.value)
  }
  // const handleTshirt = (event: any) => {
  //   setTshirt(event.target.value)
  // }
  // const handleTiming = (event: any) => {
  //   setTiming(event.target.value)
  // }
  const handleMaternity = (event: any) => {
    setTiming(event.target.value)
  }
  const handlePoorPerformanceReview = (event: any) => {
    setTiming(event.target.value)
  }

  const handleRefer = (event: any) => {
    setRefer(event.target.value)
  }

  useEffect(() => {
    setManager(props?.getUserById?.id || 'NA')
    setRefer(props.getReferredById?.id || 'NA')
  }, [props.getUserById?.id, props.getReferredById?.id])

  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>First Performance Review</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.first_performance_review === 1}
                onChange={handlePerformanceReview}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.first_performance_review === 0}
                onChange={handlePerformanceReview}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>ID Card Issued</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.id_card_issued === 1}
                onChange={handleIdCard}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.id_card_issued === 0}
                onChange={handleIdCard}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Mediclaim Issued</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.mediclaim_issued === 1}
                onChange={handleMediClaim}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.mediclaim_issued === 0}
                onChange={handleMediClaim}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Performance Improvement Plan</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={poorPerformanceReview === 'Yes'}
                onChange={handlePerformanceReview}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={poorPerformanceReview === 'No'}
                onChange={handlePerformanceReview}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Maternity</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.under_maternity === 1}
                onChange={handleMaternity}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.under_maternity === 0}
                onChange={handleMaternity}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            label='Mediclaim Number'
            value={data.mediclaim_no ? data.mediclaim_no : 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-readonly-label'>T-shirt Size</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={tshirtSize}
              label='T-shirt Size'
              // onChange={handleTshirt}
              inputProps={{ readOnly: true }}
            >
              {getAllTshirtSize.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>

        {/* <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-readonly-label'>Timing</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={timing}
              label='Timing'
              // onChange={handleTiming}
              inputProps={{ readOnly: true }}
            > 
                {getAllTimingData.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid> */}

        {/* <Grid item xs={4} sm={4}>
          <InputField
            required
            label='Effective Date'
            defaultValue={props.json.effective_date || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid> */}

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '-1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Referred By</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={refer}
              label='Referred By'
              onChange={handleRefer}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value={'NA'}>{'NA'}</MenuItem>
              <MenuItem value={props.getReferredById?.id}>{props.getReferredById?.name}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

// https://dev-kuber-v2.tudip.uk/users/basic-info?userId=93
// https://dev-kuber-v2.tudip.uk/users/basic-info?userId=440

// const OfficeTimingLogs = (props: any) => {
//   const { empData } = props;
//   return (
//     <StyledPaper elevation={0}>
//       <LeavesDetailsHeading>OFFICE TIMING LOGS</LeavesDetailsHeading>

//       <TableContainer component={Paper}>
//         <Table sx={{ minWidth: 700 }} aria-label='customized table'>
//           <TableHead>
//             <TableRow>
//               <StyledTableCell>OFFICE TIMING</StyledTableCell>
//               <StyledTableCell>FROM</StyledTableCell>
//               <StyledTableCell>TILL</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <StyledTableRow>
//               <StyledTableCell component='th' scope='row'>
//                 {moment(empData?.officeTimingLogs?.from).format("hh:mm A")}
//               </StyledTableCell>
//               <StyledTableCell align='right'>{moment(empData?.officeTimingLogs?.from).format("DD-MM-YYYY")}</StyledTableCell>
//               <StyledTableCell align='right'>
//                 {empData?.officeTimingLogs?.till
//                   ? moment(empData.officeTimingLogs.till).format("hh:mm A")
//                   : 'N/A'}
//               </StyledTableCell>
//             </StyledTableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </StyledPaper>
//   )
// }

const Info = (props: any) => {
  const {
    loaderState,
    BasicInfoData,
    fetchBasicInfoData,
    fetchUserData,
    userData,
    fetchDesignationData,
    designationData,
    fetchDesignationBandData,
    designationBandData,
    fetchEmploymentTypeData,
    employmentTypeData,
    fetchSAPeriodData,
    saPeriodData,
    fetchTimingData,
    timingData,
    getManagerDataById,
    getRefDataById,
    getUserById,
    getReferredById,
  } = props
  useEffect(() => {
    fetchUserData()
    fetchDesignationData()
    fetchDesignationBandData()
    fetchEmploymentTypeData()
    fetchSAPeriodData()
    // fetchTimingData()
  }, [])
  // Inside the component Info
  useEffect(() => {
    if (userData.id_manager) {
      getManagerDataById({ id: userData.id_manager })
    }
  }, [userData.id_manager])

  useEffect(() => {
    if (userData.referred_by) {
      getRefDataById({ id: userData.referred_by })
    }
  }, [userData.referred_by])

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div
          style={{
            backgroundColor: 'rgb(231, 235, 240)',
            width: '100%',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BasicInfo json={userData} />
              <ProfessionalInfo
                json={userData}
                timing_id={timingData}
                service_agreement_period={saPeriodData}
                designation={designationData}
                designationband={designationBandData}
                employment_type={employmentTypeData}
                getUserById={getUserById}
                getReferredById={getReferredById}
              />
              <OtherInfo
                json={userData}
                getReferredById={getReferredById}
                getUserById={getUserById}
              />
              {/* <OfficeTimingLogs empData={userData} /> */}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: employeePortalEntity.getEmployeePortal(state).getEmpInfoData,
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    employmentTypeData: employeePortalEntity.getEmployeePortal(state).getEmploymentTypeData,
    saPeriodData: employeePortalEntity.getEmployeePortal(state).getSAPeriodData,
    timingData: employeePortalEntity.getEmployeePortal(state).getTimingData,
    getUserById: dashboardEntity.getDashboard(state).getManagerDataById,
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    getReferredById: dashboardEntity.getDashboard(state).getRefDataById,
    loaderState: dashboardUI.getDashboard(state).isGetEmpInfoData,
  }
}

// Define mapDispatchToProps function
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserData: () => dispatch(fetchEmpInfo.request()),
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchEmploymentTypeData: () => dispatch(fetchEmplymentType.request()),
    fetchSAPeriodData: () => dispatch(fetchSAPeriod.request()),
    fetchTimingData: () => dispatch(fetchTiming.request()),
    getManagerDataById: (data: any) => {
      dispatch(getManagerDataById.request(data))
    },
    getRefDataById: (data: any) => {
      dispatch(getRefDataById.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)
