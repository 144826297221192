import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  ADD_NEW_COMMENT,
  FETCH_ASSIGNED_REQUEST,
  FETCH_DOWNLOADABLE_URL,
} from '../../actions/actiontypes'
import { CREATE_SERVICE_REQUEST } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, RESET } = actionTypes

const ui = () => {
  const isCommentPostDone = (state = false, action: actions) => {
    switch (action.type) {
      case ADD_NEW_COMMENT[SUCCESS]:
        return true
      case ADD_NEW_COMMENT[FAILURE]:
        return false
      case ADD_NEW_COMMENT[REQUEST]:
        return false
      case ADD_NEW_COMMENT[RESET]:
        return false
      default:
        return state
    }
  }

  const isCreateServiceRequestSuccess = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_SERVICE_REQUEST[SUCCESS]:
        return true
      case CREATE_SERVICE_REQUEST[FAILURE]:
      case CREATE_SERVICE_REQUEST[REQUEST]:
      case CREATE_SERVICE_REQUEST[RESET]:
        return false
      default:
        return state
    }
  }

  const isCreateServiceRequest = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_SERVICE_REQUEST[REQUEST]:
        return true
      case CREATE_SERVICE_REQUEST[FAILURE]:
      case CREATE_SERVICE_REQUEST[SUCCESS]:
      case CREATE_SERVICE_REQUEST[RESET]:
        return false
      default:
        return state
    }
  }

  const isAssignedRequest = (state = false, action: actions) => {
    switch (action.type) {
      case FETCH_ASSIGNED_REQUEST[REQUEST]:
        return true
      case FETCH_ASSIGNED_REQUEST[FAILURE]:
      case FETCH_ASSIGNED_REQUEST[SUCCESS]:
      case FETCH_ASSIGNED_REQUEST[RESET]:
        return false
      default:
        return state
    }
  }

  const isDownloadable = (state = false, action: actions) => {
    switch (action.type) {
      case FETCH_DOWNLOADABLE_URL[SUCCESS]:
        return true
      case FETCH_DOWNLOADABLE_URL[FAILURE]:
      case FETCH_DOWNLOADABLE_URL[REQUEST]:
      case FETCH_DOWNLOADABLE_URL[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isCommentPostDone,
    isCreateServiceRequestSuccess,
    isCreateServiceRequest,
    isAssignedRequest,
    isDownloadable,
  })
}

export default ui

export const getServicePostState = (state: RootState) => state.ui.servicepostState
export const getServiceRequestCreate = (state: RootState) => state.ui.servicepostState
