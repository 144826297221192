import React from 'react'
import { NavLink } from 'react-router-dom'
import { ForgotPasswordContainer, ForgotPasswordContent, FPLogo } from './Styles'
import Footer from '../Common/Footer'

const ForgotPassword = () => {
  return (
    <ForgotPasswordContainer>
      <ForgotPasswordContent>
        <FPLogo src='../images/Kuber_Logo.png' />
        <p>
          To retrieve your password, please drop an email to{' '}
          <span style={{ color: 'red' }}> mailto:hr@tudip.com</span>, They will revert back to you
          in 2-3 working days.
        </p>
        <NavLink
          to='/'
          style={(isActive) => ({
            color: isActive ? 'red' : 'red',
            marginTop: '30px',
            textDecoration: 'none',
          })}
        >
          Back to Log In
        </NavLink>
      </ForgotPasswordContent>
      <Footer />
    </ForgotPasswordContainer>
  )
}

export default ForgotPassword
