import { Box, Typography, CircularProgress, Dialog, InputLabel } from '@mui/material'
import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import {
  HeaderHeading,
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { getAttendanceDetail, getAttendanceTimesheet } from '../../actions'
import { Dispatch } from 'redux'
import { attendanceEntity, attendanceUI, dashboardEntity } from '../../reducers'
import { useState, useEffect, useCallback } from 'react'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import moment from 'moment'
import { SelectField } from '../Common/ComponentCommonStyles'
import FormControl from '@mui/material/FormControl'

const AttendanceList = (props: any) => {
  const {
    fetchAttendanceData,
    AttendanceTimesheet,
    AttendanceData,
    loaderState,
    getUserById,
    fetchAttendanceTimesheet,
  } = props

  const [selectedMonth, setSelectedMonth] = useState('')

  useEffect(() => {
    if (getUserById.id) {
      fetchAttendanceTimesheet(getUserById.id)
    }
  }, [getUserById.id])

  useEffect(() => {
    if (AttendanceTimesheet.length) {
      setSelectedMonth(AttendanceTimesheet[0].id)
      fetchAttendanceData({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
    }
  }, [AttendanceTimesheet])

  const handleSelectMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedMonth(selectedMonthId)
      fetchAttendanceData({
        userId: getUserById.id,
        tId: selectedMonthId,
      })
    },
    [getUserById.id, fetchAttendanceData],
  )

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '93%',
    padding: '25px 25px',
    background: '#FFFFFF',
    opacity: '1',
    margin: '20px',
    border: '1px solid #DDDDDD',
    minHeight: '350px',
  }))
  const MainContainer = {
    backgroundColor: 'rgb(231, 235, 240)',
    width: '100%',
  }

  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginLeft: '-38px',
  }))

  const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    letterSpacing: '0px',
  }))

  const AttendanceListTable = (props: any) => {
    const useStyles = makeStyles({
      header: {},
      subHeadingContainer: {
        position: 'relative',
        top: 14,
        left: 18,
        margin: '20px 0px !important',
      },
      SubHeading: {
        color: '#193C6D',
        right: '20px',
        padding: '6px',
        margin: '10px',
        position: 'relative',
        bottom: '8px',
      },
      LateAttendanceSubHeadingContainer: {
        position: 'relative',
        top: '48px',
        left: '18px',
        margin: '20px 0px !important',
      },
      LateAttendanceSubHeading: {
        color: '#193C6D',
        bottom: '38px',
        position: 'relative',
      },
      list: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        listStyle: 'none',
      },
    })
    const classes = useStyles(props)
    const navigate = useNavigate()

    const convertTime = (date: any) => {
      const originalDate = new Date(date)

      const modifiedDate = new Date(originalDate)
      modifiedDate.setHours(originalDate.getHours() - 5)
      modifiedDate.setMinutes(originalDate.getMinutes() - 30)
      return modifiedDate.toISOString()
    }

    const formatTime = (timeString: any) => {
      const [hourString, minute] = timeString.split(':')
      const hour = +hourString % 24
      return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'AM' : 'PM')
    }

    return (
      <>
        <StyledPaper>
          <Box sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-start' }}>
            <Box sx={{ width: '160px' }}>
              <StyledFormControl>
                <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                <SelectField
                  sx={{ borderRadius: '22px' }}
                  variant='outlined'
                  label='Select Month:'
                  value={selectedMonth}
                  onChange={handleSelectMonth}
                >
                  {AttendanceTimesheet.map((option: any, index: number) => (
                    <StyledMenuItem key={index} value={option?.id}>
                      {moment(option?.start_date).format('MMMM YYYY')}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </StyledFormControl>
            </Box>
            <Box
              onClick={() => navigate(-1)}
              sx={{
                float: 'left',
                marginRight: '-25px',
                pr: '30px',
                mt: '0px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              {/* <ArrowBack /> */}
            </Box>
          </Box>
          <div>
            <HeaderHeading sx={{ marginTop: '-42px' }}>Attendance Details</HeaderHeading>
            <Typography align='center' sx={{ position: 'relative', top: '15px' }}>
              Your current reporting time is at: {formatTime(props?.data?.officeTiming || '')}
            </Typography>
          </div>
          {/* tables */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'baseline',
            }}
          >
            {/* first table */}
            <Box sx={{ flex: '1', marginRight: '20px' }}>
              <Typography className={classes.LateAttendanceSubHeadingContainer}>
                <SubHeading className={classes.LateAttendanceSubHeading}>
                  Late Attendance
                </SubHeading>
              </Typography>
              <TableContainer
                sx={{
                  margin: '20px 0',
                  position: props.data.lateAttendanceDetails?.length > 0 ? 'relative' : 'static',
                  maxHeight: '300px',
                }}
                component={Paper}
              >
                <Table
                  sx={{
                    minWidth: 670,
                    minHeight: props.data.lateAttendanceDetails?.length > 0 ? 115 : 50,
                  }}
                  aria-label='customized table'
                >
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                        REPORTING TIME
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>
                        ACTUAL REPORTING TIME
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>LATE BY</StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>DATE</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {props.data.lateAttendanceDetails?.length > 0 ? (
                      props?.data?.lateAttendanceDetails.map((data: any, index: number) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component='th'
                            scope='row'
                            style={{ verticalAlign: 'top', margin: '25px' }}
                          >
                            {moment(new Date(convertTime(data.expected_scan_time)), 'HH:mm').format(
                              'h:mm A',
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            component='th'
                            scope='row'
                            style={{ verticalAlign: 'top' }}
                          >
                            {moment(new Date(convertTime(data.actual_scan_time)), 'HH:mm').format(
                              'h:mm A',
                            )}
                          </StyledTableCell>
                          <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                            {data.late_by}
                          </StyledTableCell>
                          <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                            {moment(new Date(data.expected_scan_time), 'YYYY-MM-DD').format('dddd')}
                          </StyledTableCell>
                          <StyledTableCell align='left' style={{ verticalAlign: 'top' }}>
                            {data.date}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell align='center' colSpan={10}>
                          <Typography variant='subtitle1' align='center' sx={{ color: '#483f3f' }}>
                            No data found
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {/* second table */}
            <Box sx={{ flex: '1' }}>
              <Typography className={classes.subHeadingContainer}>
                <SubHeading className={classes.SubHeading}>No Attendance</SubHeading>
              </Typography>

              <TableContainer
                sx={{
                  margin: '20px 0',
                  position: 'relative',
                  bottom: '6px',
                  maxHeight: '300px',
                }}
                component={Paper}
              >
                <Table
                  sx={{
                    minWidth: 420,
                    minHeight: 105,
                  }}
                  aria-label='customized table'
                >
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                        REPORTING TIME
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>{' '}
                      <StyledTableCell style={{ textAlign: 'left' }}>DATE</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  {props.data.noAttendanceDetails?.length > 0 ? (
                    <TableBody>
                      {props?.data?.noAttendanceDetails.map((data: any, index: number) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component='th' scope='row'>
                            {moment(new Date(convertTime(data.expected_scan_time)), 'HH:mm').format(
                              'h:mm A',
                            )}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {moment(
                              new Date(convertTime(data.expected_scan_time)),
                              'YYYY-MM-DD',
                            ).format('dddd')}
                          </StyledTableCell>
                          <StyledTableCell align='left'>{data.date}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align='center' colSpan={10}>
                          <Typography variant='subtitle1' align='center' sx={{ color: '#483f3f' }}>
                            No data found
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </StyledPaper>
      </>
    )
  }

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div style={MainContainer}>
          {!AttendanceTimesheet.length ? (
            <div></div>
          ) : (
            <AttendanceListTable data={AttendanceData} />
          )}
        </div>
      )}
    </>
  )
}

//used to map relavant part of redux state to its props
const mapStateToProps = (state: RootState) => {
  return {
    AttendanceData: attendanceEntity.getAllAttendance(state).getEmployeeAttendance,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    loaderState: attendanceUI.getEmpAttendanceList(state).isGetEmployeeAttendanceList,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
  }
}

//api calls here to display data
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceList)
//connect to redux store through connect
