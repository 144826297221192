import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, CircularProgress, Dialog, Pagination, Typography } from '@mui/material'
import { Primary } from '../../utils'
import { SearchBoxCustom, SearchIconStyle, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { fetchAsignedRequest, fetchDepartmentList, fetchSR } from '../../actions'
import { SRUI, dashboardEntity, dashboardUI } from '../../reducers'
import getHardCodedData from './ServiceRequestData.json'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import SRTab from '../Common/SRTab'
import UpdateRequestDialog from './UpdateRequestDialog'


const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const SearchBox: React.CSSProperties = {
  width: '250px',
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
    padding: '11px 0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const ServiceRequestTableData = ({
  serviceRequestData,
  DepartmentList,
  page,
  handleChangePage,
  fetchSRData1,
  total,
}: any) => {
  const { getAllPriority } = getHardCodedData
  const { getAllStatus } = getHardCodedData
  const getPriorityLabel = (priorityValue: any) => {
    const priority = getAllPriority.find((p: any) => p.value === priorityValue)
    return priority ? priority.label : 'Unknown'
  }
  const getStatusLabel = (statusValue: any) => {
    const status = getAllStatus.find((p: any) => p.value === statusValue)
    return status ? status.label : 'Unknown'
  }
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [totalPages, setTotalPages] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])

  const [selectedRowData, setSelectedRowData] = useState(null)
  const [open, setOpen] = useState(false)
  const [createButton, setCreateButton] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const filteredData = serviceRequestData?.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
    setFilteredRows(filteredData || [])
  }, [searchQuery, serviceRequestData])

  useEffect(() => {
    const totalRows = total || 0
    const totalPages = Math.ceil(totalRows / rowsPerPage)

    setTotalPages(totalPages)
  }, [total, rowsPerPage])

  useEffect(() => {}, [])

  const handleClickOpen = (rowData: any) => {
    setSelectedRowData(rowData)
    setOpen(true)
    setCreateButton(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value)
  }

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <SRTab />
      <SearchBoxCustom
        id='outlined-basic'
        placeholder='Search by Title'
        variant='outlined'
        size='small'
        value={searchQuery}
        onChange={handleSearch}
        style={SearchBox}
        InputProps={{
          startAdornment: <SearchIconStyle />,
        }}
      />
      <UpdateRequestDialog
        open={open}
        fetchSRData1={fetchSRData1}
        onClose={handleClose}
        rowData={selectedRowData}
        DepartmentList={DepartmentList}
      />

      <TableContainer component={Paper} sx={{ cursor: 'pointer' }}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>TICKET NO</StyledTableCell>
              <StyledTableCell>TITLE</StyledTableCell>
              <StyledTableCell>DEPARTMENT</StyledTableCell>
              <StyledTableCell>ISSUE TYPE</StyledTableCell>
              <StyledTableCell>PRIORITY</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>RAISED BY</StyledTableCell>
              <StyledTableCell>RAISED ON</StyledTableCell>
              <StyledTableCell>DUE BY</StyledTableCell>
            </TableRow>
          </TableHead>
          {filteredRows && filteredRows.length ? (
            <TableBody>
              {filteredRows.map((request: any) => (
                <StyledTableRow onClick={() => handleClickOpen(request)} key={request.srId}>
                  <StyledTableCell component='th' scope='row'>
                    {request?.srId}
                  </StyledTableCell>
                  <StyledTableCell>{request?.title}</StyledTableCell>
                  <StyledTableCell>{request?.department?.dept_name}</StyledTableCell>
                  <StyledTableCell>{request?.issues?.title}</StyledTableCell>
                  <StyledTableCell>{getPriorityLabel(request?.priority)}</StyledTableCell>
                  <StyledTableCell>{getStatusLabel(request?.status)}</StyledTableCell>
                  <StyledTableCell>{request?.employeeName}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(request?.created_at).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                  <StyledTableCell>{request?.dueBy}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Pagination
          count={totalPages}
          color='primary'
          page={page + 1}
          onChange={(_, newPage) => handleChangePage(null, newPage - 1)}
        />
      </Box>
    </>
  )
}

const AssignedSR = (props: any) => {
  const {
    loaderState,
    fetchDepartmentList,
    DepartmentList,
    fetchAssignedRequest,
    assignedRequest,
    isAssignedRequest,
    
  } = props
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)

  useEffect(() => {
    fetchDepartmentList()
  }, [])

  useEffect(() => {
    fetchAssignedRequest({
      page,
      pageSize,
    })
  }, [page])
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: any) => {
    setPage(page)
  }

  const fetchSRData1 = () => {
    fetchAssignedRequest({
      page,
      pageSize,
    })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(event.target.value)
  }

  return (
    <>
      {(loaderState || isAssignedRequest) && (
        <Dialog open={loaderState || isAssignedRequest} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {!loaderState ? (
        <div style={MainContainer}>
          <StyledPaper>
            <ServiceRequestTableData
              serviceRequestData={assignedRequest?.data}
              isAssignedRequest={isAssignedRequest}
              fetchSRData1={fetchSRData1}
              page={page}
              DepartmentList={DepartmentList}
              total={assignedRequest.totalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    SRData: dashboardEntity.getDashboard(state).getSRData,
    loaderState: dashboardUI.getDashboard(state).isServiceRequestData,
    DepartmentList: dashboardEntity.getDashboard(state).getDepartmentList,
    isCreateServiceRequest: SRUI.getServiceRequestCreate(state).isCreateServiceRequest,
    assignedRequest: dashboardEntity.getDashboard(state).getAssignedRequest,
    isAssignedRequest: SRUI.getServiceRequestCreate(state).isAssignedRequest,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSRData: (data: any) => dispatch(fetchSR.request(data)),
    fetchDepartmentList: () => dispatch(fetchDepartmentList.request()),
    fetchAssignedRequest: (data: any) => dispatch(fetchAsignedRequest.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedSR)
