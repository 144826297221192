import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ClickAwayListener, Collapse } from '@mui/material'
import {
  ChildList,
  DrawerHeaderStack,
  StyledDrawer,
  StyledListItemButton,
  StyledListItemText,
} from './FinancialStyles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AdministrationLogo } from '../../assets/images/home.svg'
import { ReactComponent as RecordLogo } from '../../assets/images/record_icon.svg'
import { ReactComponent as FileUploadLogo } from '../../assets/images/file-upload-icon.svg'
import { ReactComponent as EmployeeInfoIcon } from '../../assets/images/monitor-data.svg'
import { ReactComponent as FinancalIcon } from '../../assets/images/db.svg'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import EscalatorIcon from '@mui/icons-material/Escalator';
import { SmallArrow } from '../Common/CommonStyles'
import { SideDrawerMenu as menuObject } from '../../utils/StaticData'
import MenuIcon from '@mui/icons-material/Menu'
import SubjectIcon from '@mui/icons-material/Subject';

const SideDrawer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const listArray = Object.keys(menuObject)
  const [openedIndex, setOpenedIndex] = useState<null | number>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const location = useLocation()
  const pathName = location?.pathname?.split('/')

  const handleOpenMenu = (index: number, value: string) => {
    setOpenedIndex(index === openedIndex ? null : index)
    navigate(value)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleClickMenu = (value: string) => {
    if (value === 'benefits') {
      window.open(
        'https://sites.google.com/tudip.com/tudipinternal/tudip-benefits?authuser=0',
        '_blank',
        'noreferrer',
      )
    } else {
      navigate(value)
    }
    handleOpenDrawer()
  }

  const getListItemIcon = (value: string) => {
    switch (value) {
      case 'dashboard':
        return <AdministrationLogo />
      case 'employeeportal':
        return <EmployeeInfoIcon />
      case 'finance':
        return <FinancalIcon />
      case 'idsr':
        return <EscalatorIcon />
      case 'benefits':
        return <RecordLogo />
      case 'attendanceList':
        return <AccessibilityIcon />
      default:
        return <FileUploadLogo />
    }
  }

  return (
    <ClickAwayListener
      onClickAway={(event: any) => {
        setOpenDrawer(false)
      }}
    >
      <StyledDrawer variant='permanent' anchor='left' open={openDrawer}>
        <DrawerHeaderStack>
          {!openDrawer ? (
            <MenuIcon
              sx={{ cursor: 'pointer', color: 'white', fontSize: '28px' }}
              onClick={handleOpenDrawer}
            />
          ) : (
            <MenuIcon
              sx={{ cursor: 'pointer', color: 'white', fontSize: '28px' }}
              onClick={handleOpenDrawer}
            />
          )}
        </DrawerHeaderStack>
        <List>
          {listArray.map((link: string, index: number) => (
            <>
              <ListItem
                key={`index-${index}`}
                onClick={() =>
                  !openDrawer
                    ? handleOpenDrawer()
                    : menuObject[link].length > 0
                      ? handleOpenMenu(index, link)
                      : handleClickMenu(link)
                }
              >
                <StyledListItemButton selected={pathName?.includes(link)}>
                  <ListItemIcon sx={{ minWidth: '35px' }}>{getListItemIcon(link)}</ListItemIcon>
                  {openDrawer && <StyledListItemText primary={t(link)} />}
                  {openDrawer &&
                    menuObject[link].length > 0 &&
                    (openedIndex === index ? (
                      <ArrowDropUpRoundedIcon sx={SmallArrow} />
                    ) : (
                      <ArrowDropDownRoundedIcon sx={SmallArrow} />
                    ))}
                </StyledListItemButton>
              </ListItem>
              {openDrawer && menuObject[link].length > 0 && (
                <Collapse
                  key={`ii-${index}`}
                  in={openedIndex === index}
                  timeout='auto'
                  unmountOnExit
                >
                  <ChildList key={`i-${index}`}>
                    {menuObject[link].map((subLink: string, j: number) => (
                      <ListItem key={`e-${j}`}>
                        <StyledListItemButton
                          onClick={() => handleClickMenu(`${link}/${subLink}`)}
                          selected={pathName.includes(subLink)}
                        >
                          <StyledListItemText primary={t(subLink)} />
                        </StyledListItemButton>
                      </ListItem>
                    ))}
                  </ChildList>
                </Collapse>
              )}
            </>
          ))}
        </List>
      </StyledDrawer>
    </ClickAwayListener>
  )
}

export default SideDrawer
