import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../Common/CommonStyles'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Heading } from '../Pages/Styles'
import { RootState } from '../../configureStore'
import { attendanceEntity, dashboardEntity } from '../../reducers'
import { Dispatch } from 'redux'
import { getAttendanceDetail, getAttendanceTimesheet, managerViewData } from '../../actions'
import { connect } from 'react-redux'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '1px  25px 1px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const DrsIDSRsTable = (props: any) => {
  const { ManagerViewData } = props

  return (
    <StyledPaper>
      <TableContainer sx={{ margin: '36px 0' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>DATE (DAY)</StyledTableCell>
              <StyledTableCell>SUBJECT</StyledTableCell>
              <StyledTableCell>PROJECT NAME</StyledTableCell>
              <StyledTableCell>TIME SPENT (MINS)</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {ManagerViewData?.IDSR?.length ? (
            <TableBody>
              {ManagerViewData?.IDSR?.map((data: any, index: number) => (
                <StyledTableRow
                  key={index}
                  onClick={() => props.onRowClick(data)}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCell component='th' scope='row'>
                    {`${new Date(data.IDSR_date).toLocaleDateString('en-GB')}`} (
                    {`${new Date(data.IDSR_date).toLocaleDateString('en-GB', {
                      weekday: 'long',
                    })}`}
                    )
                  </StyledTableCell>
                  <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                  <StyledTableCell align='left'>{data.project_name}</StyledTableCell>
                  <StyledTableCell align='left'>{data.time_spent}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const DRsIDSRsList = (props: any) => {
  const {
    ManagerViewData,
  } = props

  const [selectedIDSR, setSelectedIDSR] = useState<any | null>(null)

  const handleRowClickData = (data: any) => {
    setSelectedIDSR(data)
  }
  const handleCloseModal = () => {
    setSelectedIDSR(null)
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  return (
    <>
      <DrsIDSRsTable ManagerViewData={ManagerViewData} onRowClick={handleRowClickData} />
      <Modal open={!!selectedIDSR} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            maxWidth: '75%',
            bgcolor: 'background.paper',
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '10px',
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[600],
              marginTop: '15px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Heading
            style={{
              fontSize: '18px',
              textAlign: 'center',
              fontFamily: 'sans-serif',
              letterSpacing: '0px',
              position: 'relative',
            }}
            dangerouslySetInnerHTML={{
              __html: selectedIDSR?.subject || '',
            }}
          />
          <Typography variant='body1' style={{ fontSize: '12px' }}>
            <div>{convertTextToHTML(selectedIDSR?.body || '')}</div>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchManagerViewData: (data: any) => dispatch(managerViewData.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRsIDSRsList)
