import { combineReducers } from 'redux'
import entities, * as Entities from './entities'
import ui, * as UI from './ui'
export const dashboardEntity = Entities.dashboardState
export const employeePortalEntity = Entities.employeePortalState
export const financeEntity = Entities.financeState
export const userAuthenticationEntity = Entities.userAuthenticationState
export const loginUserEntity = Entities.loginUserState
export const SREntity = Entities.ServiceRequest
export const LoanRequestEntity = Entities.loanRequest
export const dashboardUI = UI.dashboardState
export const userAuthenticationUI = UI.userAuthenticationState
export const SRUI = UI.servicepostState
export const loaderStateUI = UI.loaderState
export const loginUserUI = UI.loginUserState
export const employeeRecordsUI = Entities.employeeDataRequest
export const financeUI = UI.financeState
export const attendanceEntity = Entities.attendanceState
export const attendanceUI = UI.attendanceState


const reducers = () =>
  combineReducers({
    entities,
    ui,
  })
export default reducers
