import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { REQUEST, SUCCESS } from '../../actions/actiontypes'

const { STATUS_SUMMARY } = actionTypes

const entity = () => {
    const getStatusSummaryDetails = (state = [], action: actions) => {
        switch (action.type) {
            case STATUS_SUMMARY[SUCCESS]:
                return action.payload
            case STATUS_SUMMARY[REQUEST]:
                return []

            default:
                return state
        }
    }

    return combineReducers({
        getStatusSummaryDetails,
    })
}

export default entity

export const getAllStatusSummaries = (state: RootState) => state.entities.statuSummary
