import { ACCTypes } from './Types'
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const RESET = 'RESET'

// This function will return Action Type object
export const createRequestTypes = (base: string) => {
  return [REQUEST, SUCCESS, FAILURE, RESET].reduce((acc: ACCTypes, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const action = (type: string, payload = {}) => {
  return { type, ...payload }
}

export const GET_USER_DATA = createRequestTypes('GET_USER_DATA')

export const LOGIN = createRequestTypes('LOGIN')

export const LOGOUT = createRequestTypes('LOGOUT')

export const GET_ACCESS_TOKEN = createRequestTypes('GET_ACCESS_TOKEN')

export const LOGIN_USER = createRequestTypes('LOGIN_USER')

export const FETCH_USERS = createRequestTypes('FETCH_USERS')

export const FETCH_BACKINFO = createRequestTypes('FETCH_BACKINFO')

export const FETCH_HOLIDAYS = createRequestTypes('FETCH_HOLIDAYS')

export const FETCH_ASSET = createRequestTypes('FETCH_ASSET')

export const FETCH_LOAN_INSTALLMENT_DATA = createRequestTypes('FETCH_LOAN_INSTALLMENT_DATA')

export const FETCH_DRS_DATA = createRequestTypes('FETCH_DRS_DATA')

export const FETCH_EMPINFO = createRequestTypes('FETCH_EMPINFO')

export const FETCH_DESIGNATION = createRequestTypes('FETCH_DESIGNATION')

export const FETCH_DESIGNATIONBAND = createRequestTypes('FETCH_DESIGNATIONBAND')

export const FETCH_EMPLOYMENTTYPE = createRequestTypes('FETCH_EMPLOYMENTTYPE')

export const FETCH_SAPERIOD = createRequestTypes('FETCH_SAPERIOD')

export const FETCH_TIMING = createRequestTypes('FETCH_TIMING')

export const FETCH_BASICINFO = createRequestTypes('FETCH_BASICINFO')

export const FETCH_SR = createRequestTypes('FETCH_SR')

export const FETCH_ASSIGNED_SR = createRequestTypes('FETCH_ASSIGNED_SR')


export const FETCH_BANKINFO = createRequestTypes('FETCH_BANKINFO')

export const FETCH_STATE = createRequestTypes('FETCH_STATE')

export const FETCH_COUNTRY = createRequestTypes('FETCH_COUNTRY')

export const FETCH_ACCOUNTTYPE = createRequestTypes('FETCH_ACCOUNTTYPE')

export const FETCH_MARITIAL_STATUS = createRequestTypes('FETCH_MARITIAL_STATUS')

export const FETCH_LEAVE_DETAILS = createRequestTypes('FETCH_LEAVE_DETAILS')

export const FETCH_QUALIFICATION = createRequestTypes('FETCH_QUALIFICATION')

export const FETCH_QUALIFICATION_SKILL = createRequestTypes('FETCH_QUALIFICATION_SKILL')

export const CREATE_SERVICE_REQUEST = createRequestTypes('CREATE_SERVICE_REQUEST')

export const FETCH_ISSUE_TYPE_LIST = createRequestTypes('FETCH_ISSUE_TYPE_LIST')

export const FETCH_USER_BY_ID = createRequestTypes('FETCH_USER_BY_ID')

export const FETCH_REF_BY_ID = createRequestTypes('FETCH_REF_BY_ID')

export const FETCH_COMPENSATION_DETAILS = createRequestTypes('FETCH_COMPENSATION_DETAILS')

export const FETCH_LOAN_DETAILS = createRequestTypes('FETCH_LOAN_DETAILS')

export const FETCH_DEPARTMENT_LIST = createRequestTypes('FETCH_DEPARTMENT_LIST')

export const SEND_LOAN_REQUEST = createRequestTypes('SEND_LOAN_REQUEST')

// export const SERVICE_REQUEST = createRequestTypes('SERVICE_REQUEST')

export const STATUS_SUMMARY = createRequestTypes('STATUS_SUMMARY')

export const USER_INFO = createRequestTypes('USER_INFO')

export const HOME_PAGE_INFO = createRequestTypes('HOME_PAGE_INFO')

export const FETCH_EMPLOYEE_DETAILS = createRequestTypes('FETCH_EMPLOYEE_DETAILS')

export const ADD_NEW_COMMENT = createRequestTypes('ADD_NEW_COMMENT')

export const GET_TIMESHEET = createRequestTypes('GET_TIMESHEET')

export const GET_LEAVE_TIMESHEET = createRequestTypes('GET_LEAVE_TIMESHEET')

export const GET_PAY_SLIP = createRequestTypes('GET_PAY_SLIP')

export const GET_LEAVE_DATA = createRequestTypes('GET_LEAVE_DATA')

export const ACCEPT_LEAVE_DATA = createRequestTypes('ACCEPT_LEAVE_DATA')

export const REJECT_LEAVE_DATA = createRequestTypes('REJECT_LEAVE_DATA')

export const FETCH_PROJECT_DATA = createRequestTypes('FETCH_PROJECT_DATA')

export const FETCH_HIGHLIGHTS = createRequestTypes('FETCH_HIGHLIGHTS')

export const FETCH_ATTENDANCE = createRequestTypes('FETCH_ATTENDANCE')

export const FETCH_ATTENDANCE_TIMESHEET = createRequestTypes('FETCH_ATTENDANCE_TIMESHEET')

export const FETCH_RCA = createRequestTypes('FETCH_RCA')

export const FETCH_IDSR = createRequestTypes('FETCH_IDSR')

export const CREATE_RCA = createRequestTypes('CREATE_RCA')

export const CREATE_IDSR = createRequestTypes('CREATE_IDSR')

export const FETCH_ASSIGNED_REQUEST = createRequestTypes('FETCH_ASSIGNED_REQUEST')

export const FETCH_UPDATED_SERVICE_REQUEST = createRequestTypes('FETCH_UPDATED_SERVICE_REQUEST')

export const FETCH_DOWNLOADABLE_URL = createRequestTypes('FETCH_DOWNLOADABLE_URL')

export const MANAGER_VIEW_DATA = createRequestTypes('MANAGER_VIEW_DATA')

export const FETCH_DOC_URL = createRequestTypes('FETCH_DOC_URL')





