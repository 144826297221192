import { IData } from '../components/Dashboard/Types'
import { GetAccessToken, IRequestData, LoginAuthData, userDetails } from '../components/Types'
import { ICreateComment } from '../models/create-comment.model'
import * as actions from './actiontypes'
import {
  FETCH_EMPINFO,
  FETCH_USERS,
  FETCH_BACKINFO,
  FETCH_HOLIDAYS,
  FETCH_ASSET,
  FETCH_DESIGNATION,
  FETCH_DESIGNATIONBAND,
  FETCH_EMPLOYMENTTYPE,
  FETCH_SAPERIOD,
  FETCH_TIMING,
  FETCH_BASICINFO,
  FETCH_SR,
  FETCH_BANKINFO,
  FETCH_COUNTRY,
  FETCH_STATE,
  FETCH_ACCOUNTTYPE,
  FETCH_MARITIAL_STATUS,
  FETCH_LEAVE_DETAILS,
  FETCH_QUALIFICATION,
  FETCH_QUALIFICATION_SKILL,
  CREATE_SERVICE_REQUEST,
  FETCH_DEPARTMENT_LIST,
  FETCH_ISSUE_TYPE_LIST,
  SEND_LOAN_REQUEST,
  USER_INFO,
  HOME_PAGE_INFO,
  FETCH_USER_BY_ID,
  FETCH_REF_BY_ID,
  FETCH_COMPENSATION_DETAILS,
  FETCH_LOAN_DETAILS,
  FETCH_EMPLOYEE_DETAILS,
  ADD_NEW_COMMENT,
  FETCH_LOAN_INSTALLMENT_DATA,
  GET_TIMESHEET,
  GET_PAY_SLIP,
  FETCH_DRS_DATA,
  GET_LEAVE_TIMESHEET,
  GET_LEAVE_DATA,
  ACCEPT_LEAVE_DATA,
  REJECT_LEAVE_DATA,
  FETCH_PROJECT_DATA,
  FETCH_HIGHLIGHTS,
  STATUS_SUMMARY,
  FETCH_ATTENDANCE,
  FETCH_RCA,
  FETCH_IDSR,
  FETCH_ASSIGNED_SR,
} from './actiontypes'

const {
  SUCCESS,
  REQUEST,
  FAILURE,
  RESET,
  GET_USER_DATA,
  LOGIN,
  LOGOUT,
  GET_ACCESS_TOKEN,
  LOGIN_USER,
  action,
} = actions

// Bellow are the Action Objects
export const getUserData = {
  request: () => action(GET_USER_DATA[REQUEST]),
  success: (response: IData) => action(GET_USER_DATA[SUCCESS], { response }),
  failure: () => action(GET_USER_DATA[FAILURE]),
  reset: () => action(GET_USER_DATA[RESET], {}),
}

export const getUserAuthentication = {
  request: (data: LoginAuthData) => action(LOGIN[REQUEST], { data }),
  success: (response: LoginAuthData) => action(LOGIN[SUCCESS], { response }),
  failure: () => action(LOGIN[FAILURE]),
  reset: () => action(LOGIN[RESET], {}),
}

//Action for renewing access token
export const renewAccessToken = {
  request: (data: GetAccessToken) => action(GET_ACCESS_TOKEN[REQUEST], { data }),
  success: (response: GetAccessToken) => action(GET_ACCESS_TOKEN[SUCCESS], { response }),
  failure: () => action(GET_ACCESS_TOKEN[FAILURE]),
  reset: () => action(GET_ACCESS_TOKEN[RESET], {}),
}

export const getUserLogout = {
  request: () => action(LOGOUT[REQUEST]),
  success: () => action(LOGOUT[SUCCESS]),
  failure: () => action(LOGOUT[FAILURE]),
  reset: () => action(LOGOUT[RESET], {}),
}

export const getUserLoginUser = {
  request: (data: any) => action(LOGIN_USER[REQUEST], { data }),
  success: (response: any) => action(LOGIN_USER[SUCCESS], { response }),
  failure: () => action(LOGIN_USER[FAILURE]),
  reset: () => action(LOGIN_USER[RESET], {}),
}

// Bellow are the Action Objects
export const fetchUserData = {
  request: () => action(FETCH_USERS[REQUEST]),
  success: (response: IData) => action(FETCH_USERS[SUCCESS], { response }),
  failure: () => action(FETCH_USERS[FAILURE]),
  reset: () => action(FETCH_USERS[RESET], {}),
}

export const fetchBackInfo = {
  request: () => action(FETCH_BACKINFO[REQUEST]),
  success: (response: IData) => action(FETCH_BACKINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BACKINFO[FAILURE]),
  reset: () => action(FETCH_BACKINFO[RESET], {}),
}

export const fetchHolidays = {
  request: () => action(FETCH_HOLIDAYS[REQUEST]),
  success: (response: IData) => action(FETCH_HOLIDAYS[SUCCESS], { response }),
  failure: () => action(FETCH_HOLIDAYS[FAILURE]),
  reset: () => action(FETCH_HOLIDAYS[RESET], {}),
}

export const fetchHighlights = {
  request: () => action(FETCH_HIGHLIGHTS[REQUEST]),
  success: (response: IData) => action(FETCH_HIGHLIGHTS[SUCCESS], { response }),
  failure: () => action(FETCH_HIGHLIGHTS[FAILURE]),
  reset: () => action(FETCH_HIGHLIGHTS[RESET], {}),
}

export const fetchAsset = {
  request: () => action(FETCH_ASSET[REQUEST]),
  success: (response: IData) => action(FETCH_ASSET[SUCCESS], { response }),
  failure: () => action(FETCH_ASSET[FAILURE]),
  reset: () => action(FETCH_ASSET[RESET], {}),
}

export const fetchEmpInfo = {
  request: () => action(FETCH_EMPINFO[REQUEST]),
  success: (response: IData) => action(FETCH_EMPINFO[SUCCESS], { response }),
  failure: () => action(FETCH_EMPINFO[FAILURE]),
  reset: () => action(FETCH_EMPINFO[RESET], {}),
}

export const fetchDesignation = {
  request: () => action(FETCH_DESIGNATION[REQUEST]),
  success: (response: IData) => action(FETCH_DESIGNATION[SUCCESS], { response }),
  failure: () => action(FETCH_DESIGNATION[FAILURE]),
  reset: () => action(FETCH_DESIGNATION[RESET], {}),
}

export const fetchDesignationBand = {
  request: () => action(FETCH_DESIGNATIONBAND[REQUEST]),
  success: (response: IData) => action(FETCH_DESIGNATIONBAND[SUCCESS], { response }),
  failure: () => action(FETCH_DESIGNATIONBAND[FAILURE]),
  reset: () => action(FETCH_DESIGNATIONBAND[RESET], {}),
}

export const fetchEmplymentType = {
  request: () => action(FETCH_EMPLOYMENTTYPE[REQUEST]),
  success: (response: IData) => action(FETCH_EMPLOYMENTTYPE[SUCCESS], { response }),
  failure: () => action(FETCH_EMPLOYMENTTYPE[FAILURE]),
  reset: () => action(FETCH_EMPLOYMENTTYPE[RESET], {}),
}

export const fetchSAPeriod = {
  request: () => action(FETCH_SAPERIOD[REQUEST]),
  success: (response: IData) => action(FETCH_SAPERIOD[SUCCESS], { response }),
  failure: () => action(FETCH_SAPERIOD[FAILURE]),
  reset: () => action(FETCH_SAPERIOD[RESET], {}),
}

export const fetchTiming = {
  request: () => action(FETCH_TIMING[REQUEST]),
  success: (response: IData) => action(FETCH_TIMING[SUCCESS], { response }),
  failure: () => action(FETCH_TIMING[FAILURE]),
  reset: () => action(FETCH_TIMING[RESET], {}),
}

export const fetchBasicInfo = {
  request: (data: any) => action(FETCH_BASICINFO[REQUEST], { data }),
  success: (response: IData) => action(FETCH_BASICINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BASICINFO[FAILURE]),
  reset: () => action(FETCH_BASICINFO[RESET], {}),
}

export const getLoanInstallment = {
  request: (data: any) => action(FETCH_LOAN_INSTALLMENT_DATA[REQUEST], { data }),
  success: (response: IData) => action(FETCH_LOAN_INSTALLMENT_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_LOAN_INSTALLMENT_DATA[FAILURE]),
  reset: () => action(FETCH_LOAN_INSTALLMENT_DATA[RESET], {}),
}

export const getDrsData = {
  request: () => action(FETCH_DRS_DATA[REQUEST]),
  success: (response: IData) => action(FETCH_DRS_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_DRS_DATA[FAILURE]),
  reset: () => action(FETCH_DRS_DATA[RESET], {}),
}

export const fetchSR = {
  request: (data: any) => action(FETCH_SR[REQUEST], { data }),
  success: (response: IData) => action(FETCH_SR[SUCCESS], { response }),
  failure: () => action(FETCH_SR[FAILURE]),
  reset: () => action(FETCH_SR[RESET], {}),
}

export const fetchAssignedSR = {
  request: (data: any) => action(FETCH_ASSIGNED_SR[REQUEST], { data }),
  success: (response: IData) => action(FETCH_ASSIGNED_SR[SUCCESS], { response }),
  failure: () => action(FETCH_ASSIGNED_SR[FAILURE]),
  reset: () => action(FETCH_ASSIGNED_SR[RESET], {}),
}

export const getProjectData = {
  request: () => action(FETCH_PROJECT_DATA[REQUEST]),
  success: (response: IData) => action(FETCH_PROJECT_DATA[SUCCESS], { response }),
  failure: () => action(FETCH_PROJECT_DATA[FAILURE]),
  reset: () => action(FETCH_PROJECT_DATA[RESET], {}),
}

export const fetchBankInfo = {
  request: () => action(FETCH_BANKINFO[REQUEST]),
  success: (response: IData) => action(FETCH_BANKINFO[SUCCESS], { response }),
  failure: () => action(FETCH_BANKINFO[FAILURE]),
  reset: () => action(FETCH_BANKINFO[RESET], {}),
}

export const fetchCountry = {
  request: () => action(FETCH_COUNTRY[REQUEST]),
  success: (response: IData) => action(FETCH_COUNTRY[SUCCESS], { response }),
  failure: () => action(FETCH_COUNTRY[FAILURE]),
  reset: () => action(FETCH_COUNTRY[RESET], {}),
}

export const fetchState = {
  request: () => action(FETCH_STATE[REQUEST]),
  success: (response: IData) => action(FETCH_STATE[SUCCESS], { response }),
  failure: () => action(FETCH_STATE[FAILURE]),
  reset: () => action(FETCH_STATE[RESET], {}),
}

export const fetchAccountType = {
  request: () => action(FETCH_ACCOUNTTYPE[REQUEST]),
  success: (response: IData) => action(FETCH_ACCOUNTTYPE[SUCCESS], { response }),
  failure: () => action(FETCH_ACCOUNTTYPE[FAILURE]),
  reset: () => action(FETCH_ACCOUNTTYPE[RESET], {}),
}

export const fetchMaritialStatus = {
  request: () => action(FETCH_MARITIAL_STATUS[REQUEST]),
  success: (response: IData) => action(FETCH_MARITIAL_STATUS[SUCCESS], { response }),
  failure: () => action(FETCH_MARITIAL_STATUS[FAILURE]),
  reset: () => action(FETCH_MARITIAL_STATUS[RESET], {}),
}

export const fetchLeaveDetails = {
  request: (data: any) => action(FETCH_LEAVE_DETAILS[REQUEST], { data }),
  success: (response: IData) => action(FETCH_LEAVE_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_LEAVE_DETAILS[FAILURE]),
  reset: () => action(FETCH_LEAVE_DETAILS[RESET], {}),
}

export const fetchDepartmentList = {
  request: () => action(FETCH_DEPARTMENT_LIST[REQUEST]),
  success: (response: any) => action(FETCH_DEPARTMENT_LIST[SUCCESS], { response }),
  failure: () => action(FETCH_DEPARTMENT_LIST[FAILURE]),
  reset: () => action(FETCH_DEPARTMENT_LIST[RESET], {}),
}

export const fetchQualification = {
  request: () => action(FETCH_QUALIFICATION[REQUEST]),
  success: (response: IData) => action(FETCH_QUALIFICATION[SUCCESS], { response }),
  failure: () => action(FETCH_QUALIFICATION[FAILURE]),
  reset: () => action(FETCH_QUALIFICATION[RESET], {}),
}

export const fetchQualificationSkill = {
  request: () => action(FETCH_QUALIFICATION_SKILL[REQUEST]),
  success: (response: IData) => action(FETCH_QUALIFICATION_SKILL[SUCCESS], { response }),
  failure: () => action(FETCH_QUALIFICATION_SKILL[FAILURE]),
  reset: () => action(FETCH_QUALIFICATION_SKILL[RESET], {}),
}

export const createServiceRequest = {
  request: (data: any) => action(CREATE_SERVICE_REQUEST[REQUEST], { data }),
  success: (response: IRequestData) => action(CREATE_SERVICE_REQUEST[SUCCESS], { response }),
  failure: () => action(CREATE_SERVICE_REQUEST[FAILURE]),
  reset: () => action(CREATE_SERVICE_REQUEST[RESET], {}),
}

export const getIssueTypeList = {
  request: (data: any) => action(FETCH_ISSUE_TYPE_LIST[REQUEST], { data }),
  success: (response: any) => action(FETCH_ISSUE_TYPE_LIST[SUCCESS], { response }),
  failure: () => action(FETCH_ISSUE_TYPE_LIST[FAILURE]),
  reset: () => action(FETCH_ISSUE_TYPE_LIST[RESET], {}),
}

export const getManagerDataById = {
  request: (data: any) => action(FETCH_USER_BY_ID[REQUEST], { data }),
  success: (response: any) => action(FETCH_USER_BY_ID[SUCCESS], { response }),
  failure: () => action(FETCH_USER_BY_ID[FAILURE]),
  reset: () => action(FETCH_USER_BY_ID[RESET], {}),
}

export const getCompensationDetails = {
  request: (data: any) => action(FETCH_COMPENSATION_DETAILS[REQUEST], { data }),
  success: (response: any) => action(FETCH_COMPENSATION_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_COMPENSATION_DETAILS[FAILURE]),
  reset: () => action(FETCH_COMPENSATION_DETAILS[RESET], {}),
}

export const getLoanDetails = {
  request: () => action(FETCH_LOAN_DETAILS[REQUEST], {}),
  success: (response: any) => action(FETCH_LOAN_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_LOAN_DETAILS[FAILURE]),
  reset: () => action(FETCH_LOAN_DETAILS[RESET], {}),
}

export const getRefDataById = {
  request: (data: any) => action(FETCH_REF_BY_ID[REQUEST], { data }),
  success: (response: any) => action(FETCH_REF_BY_ID[SUCCESS], { response }),
  failure: () => action(FETCH_REF_BY_ID[FAILURE]),
  reset: () => action(FETCH_REF_BY_ID[RESET], {}),
}

export const getUserDetails = {
  request: () => action(USER_INFO[REQUEST], {}),
  success: (response: any) => action(USER_INFO[SUCCESS], { response }),
  failure: () => action(USER_INFO[FAILURE]),
  reset: () => action(USER_INFO[RESET], {}),
}

export const getHomePageDetails = {
  request: () => action(HOME_PAGE_INFO[REQUEST], {}),
  success: (response: any) => action(HOME_PAGE_INFO[SUCCESS], { response }),
  failure: () => action(HOME_PAGE_INFO[FAILURE]),
  reset: () => action(HOME_PAGE_INFO[RESET], {}),
}

export const getStatusSummary = {
  request: (data: any) => action(STATUS_SUMMARY[REQUEST], { data }),
  success: (response: any) => action(STATUS_SUMMARY[SUCCESS], { response }),
  failure: () => action(STATUS_SUMMARY[FAILURE]),
  reset: () => action(STATUS_SUMMARY[RESET], {}),
}

// export const getIssueTypeList = {
//   request: () => action(FETCH_ISSUE_TYPE_LIST[REQUEST]),
//   success: (response: IData) => action(FETCH_ISSUE_TYPE_LIST[SUCCESS], { response }),
//   failure: () => action(FETCH_ISSUE_TYPE_LIST[FAILURE]),
//   reset: () => action(FETCH_ISSUE_TYPE_LIST[RESET], {}),
// }

export const sendLoanRequest = {
  request: (data: any) => action(SEND_LOAN_REQUEST[REQUEST], { data }),
  success: (response: any) => action(SEND_LOAN_REQUEST[SUCCESS], { response }),
  failure: () => action(SEND_LOAN_REQUEST[FAILURE]),
  reset: () => action(SEND_LOAN_REQUEST[RESET], {}),
}

// To fetch all employee details
export const getAllEmployees = {
  request: () => action(FETCH_EMPLOYEE_DETAILS[REQUEST], {}),
  success: (response: any) => action(FETCH_EMPLOYEE_DETAILS[SUCCESS], { response }),
  failure: () => action(FETCH_EMPLOYEE_DETAILS[FAILURE]),
  reset: () => action(FETCH_EMPLOYEE_DETAILS[RESET], {}),
}

export const addNewComment = {
  request: (data: ICreateComment) => action(ADD_NEW_COMMENT[REQUEST], { data }),
  success: (response: any) => action(ADD_NEW_COMMENT[SUCCESS], { response }),
  failure: () => action(ADD_NEW_COMMENT[FAILURE]),
  reset: () => action(ADD_NEW_COMMENT[RESET], {}),
}

export const getTimesheet = {
  request: () => action(GET_TIMESHEET[REQUEST], {}),
  success: (response: any) => action(GET_TIMESHEET[SUCCESS], { response }),
  failure: () => action(GET_TIMESHEET[FAILURE]),
  reset: () => action(GET_TIMESHEET[RESET], {}),
}

export const getLeaveTimesheets = {
  request: () => action(GET_LEAVE_TIMESHEET[REQUEST], {}),
  success: (response: any) => action(GET_LEAVE_TIMESHEET[SUCCESS], { response }),
  failure: () => action(GET_LEAVE_TIMESHEET[FAILURE]),
  reset: () => action(GET_LEAVE_TIMESHEET[RESET], {}),
}

export const getLeaveData = {
  request: (data: any) => action(GET_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(GET_LEAVE_DATA[FAILURE]),
  reset: () => action(GET_LEAVE_DATA[RESET], {}),
}

export const acceptLeaveData = {
  request: (data: any) => action(ACCEPT_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(ACCEPT_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(ACCEPT_LEAVE_DATA[FAILURE]),
  reset: () => action(ACCEPT_LEAVE_DATA[RESET], {}),
}

export const rejectLeaveData = {
  request: (data: any) => action(REJECT_LEAVE_DATA[REQUEST], { data }),
  success: (response: any) => action(REJECT_LEAVE_DATA[SUCCESS], { response }),
  failure: () => action(REJECT_LEAVE_DATA[FAILURE]),
  reset: () => action(REJECT_LEAVE_DATA[RESET], {}),
}

export const getPaySlip = {
  request: (data: any) => action(GET_PAY_SLIP[REQUEST], { data }),
  success: (response: any) => action(GET_PAY_SLIP[SUCCESS], { response }),
  failure: () => action(GET_PAY_SLIP[FAILURE]),
  reset: () => action(GET_PAY_SLIP[RESET], {}),
}

export const getAttendanceDetail = {
  request: (data: any) => action(FETCH_ATTENDANCE[REQUEST], data),
  success: (response: IData) => action(FETCH_ATTENDANCE[SUCCESS], { response }),
  failure: () => action(FETCH_ATTENDANCE[FAILURE]),
  reset: () => action(FETCH_ATTENDANCE[RESET], {}),
}

export const getAttendanceTimesheet = {
  request: (data: any) => action(actions.FETCH_ATTENDANCE_TIMESHEET[REQUEST], data),
  success: (response: IData) => action(actions.FETCH_ATTENDANCE_TIMESHEET[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ATTENDANCE_TIMESHEET[FAILURE]),
  reset: () => action(actions.FETCH_ATTENDANCE_TIMESHEET[RESET], {}),
}

export const getRCAs = {
  request: (data: any) => action(actions.FETCH_RCA[REQUEST], data),
  success: (response: IData) => action(actions.FETCH_RCA[SUCCESS], { response }),
  failure: () => action(actions.FETCH_RCA[FAILURE]),
  reset: () => action(actions.FETCH_RCA[RESET], {}),
}

export const getIDSRs = {
  request: (data: any) => action(actions.FETCH_IDSR[REQUEST], data),
  success: (response: any) => action(actions.FETCH_IDSR[SUCCESS], { response }),
  failure: () => action(actions.FETCH_IDSR[FAILURE]),
  reset: () => action(actions.FETCH_IDSR[RESET], {}),
}

export const createNewRCA = {
  request: (data: any) => action(actions.CREATE_RCA[REQUEST], data),
  success: (response: IData) => action(actions.CREATE_RCA[SUCCESS], { response }),
  failure: () => action(actions.CREATE_RCA[FAILURE]),
  reset: () => action(actions.CREATE_RCA[RESET], {}),
}

export const createNewIDSR = {
  request: (data: any) => action(actions.CREATE_IDSR[REQUEST], data),
  success: (response: IData) => action(actions.CREATE_IDSR[SUCCESS], { response }),
  failure: () => action(actions.CREATE_IDSR[FAILURE]),
  reset: () => action(actions.CREATE_IDSR[RESET], {}),
}

export const fetchAsignedRequest = {
  request: (data: any) => action(actions.FETCH_ASSIGNED_REQUEST[REQUEST], data),
  success: (response: any) => action(actions.FETCH_ASSIGNED_REQUEST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_ASSIGNED_REQUEST[FAILURE]),
  reset: () => action(actions.FETCH_ASSIGNED_REQUEST[RESET], {}),
}

export const fetchUpdatedServiceRequest = {
  request: (data: any) => action(actions.FETCH_UPDATED_SERVICE_REQUEST[REQUEST], data),
  success: (response: any) => action(actions.FETCH_UPDATED_SERVICE_REQUEST[SUCCESS], { response }),
  failure: () => action(actions.FETCH_UPDATED_SERVICE_REQUEST[FAILURE]),
  reset: () => action(actions.FETCH_UPDATED_SERVICE_REQUEST[RESET], {}),
}

export const fetchDownloadableURL = {
  request: (data: any) => action (actions.FETCH_DOWNLOADABLE_URL[REQUEST],data),
  success: (response: any) => action(actions.FETCH_DOWNLOADABLE_URL[SUCCESS],{response}),
  failure: () => action(actions.FETCH_DOWNLOADABLE_URL[FAILURE]),
  reset: () => action(actions.FETCH_DOWNLOADABLE_URL[RESET],{}),
}

export const fetchDocURL = {
  request: (data: any) => action (actions.FETCH_DOC_URL[REQUEST],data),
  success: (response: any) => action(actions.FETCH_DOC_URL[SUCCESS],{response}),
  failure: () => action(actions.FETCH_DOC_URL[FAILURE]),
  reset: () => action(actions.FETCH_DOC_URL[RESET],{}),
}


export const managerViewData = {
  request: (data: any) => action(actions.MANAGER_VIEW_DATA[REQUEST],data),
  success: (response: any) =>action(actions.MANAGER_VIEW_DATA[SUCCESS],{response}),
  failure: () => action(actions.MANAGER_VIEW_DATA[FAILURE]),
  reset: () => action(actions.MANAGER_VIEW_DATA[RESET],{}),
}

export const actionTypes = {
  ...actions,
}
